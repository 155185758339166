import { SubmitHandler, useForm } from 'react-hook-form';
import { Dispatch, SetStateAction, useState } from 'react';
import { castAuthData, setAuthData } from 'entities/user';
import { useDispatch } from 'shared/model';
import { useLoginStep1Mutation, useLoginStep2Mutation } from 'shared/api/login-by-code';
import { LoginByCodeStep1Input, LoginByCodeStep2Input } from 'shared/api';
import { normalizePhone } from 'shared/lib';

interface FormData {
  code: string;
  merge_carts: boolean;
}
interface UseCheckoutAuthFormProps {
  phone: string;
  setPopupVisible: Dispatch<SetStateAction<boolean>>
}
export const useCheckoutAuthForm = ({ phone, setPopupVisible }: UseCheckoutAuthFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm<FormData>();
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  const [loginStep2] = useLoginStep2Mutation();
  const [loginStep1] = useLoginStep1Mutation();
  const setResendCode = (isSms: boolean) => {
    const input: LoginByCodeStep1Input = { is_sms: isSms };
    input.phone = normalizePhone(phone);
    loginStep1({ input });
  };
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input: LoginByCodeStep2Input = { ...data };
    input.phone = normalizePhone(phone);

    loginStep2({ input })
      .unwrap()
      .then((res) => {
        setPopupVisible(false);
        const authData = castAuthData(res.LoginByCodeStep2);
        if (authData == null) {
          throw new Error();
        }
        dispatch(setAuthData(authData));
      })
      .catch((error) => {
        // TODO: need to remove hard code
        if (error.message === 'Большое количество попыток ввода короткого кода, попробуйте через 10 минут.') {
          setDisabled(!disabled);
          setTimeout(() => setDisabled(false), 600000);
        }
        reset();
        setError('code', { type: 'custom', message: error.message });
      });
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    setValue,
    clearErrors,
    disabled,
    setResendCode,
  };
};
