import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './PaginationItem.module.scss';

interface PaginationPrevItemProps {
  href: string;
  isFirst: boolean;
}

export const PaginationPrevItem = ({ href, isFirst }: PaginationPrevItemProps) => (
  <Link to={href}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a className={cn(styles.item, styles.itemPrev, isFirst ? styles.itemDisabled : null)}>
      &lt;
    </a>
  </Link>
);
