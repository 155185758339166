/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgClock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      stroke="#95989F"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#Clock_svg__a)"
    >
      <path d="M2 8a6 6 0 1 0 12 0A6 6 0 0 0 2 8M8 8l2 1.333M8 4.667V8" />
    </g>
    <defs>
      <clipPath id="Clock_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgClock;
