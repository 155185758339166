// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_root__zsbfp {
  display: flex;
  flex: 1 1;
  position: relative;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/router/Layout.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EACA,kBAAA;EACA,uBAAA;AADF","sourcesContent":["@import \"shared/styles/theme\";\n\n.root {\n  display: flex;\n  flex: 1;\n  position: relative;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Layout_root__zsbfp`
};
export default ___CSS_LOADER_EXPORT___;
