import { useState } from 'react';
import type { SetStep, Steps } from './ResetPasswordContext';

export function useResetPasswordContext() {
  const [steps, setSteps] = useState<Steps>({});

  const setStep: SetStep['setStep'] = (step, value) => {
    setSteps((prev) => {
      // eslint-disable-next-line no-param-reassign
      prev[`step${step}`] = value;
      return prev;
    });
  };

  return { steps, setStep };
}
