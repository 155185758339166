/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ResetPasswordStep2MutationVariables = Types.Exact<{
  input: Types.PasswordRecoveryStep2Input;
}>;


export type ResetPasswordStep2Mutation = { __typename?: 'Mutation', PasswordRecoveryStep2: string };


export const ResetPasswordStep2Document = `
    mutation ResetPasswordStep2($input: PasswordRecoveryStep2Input!) {
  PasswordRecoveryStep2(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ResetPasswordStep2: build.mutation<ResetPasswordStep2Mutation, ResetPasswordStep2MutationVariables>({
      query: (variables) => ({ document: ResetPasswordStep2Document, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useResetPasswordStep2Mutation } = injectedRtkApi;

