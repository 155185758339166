export { TAG_PRODUCT } from 'shared/api/product/tags';
export {
  ProductAttribute,
  AttributeModal,
  AttributeModalHeader,
  AttributeModalBodyColors,
  AttributeModalBodyDefault,
  AttributeModalFooter,
  AttributeConfirmationModal,
} from './ui';
export {
  setAttributes,
  selectedAttributes,
  attributesReducer,
  transformAttributes,
  selectedIsUpdated,
  clearAttributes,
  processAttributes,
} from './model';
