import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { LoginUserInput } from 'shared/api';
import { Paths } from 'shared/config';
import { normalizePhone, regex } from 'shared/lib';
import { setAuthData, castAuthData } from 'entities/user';
import { useLoginMutation } from 'shared/api/login-by-password';

interface FormData {
  contact: string,
  password: string,
}
export function useLoginByPasswordForm() {
  const {
    handleSubmit,
    register,
    setError,
    formState: { errors },
  } = useForm<FormData>();
  const [login] = useLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input: LoginUserInput = { password: data.password };
    if (regex.email.test(data.contact)) {
      input.email = data.contact;
    } else if (regex.phone.test(data.contact)) {
      input.phone = normalizePhone(data.contact);
    }
    login({ input })
      .unwrap()
      .then((res) => {
        const authData = castAuthData(res.Login);
        if (authData == null) {
          throw new Error();
        }
        dispatch(setAuthData(authData));
        navigate(Paths.Root);
      })
      .catch((error) => {
        setError('contact', { type: '', message: error.message });
        setError('password', { type: '', message: '' });
      });
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
  };
}
