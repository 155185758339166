/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useState } from 'react';
import { FormControlProps, FormGroup, FormControl, FormLabel, FormText } from 'react-bootstrap';
import cn from 'classnames';
import { VisibleIcon, InvisibleIcon } from 'shared/ui/icons';
import { useHasValue } from 'shared/lib';
import styles from './Input.module.scss';
import passwordStyles from './PasswordInput.module.scss';

interface PasswordInputProps extends FormControlProps {
  id: string,
  label: string,
  hasError?: boolean,
  help?: string | null,
  required?: boolean,
  value?: string | number;
  defaultValue?: string | number;
  position?: string,
}
export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(({
  id,
  label,
  hasError,
  help,
  required,
  // eslint-disable-next-line react/prop-types
  className,
  position = 'left',
  ...props
}, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const { hasValue, onInput } = useHasValue(props);
  return (
    <FormGroup
      className={cn(
        styles.root,
        hasError ? styles.hasError : null,
        className,
      )}
    >
      <FormLabel className={position === 'center' ? styles.center : styles.left}>
        {label}
        {required ? <span className={styles.required}> *</span> : null}
      </FormLabel>
      <div className={passwordStyles.input}>
        <FormControl
          type={isVisible ? 'text' : 'password'}
          id={id}
          ref={ref}
          className={hasValue ? styles.hasValue : undefined}
          onInput={onInput}
          {...props}
        />
        <button
          type="button"
          onClick={() => setIsVisible(!isVisible)}
          className={passwordStyles.visibility}
        >
          { isVisible
            ? <InvisibleIcon className={passwordStyles.icon} />
            : <VisibleIcon className={passwordStyles.icon} /> }
        </button>
      </div>
      <FormText>{help}</FormText>
    </FormGroup>
  );
});
