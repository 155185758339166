// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchInput_root__5SHwJ {
  border: none !important;
  padding: 0 !important;
}

.SearchInput_disabled__XInuZ {
  background-color: #E5E5E5;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/form/input/SearchInput.module.scss","webpack://./src/shared/styles/vars/_colors.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;EACA,qBAAA;AADF;;AAIA;EACE,yBCOS;EDNT,UAAA;AADF","sourcesContent":["@import 'shared/styles/theme';\n\n.root {\n  border: none !important;\n  padding: 0 !important;\n}\n\n.disabled {\n  background-color: $disabled;\n  color: red;\n}","$main-bg: #C2CBD1;\n$primary: #21252E;\n$secondary: #FFFFFF;\n\n$grey: #95989F;\n$grey-light: #F4F5F6;\n$grey-hover: #EDEFF0;\n$grey-active: #E1E8EC;\n\n$red: #B43737;\n\n$border: #C2CBD1;\n\n$error: #B43737;\n$link: #3B72C5;\n$disabled: #E5E5E5;\n\n$input-focus: #21252E;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `SearchInput_root__5SHwJ`,
	"disabled": `SearchInput_disabled__XInuZ`
};
export default ___CSS_LOADER_EXPORT___;
