import { Outlet } from 'react-router-dom';
import { Header } from 'widgets/header/ui';
import { FooterMobile, Footer } from 'widgets/footer';
import styles from './Layout.module.scss';
import { ScrollToTop } from './ScrollTop';

const Layout = () => (
  <>
    <ScrollToTop />
    <Header />
    <div className={styles.root}>
      <Outlet />
    </div>
    <Footer />
    <FooterMobile />
  </>
);

export default Layout;
