/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type GetOrderQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetOrderQuery = { __typename?: 'Query', Order: { __typename?: 'OrderFront', id?: string, total: number, comission: number, address: string, fio: string, createdAt: any, isAvailablePayment?: boolean, isAvailableTrack?: boolean, status: { __typename?: 'OrderStatus', id: string, title: string, createdAt?: any }, products?: Array<{ __typename?: 'Cartproduct', id: string, name: string, slug: string, amount?: number, price: number, attribute_rows?: Array<string>, category?: { __typename?: 'Category', slug: string, id: string, name: string, active?: Types.YesNoEnum, image_path: string, created_at?: any, updated_at?: any }, productFiles?: Array<{ __typename?: 'ProductFiles', id: string, file_path: string, fileType?: { __typename?: 'ProductFileType', name: string, id: string } }> }> } };


export const GetOrderDocument = `
    query getOrder($id: ID!) {
  Order(id: $id) {
    id
    status {
      id
      title
      createdAt: created_at
    }
    total
    comission
    createdAt: created_at
    address
    fio
    products {
      id
      name
      slug
      amount
      price
      category {
        slug
        id
        name
        active
        image_path
        created_at
        updated_at
      }
      attribute_rows
      productFiles {
        id
        file_path
        fileType {
          name
          id
        }
      }
    }
    isAvailablePayment: is_available_payment
    isAvailableTrack: is_available_track
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    getOrder: build.query<GetOrderQuery, GetOrderQueryVariables>({
      query: (variables) => ({ document: GetOrderDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetOrderQuery, useLazyGetOrderQuery } = injectedRtkApi;

