/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgDelivery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1.5em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="#21252E"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#Delivery_svg__a)"
    >
      <path d="M16 12.6 9 8.4l7-4.2 7 4.2v7.7l-7 4.2z" />
      <path d="M9 8.4v7.7l7 4.2M16 12.663l7-4.242M6 9H1M6 12H3M6 15H5" />
    </g>
    <defs>
      <clipPath id="Delivery_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDelivery;
