/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgVk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12.893 18C6.06 18 2.163 13.496 2 6h3.423c.112 5.502 2.636 7.832 4.634 8.312V6h3.223v4.745c1.974-.204 4.048-2.367 4.747-4.745h3.223c-.537 2.93-2.785 5.093-4.384 5.982 1.599.72 4.16 2.607 5.134 6.018h-3.548c-.762-2.282-2.66-4.048-5.172-4.288V18z"
    />
  </svg>
);
export default SvgVk;
