/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSuggestAddressQuery } from 'shared/api/checkout';
import { Autocomplete } from 'shared/ui';
import { AddressAutocompleteProps } from '../types';

export const StreetAutocomplete = ({
  label,
  required,
  resetField,
  setCode,
  hasError,
  help,
  disabled,
  arg,
  ...rest
}: AddressAutocompleteProps) => {
  const mapFunction = <T extends Record<any, any>>(item: T) => {
    const { value, data: { street, streetId, postalCode, house, houseId } } = item;
    return { label: value, value: { value, street, house, streetId, houseId }, postalCode };
  };
  return (
    <Autocomplete
      setCode={setCode}
      label={label}
      required={required}
      resetField={resetField}
      useQuery={useSuggestAddressQuery}
      arg={arg}
      hasError={hasError}
      help={help}
      disabled={disabled}
      mapFunction={mapFunction}
      {...rest}
    />
  );
};
