import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './PaginationItem.module.scss';

interface PaginationNextItemProps {
  href: string;
  isLast: boolean;
}

export const PaginationNextItem = ({ href, isLast }: PaginationNextItemProps) => (
  <Link to={href}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a className={cn(styles.item, styles.itemNext, isLast ? styles.itemDisabled : null)}>
      &gt;
    </a>
  </Link>
);
