/* eslint-disable react/jsx-props-no-spreading */
import { CheckboxInput, CodeInput, Popup, PopupProps, ResendCode } from 'shared/ui';
import { useState } from 'react';
import { FormContainer } from 'entities/auth';
import { timestamp } from 'shared/lib';
import { useCheckoutAuthForm } from '../model';
import styles from './CheckoutAuthModal.module.scss';

interface CheckoutAuthModalProps extends PopupProps {
  phone: string;
}
export const CheckoutAuthModal = (
  {
    phone,
    setPopupVisible,
    ...rest
  }: CheckoutAuthModalProps,
) => {
  const {
    onSubmit,
    setValue,
    errors,
    register,
    clearErrors,
    disabled,
    setResendCode,
  } = useCheckoutAuthForm({ phone, setPopupVisible });
  const [codeRequestTime, setCodeRequestTime] = useState(timestamp());
  const onCodeResend = (requestTime: number) => {
    setCodeRequestTime(requestTime);
  };
  const description = (
    <p>
      Введите код, отправленный по SMS
      <br />
      на ваш номер
      <span>{phone}</span>
    </p>
  );
  return (
    <Popup
      setPopupVisible={setPopupVisible}
      {...rest}
    >
      <FormContainer
        title="Подтверждение"
        id="login-by-code"
        onSubmit={onSubmit}
        description={description}
        className={styles.root}
      >
        <CodeInput
          setCode={(code) => {
            clearErrors();
            setValue('code', code);
            onSubmit();
          }}
          disabled={disabled}
          hasError={errors.code !== undefined}
          help={errors.code?.message}
        />
        <ResendCode
          startTime={codeRequestTime}
          onResend={onCodeResend}
          setResendCode={setResendCode}
        />
        <CheckboxInput
          label="Я дизайнер"
          {...register('merge_carts')}
        />
      </FormContainer>
    </Popup>
  );
};
