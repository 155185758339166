import { ErrorOption } from 'react-hook-form';

type FormErrorType = Exclude<ErrorOption['type'], undefined>;

type FormErrorsMessages = Partial<Record<FormErrorType, string>>;

export function useFormErrorMessages(): FormErrorsMessages {
  return {
    required: 'Заполните поле',
    validate: 'Поле введено неверно',
    pattern: 'Пароль не соотвествует требованиям',
    undefinedContact: 'E-mail введен неправильно, либо такой учётной записи ещё не существует',
  };
}
