import { ReactNode } from 'react';
import cn from 'classnames';
import { formatPrice } from 'shared/lib/format';
import { Cartproduct } from 'shared/api';
import styles from './ProductRow.module.scss';

interface ProductRowProps {
  product: Cartproduct;
  actions?: ReactNode[];
  addToCartAction?: ReactNode;
  incDecAction?: ReactNode;
  className?: string;
  withAmount?: boolean;
}
export const ProductRow = (
  { product, actions, addToCartAction, incDecAction, className, withAmount }: ProductRowProps,
) => (
  <div className={styles.root}>
    <div className={styles.wrapper}>
      <img src={product?.productFiles?.[0].file_path} alt="" className={styles.img} />
      <div className={styles.info}>
        <h2 className={styles.title}>{product.name}</h2>
        <div className={styles.properties}>
          {
              product.attribute_rows?.map((row) => (
                <div key={row} className={styles.property}>
                  <span>{row}</span>
                </div>
              ))
            }
        </div>
      </div>
    </div>
    <div className={cn(styles.price, className, addToCartAction && styles.withBtn)}>
      {incDecAction}
      {
        withAmount && (
          product.amount && <span className={styles.count}>{`${product.amount} шт`}</span>
        )
      }
      <span className={styles.total}>{formatPrice(product.price)}</span>
      {addToCartAction}
    </div>
    <div className={styles.actions}>
      {actions}
    </div>
  </div>
);
