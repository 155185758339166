import { api as meApi } from './me.csr.generated';
import { TAG_USER } from './tags';

export { invalidateUserTags } from './invalidateUserTags';
export { useIsUserExistsQuery, useLazyIsUserExistsQuery } from './isUserExists.csr.generated';
export { TAG_USER } from './tags';
export { useMeQuery } from './me.csr.generated';

export const userApi = meApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: { me: { providesTags: [TAG_USER] } },
});
