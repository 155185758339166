import { forwardRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { LogoIcon, MainMenu, FavoriteIcon, UserIcon } from 'shared/ui';
import { CartLink } from 'features/cart';
import { useMenuQuery } from 'shared/api/menu';
import { menuIds } from 'entities/menu';
import { Paths, phone, phoneTo } from 'shared/config';
import { HeaderMobile } from './HeaderMobile';
import styles from './Header.module.scss';

interface HeaderProps {}

const rightMenuItems = [
  {
    link: Paths.Favourites,
    text: 'Избранное',
    id: '1',
    parent_id: '1',
    icon: <FavoriteIcon />,
  },
  {
    link: Paths.Cart,
    text: 'Корзина',
    id: '2',
    parent_id: '1',
    icon: <CartLink />,
  },
  {
    link: Paths.Profile,
    text: 'Профиль',
    id: '3',
    parent_id: '1',
    icon: <UserIcon />,
  },
];

export const Header = forwardRef<HTMLDivElement, HeaderProps>((props, ref) => {
  const query = useMenuQuery({ input: { id: [menuIds.header] } });
  const leftMenuItemsData = useMemo(() => query.currentData?.Menu, [query.currentData]);
  // TODO: Fix the method of sending the props in MainMenu
  const leftMenuItems = leftMenuItemsData && leftMenuItemsData?.[0].items;
  return (
    <header className={styles.root} ref={ref}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={cn(styles.main)}>
            <Link to={Paths.Root}>
              <LogoIcon />
            </Link>
            {
              leftMenuItems && <MainMenu items={leftMenuItems} />
            }
          </div>
          <div className={styles.actions}>
            <Link to={phoneTo}>{phone}</Link>
            <MainMenu items={rightMenuItems} />
          </div>
        </div>
      </div>
      <HeaderMobile />
    </header>
  );
});
