import { TAG_CART } from './tags';
import { api as cartApi } from './cart.csr.generated';
import { api as addToCartApi } from './addToCart.csr.generated';
import { api as getCartCountApi } from './getCartCount.csr.generated';
import { api as incCartApi } from './incCart.csr.generated';
import { api as decCartApi } from './decCart.csr.generated';
import { api as deleteFromCartApi } from './deleteFromCart.csr.generated';
import { api as changeCartAttributeApi } from './changeCartAttribute.csr.generated';

export { useCartQuery } from './cart.csr.generated';
export { useCartAddProductMutation } from './addToCart.csr.generated';
export { useGetCartCountQuery } from './getCartCount.csr.generated';
export { useCartIncreaseAmountMutation } from './incCart.csr.generated';
export { useCartDecreaseAmountMutation } from './decCart.csr.generated';
export { useCartDeleteProductMutation } from './deleteFromCart.csr.generated';
export { useChangeCartAttributeMutation } from './changeCartAttribute.csr.generated';
export { TAG_CART } from './tags';
export type { CartFragment } from './cart.fragment.generated';

cartApi.enhanceEndpoints({
  addTagTypes: [TAG_CART],
  endpoints: { Cart: { providesTags: [TAG_CART] } },
});

addToCartApi.enhanceEndpoints({
  addTagTypes: [TAG_CART],
  endpoints: { CartAddProduct: { invalidatesTags: [TAG_CART] } },
});

getCartCountApi.enhanceEndpoints({
  addTagTypes: [TAG_CART],
  endpoints: { getCartCount: { providesTags: [TAG_CART] } },
});

incCartApi.enhanceEndpoints({
  addTagTypes: [TAG_CART],
  endpoints: { CartIncreaseAmount: { invalidatesTags: [TAG_CART] } },
});

decCartApi.enhanceEndpoints({
  addTagTypes: [TAG_CART],
  endpoints: { CartDecreaseAmount: { invalidatesTags: [TAG_CART] } },
});

deleteFromCartApi.enhanceEndpoints({
  addTagTypes: [TAG_CART],
  endpoints: { CartDeleteProduct: { invalidatesTags: [TAG_CART] } },
});

changeCartAttributeApi.enhanceEndpoints({
  addTagTypes: [TAG_CART],
  endpoints: { ChangeCartAttribute: { invalidatesTags: [TAG_CART] } },
});
