export const Root = '/';
export const Catalog = '/catalog';
export const Products = '/catalog/:path';
export const Product = '/catalog/:path/:slug';
export const Cart = '/cart';
export const LoginByPassword = '/login-by-password';
export const LoginByCode = '/login-by-code';
export const ResetPassword = '/reset-password';
export const Profile = '/profile/info';
export const Favourites= '/favourites';
export const ProfileAddress= '/profile/address';
export const ProfileAddressEdit = '/profile/address/edit';
export const ProfilePassword= '/profile/password';
export const ProfileCreatePassword = '/profile/password/create';
export const ProfileChangePassword = '/profile/password/update';
export const ProfileHistory= '/profile/history';
export const OrderCard = '/profile/history/:id';
export const ProfileName = '/profile/info/edit-name';
export const ProfileEmail = '/profile/info/edit-email';
export const ProfilePhone = '/profile/info/edit-phone';
export const Portfolio = '/portfolio';
export const Cooperation = '/cooperation';
export const Contacts = '/contacts';
export const About = '/about';
export const PdfCatalog = '/pdf-catalog';
export const ThreeDMaxCatalog = '/3dmax-catalog';
export const Checkout = '/checkout';
export const CheckoutSuccess = '/checkout-success';
