import type { ActionCreatorWithPayload, AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { TagDescription } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TAG_USER } from './tags';

type InvalidateUserTagsOptions<T extends string> = {
  invalidateTags: ActionCreatorWithPayload<Array<TagDescription<T>>, string>,
  dispatch: ThunkDispatch<any, any, AnyAction>,
  queryFulfilled: PromiseLike<unknown>,
};

export async function invalidateUserTags<T extends string>(
  { queryFulfilled, dispatch, invalidateTags }: InvalidateUserTagsOptions<T>,
) {
  try {
    await queryFulfilled;
    // Create macrotask to invalidate tags
    // this will allow to update store if needed after query is fulfilled.
    setTimeout(() => {
      dispatch(invalidateTags([TAG_USER as T]));
    }, 0);
  } catch {
    // Ignore
  }
}
