/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgFavorite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m26 16.763-10 9.904-10-9.904a6.666 6.666 0 1 1 10-8.755 6.667 6.667 0 1 1 10 8.763"
    />
  </svg>
);
export default SvgFavorite;
