/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type CheckoutCreateOrderMutationVariables = Types.Exact<{
  input: Types.CheckoutInput;
}>;


export type CheckoutCreateOrderMutation = { __typename?: 'Mutation', CheckoutCreateOrder: { __typename?: 'OrderFront', id?: string, auth?: { __typename?: 'AuthResponse', token?: string, tokenType?: string, expiresIn?: number } } };


export const CheckoutCreateOrderDocument = `
    mutation CheckoutCreateOrder($input: CheckoutInput!) {
  CheckoutCreateOrder(input: $input) {
    id
    auth {
      token
      tokenType: token_type
      expiresIn: expires_in
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CheckoutCreateOrder: build.mutation<CheckoutCreateOrderMutation, CheckoutCreateOrderMutationVariables>({
      query: (variables) => ({ document: CheckoutCreateOrderDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCheckoutCreateOrderMutation } = injectedRtkApi;

