import { ProductAttributeValues } from 'shared/api';
import { ArrowRightIcon, TooltipComponent } from 'shared/ui';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AttributesIds, updateAttribute, clearAttributeValue, selectedAttributes, transformAttributes } from '../model';
import { AttributeModal } from './modal';
import styles from './ProductAttribute.module.scss';

interface ProductAttributeProps {
  value?: ProductAttributeValues;
  productVariantValues?: ProductAttributeValues[];
}

export const ProductAttribute = ({ value, productVariantValues }: ProductAttributeProps) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [selectedAttributeId, setSelectedAttributeId] = useState<string[]>([]);
  const attributeValues = useSelector(selectedAttributes);
  const materialId = attributeValues.attributes[AttributesIds.Material];
  const formId = attributeValues.attributes[AttributesIds.Form];
  const attributesId = transformAttributes(attributeValues);
  const dispatch = useDispatch();
  const setAttributeId = () => {
    if (!selectedAttributeId.includes(value!.attribute_id)) {
      setSelectedAttributeId((prevState) => [...prevState, value!.attribute_id]);
    }
    setPopupVisible(true);
  };
  const currentAttributes = productVariantValues?.filter(
    (item) => item.attribute_id === selectedAttributeId[0],
  );
  const updateAttributes = (attribute: ProductAttributeValues) => {
    if (attribute.attribute_id === AttributesIds.Material
      && !attributesId.includes(attribute!.attributeValue!.id)) {
      dispatch(clearAttributeValue(AttributesIds.TableColor));
    }
    if (attribute.attribute_id === AttributesIds.Form
      && !attributesId.includes(attribute!.attributeValue!.id)) {
      dispatch(clearAttributeValue(AttributesIds.Size));
    }
    dispatch(
      updateAttribute(
        {
          attributeId: attribute.attribute_id,
          attributeValueId: attribute!.attributeValue!.id,
        },
      ),
    );
    setPopupVisible(false);
  };
  useEffect(() => {
    if (!isPopupVisible) {
      setTimeout(() => {
        setSelectedAttributeId([]);
      }, 300);
    }
  }, [isPopupVisible]);
  const imagePath = useMemo(() => {
    const json = { url: '' };
    try {
      json.url = value?.attributeValue?.vars
        && JSON.parse(value?.attributeValue?.vars).thumblains_image_paths[0];
    } catch (error) {
      console.warn('error');
    }
    return json.url;
  }, [value]);
  return (
    <>
      <div className={styles.root}>
        <span className={styles.title}>{value?.attribute?.title}</span>
        <button type="button" className={styles.btn} onClick={setAttributeId}>
          <div className={styles.wrapper}>
            <TooltipComponent
              id={value?.attribute_id}
              value={value?.attributeValue?.value}
              className={styles.tooltip}
            />
            <span className={styles.value}>{value?.attributeValue?.value}</span>
            {
              (value?.attribute_id === AttributesIds.LegColor
                || value?.attribute_id === AttributesIds.TableColor
              ) && (
                <img src={imagePath} alt={value.attributeValue?.value} />
              )
            }
          </div>
          <ArrowRightIcon className={styles.icon} />
        </button>
      </div>
      <AttributeModal
        isPopupVisible={isPopupVisible}
        setPopupVisible={setPopupVisible}
        updateAttributes={updateAttributes}
        selectedAttributeId={selectedAttributeId}
        setSelectedAttributeId={setSelectedAttributeId}
        currentAttributes={currentAttributes}
        formId={formId}
        materialId={materialId}
        steps={1}
      />
    </>
  );
};
