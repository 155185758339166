/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState, FormEvent } from 'react';
import { Button, PhoneInput, Textarea, TextInput, Select } from 'shared/ui';
import { useFormErrorMessages, useValidateContact } from 'shared/lib';
import { formatPrice } from 'shared/lib/format';
import { SelectOption } from 'shared/ui/form/select/Select';
import { PickupPointItem, CityAutocomplete, StreetAutocomplete } from 'entities/address';
import { useLocation } from 'react-router';
import styles from './CheckoutForm.module.scss';
import {
  deliveryMethods,
  useCheckoutForm,
  krasnoyarskGuid,
  useCheckoutShowAuth,
  usePvzList,
} from '../model';
import { CheckoutAuthModal } from './CheckoutAuthModal';

export const CheckoutForm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { state } = useLocation();
  const {
    register,
    errors,
    cdekDelivery,
    watch,
    onSubmit,
    setValue,
    unregister,
    resetField,
    userPhone,
  } = useCheckoutForm({ setIsModalVisible });
  const deliveryValue = watch('delivery');
  const cityFiasId = watch('city');
  const getCityFias = () => {
    if (cityFiasId) {
      const { cityId } = cityFiasId;
      return cityId;
    }
    return null;
  };
  const errorMessage = useFormErrorMessages();
  const [code, setCode] = useState('');
  const { pvzList, deliveryPrice } = usePvzList({ deliveryId: watch('delivery'), postalCode: code, city: getCityFias() });
  const { onBlur: onBlurAuth } = useCheckoutShowAuth({ setIsModalVisible });
  const validateContact = useValidateContact();
  const renderItem = (item: SelectOption<any>) => (
    <PickupPointItem pickupPoint={item.value} />
  );
  const getGuid = () => {
    if (deliveryValue === deliveryMethods.ByCourier) {
      return krasnoyarskGuid;
    }
    return getCityFias();
  };
  useEffect(() => {
    if (deliveryValue !== deliveryMethods.ToPvz) {
      unregister('pvz');
    } else {
      unregister('street');
    }
    setValue('city', deliveryValue === deliveryMethods.ByCourier ? 'Красноярск' : '');
    setValue('country', 'Россия');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryValue]);
  const city = watch('city');
  useEffect(() => {
    setValue('code', code);
  }, [code, setValue]);
  useEffect(() => {
    resetField('street');
    resetField('apartment');
    resetField('code');
  }, [city, resetField]);
  useEffect(() => {
    setValue('phone', userPhone ?? '');
  }, [setValue, userPhone]);
  const onPhoneBlur = (e: FormEvent<HTMLInputElement>) => {
    onBlurAuth(e);
  };
  // TODO: Fix type as keyof typeof errorMessage
  return (
    <>
      <form className={styles.root} onSubmit={onSubmit}>
        <div className={styles.column}>
          <h2 className={styles.title}>Личные данные</h2>
          <div className={styles.inputs}>
            <TextInput
              id="name"
              label="Имя"
              required
              {...register('name', { required: true })}
              hasError={errors.name != null}
              help={errors.name ? errorMessage[errors.name.type] : undefined}
            />
            <TextInput
              id="surname"
              label="Фамилия"
              required
              {...register('surname', { required: true })}
              hasError={errors.surname != null}
              help={errors.surname ? errorMessage[errors.surname.type] : undefined}
            />
            <PhoneInput
              key={userPhone}
              id="phone"
              label="Телефон"
              defaultValue={userPhone}
              disabled={!!userPhone}
              required
              {...register('phone', { required: errorMessage.required, validate: validateContact })}
              hasError={errors.phone != null}
              externalOnBlur={onPhoneBlur}
              help={errors.phone ? errors.phone.message : undefined}
            />
            <Textarea
              id="comment"
              label="Комментарий к заказу"
              placeholder="Ваше сообщение.."
              maxLength={512}
              {...register('comment')}
              hasError={errors.comment != null}
              help={errors.comment ? errorMessage[errors.comment.type] : undefined}
            />
          </div>
        </div>
        <div className={styles.column}>
          <h2 className={styles.title}>Информация о доставке</h2>
          <div className={styles.inputs}>
            <Select
              label="Доставка"
              options={cdekDelivery}
              value={watch('delivery')}
              {...register('delivery', { required: true })}
              hasError={errors.delivery != null}
              help={errors.delivery ? errorMessage[errors.delivery.type] : undefined}
            />
            <TextInput
              id="country"
              label="Страна"
              disabled
              {...register('country')}
              hasError={errors.country != null}
              help={errors.country ? errorMessage[errors.country.type] : undefined}
            />
            <CityAutocomplete
              key={deliveryValue}
              setCode={setCode}
              label="Город"
              defaultValue={watch('city')}
              required
              resetField={resetField}
              hasError={errors.city != null}
              help={errors.city
                ? errorMessage[errors.city.type as keyof typeof errorMessage]
                : undefined}
              disabled={deliveryValue === deliveryMethods.ByCourier}
              arg={{ type: 'city' }}
              {...register('city', { required: true })}
            />
            {
              (deliveryValue === deliveryMethods.ByCourier
                || deliveryValue === deliveryMethods.ToTheDoor) ? (
                  <>
                    <StreetAutocomplete
                      setCode={setCode}
                      label="Улица и номер дома"
                      defaultValue={watch('street')}
                      required
                      resetField={resetField}
                      hasError={errors.street != null}
                      help={errors.street
                        ? errorMessage[errors.street.type as keyof typeof errorMessage]
                        : undefined}
                      arg={{ type: 'street', guid: getGuid() }}
                      {...register('street', { required: true })}
                    />
                    <div className={styles.address}>
                      <TextInput
                        id="apartment"
                        label="Квартира"
                        {...register('apartment')}
                        hasError={errors.apartment != null}
                        help={errors.apartment ? errorMessage[errors.apartment.type] : undefined}
                      />
                      <TextInput
                        id="code"
                        label="Индекс"
                        disabled={!!code || !watch('street')}
                        {...register('code')}
                        hasError={errors.code != null}
                        help={errors.code ? errorMessage[errors.code.type] : undefined}
                      />
                    </div>
                  </>
                ) : (
                  <Select
                    label="Отделение транспортной компании"
                    options={pvzList}
                    itemElement={renderItem}
                    {...register('pvz', { required: true })}
                    hasError={errors.pvz != null}
                    help={errors.pvz
                      ? errorMessage[errors.pvz.type as keyof typeof errorMessage]
                      : undefined}
                  />
                )
            }
          </div>
          <Button type="submit" className={styles.submit}>Оставить заявку</Button>
          {
            deliveryValue === deliveryMethods.ByCourier ? (
              <div className={styles.info}>
                <span>{`Заказ ${formatPrice(state.cartSum)}`}</span>
                <span>+ Доставка по Красноярску бесплатно</span>
              </div>
            ) : (
              <div className={styles.delivery}>
                <span>{`Заказ ${formatPrice(state.cartSum)} `}</span>
                {
                  deliveryPrice && <span>{` + Доставка ${formatPrice(deliveryPrice)}`}</span>
                }
              </div>
            )
          }
        </div>
      </form>
      <CheckoutAuthModal
        size="sm"
        isPopupVisible={isModalVisible}
        setPopupVisible={setIsModalVisible}
        phone={watch('phone')}
      />
    </>
  );
};
