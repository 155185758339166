/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgAttributeArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#AttributeArrow_svg__a)">
      <path
        stroke="#95989F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m9 6 6 6-6 6"
      />
    </g>
  </svg>
);
export default SvgAttributeArrow;
