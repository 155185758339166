/* eslint-disable react/jsx-props-no-spreading */
import { nanoid } from '@reduxjs/toolkit';
import { forwardRef, InputHTMLAttributes, useRef } from 'react';
import styles from './CheckboxInput.module.scss';

interface CheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
}

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>((
  { label, ...rest },
  ref,
) => {
  const { current: id } = useRef(nanoid());
  return (
    <div className={styles.root}>
      <label htmlFor={id} className={styles.container}>
        <span className={styles.label}>{label}</span>
        <input id={id} ref={ref} className={styles.input} type="checkbox" {...rest} />
        <span className={styles.checkmark} />
      </label>
    </div>
  );
});
