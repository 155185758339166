/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type IsUserExistsQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.IsUserExistsInput>;
}>;


export type IsUserExistsQuery = { __typename?: 'Query', IsUserExists: boolean };


export const IsUserExistsDocument = `
    query IsUserExists($input: IsUserExistsInput) {
  IsUserExists(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    IsUserExists: build.query<IsUserExistsQuery, IsUserExistsQueryVariables | void>({
      query: (variables) => ({ document: IsUserExistsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useIsUserExistsQuery, useLazyIsUserExistsQuery } = injectedRtkApi;

