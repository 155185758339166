/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type LoginStep2MutationVariables = Types.Exact<{
  input: Types.LoginByCodeStep2Input;
}>;


export type LoginStep2Mutation = { __typename?: 'Mutation', LoginByCodeStep2: { __typename?: 'AuthResponse', token?: string, tokenType?: string, expiresIn?: number } };


export const LoginStep2Document = `
    mutation LoginStep2($input: LoginByCodeStep2Input!) {
  LoginByCodeStep2(input: $input) {
    token
    tokenType: token_type
    expiresIn: expires_in
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    LoginStep2: build.mutation<LoginStep2Mutation, LoginStep2MutationVariables>({
      query: (variables) => ({ document: LoginStep2Document, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLoginStep2Mutation } = injectedRtkApi;

