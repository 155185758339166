/* eslint-disable react-hooks/exhaustive-deps */
import parse from 'html-react-parser';
import cn from 'classnames';
import { ProductAttributeValues } from 'shared/api';
import { Slider } from 'shared/ui';
import { AttributesIds } from 'entities/product/attribute/model';
import styles from './AttributeConfirmationModal.module.scss';

interface AttributePopupBodyProps {
  attributeId: string;
  attribute: ProductAttributeValues;
}
export const AttributeConfirmationModal = ({ attributeId, attribute }: AttributePopupBodyProps) => {
  switch (attributeId) {
    case AttributesIds.Material: {
      const text = attribute!.attributeValue!.vars
        && parse(JSON.parse(attribute!.attributeValue!.vars).description_html);
      return (
        <div className={styles.text}>
          {text}
        </div>
      );
    }
    case AttributesIds.TableColor:
    case AttributesIds.LegColor: {
      const imagePath = attribute?.attributeValue?.vars
        && JSON.parse(attribute?.attributeValue?.vars).image_paths[0];
      // TODO: Fix the image display
      return (
        <div className={styles.color}>
          <img src={imagePath} alt={attribute?.attributeValue?.value} />
        </div>
      );
    }
    case AttributesIds.LegStyle: {
      const parsedData = attribute!.attributeValue!.vars
        && JSON.parse(attribute!.attributeValue!.vars);
      const text = parse(parsedData.description_html);
      const images = parsedData.image_paths;
      return (
        <div className={styles.style}>
          <div className={styles.styleSlider}>
            <Slider slides={images} />
          </div>
          <div className={cn(styles.text, styles.styleText)}>{text}</div>
        </div>
      );
    }
    default:
      return <div />;
  }
};
