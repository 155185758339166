/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type DeliveryCalcQueryVariables = Types.Exact<{
  input: Types.DeliveryCalcInput;
}>;


export type DeliveryCalcQuery = { __typename?: 'Query', DeliveryCalc: { __typename?: 'DeliveryCalcFront', price: number, pickup_point?: Array<{ __typename?: 'DeliveryPickupPointFront', id: string, type: string, address: string, schedule?: string }> } };


export const DeliveryCalcDocument = `
    query DeliveryCalc($input: deliveryCalcInput!) {
  DeliveryCalc(input: $input) {
    price
    pickup_point {
      id
      type
      address
      schedule
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    DeliveryCalc: build.query<DeliveryCalcQuery, DeliveryCalcQueryVariables>({
      query: (variables) => ({ document: DeliveryCalcDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useDeliveryCalcQuery, useLazyDeliveryCalcQuery } = injectedRtkApi;

