import { TAG_PRODUCT } from './tags';
import { api as productApi } from './product.csr.generated';

export { useProductQuery } from './product.csr.generated';
export { TAG_PRODUCT } from './tags';

productApi.enhanceEndpoints({
  addTagTypes: [TAG_PRODUCT],
  endpoints: { Product: { providesTags: [TAG_PRODUCT] } },
});
