/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type GetCartCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetCartCountQuery = { __typename?: 'Query', Cart?: { __typename?: 'CartFront', count: number } };


export const GetCartCountDocument = `
    query getCartCount {
  Cart {
    count: products_count
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    getCartCount: build.query<GetCartCountQuery, GetCartCountQueryVariables | void>({
      query: (variables) => ({ document: GetCartCountDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetCartCountQuery, useLazyGetCartCountQuery } = injectedRtkApi;

