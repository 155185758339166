// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordConfirmationForm_root__28EuU {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.5rem;
}

.PasswordConfirmationForm_submit__BYHYe {
  width: 100%;
  height: 56px;
  margin: 1.75rem 0 1.5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/edit/data/ui/PasswordConfirmationForm.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;EACA,0BAAA;AADF","sourcesContent":["@import 'shared/styles/theme';\n\n.root {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: spacing(10);\n}\n\n.submit {\n  width: 100%;\n  height: 56px;\n  margin: spacing(7) 0 spacing(6) 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PasswordConfirmationForm_root__28EuU`,
	"submit": `PasswordConfirmationForm_submit__BYHYe`
};
export default ___CSS_LOADER_EXPORT___;
