/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type PersonalUpdateProfileMutationVariables = Types.Exact<{
  input: Types.PersonalUpdateProfileInput;
}>;


export type PersonalUpdateProfileMutation = { __typename?: 'Mutation', PersonalUpdateProfile: { __typename?: 'User', id: string } };


export const PersonalUpdateProfileDocument = `
    mutation PersonalUpdateProfile($input: PersonalUpdateProfileInput!) {
  PersonalUpdateProfile(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    PersonalUpdateProfile: build.mutation<PersonalUpdateProfileMutation, PersonalUpdateProfileMutationVariables>({
      query: (variables) => ({ document: PersonalUpdateProfileDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { usePersonalUpdateProfileMutation } = injectedRtkApi;

