/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
import { useRef, useCallback, type FormEvent, type KeyboardEvent } from 'react';
import { FormControl, FormGroup, FormText } from 'react-bootstrap';
import cn from 'classnames';
import styles from './Input.module.scss';
import codeStyles from './CodeInput.module.scss';

const isValidRegex = /^\d{1}$/;

interface PhoneCodeInputProps {
  setCode: (value: string) => void;
  codeLength?: number;
  hasError?: boolean;
  help?: string;
  disabled?: boolean;
}

type InputMap = Map<number, HTMLInputElement>;

export const CodeInput = (
  { setCode, codeLength = 4, hasError, help, disabled }: PhoneCodeInputProps,
) => {
  const inputs = useRef<InputMap>(new Map());
  const valueStore = useRef<Map<number, string>>(new Map());
  const segments = Array<number>(codeLength).fill(0);

  if (hasError) {
    const input = inputs.current.get(0);
    input?.focus();
  }

  const trySubmitCode = useCallback(
    (nextIndex: number) => {
      const parts = new Array(codeLength);
      const emptyIndices = [];
      for (const [index, input] of inputs.current) {
        const { value } = input;
        if (value != null && isValidRegex.test(value)) {
          parts[index] = value;
        } else {
          emptyIndices.push(index);
        }
      }
      if (emptyIndices.length > 0) {
        const emptyInput = inputs.current.get(
          emptyIndices.includes(nextIndex) ? nextIndex : emptyIndices[0],
        );
        emptyInput?.focus();
      } else {
        setCode(parts.join(''));
      }
    },
    [codeLength, setCode],
  );

  const tryOnPaste = (value: string) => {
    if (value.length !== codeLength) {
      return false;
    }
    const chars = [...value];
    for (const [index, char] of chars.entries()) {
      const input = inputs.current.get(index);
      if (input) {
        input.value = char;
        input.classList.add(styles.hasValue);
      }
    }
    return true;
  };

  const createOnInput = (index: number) => (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    if (!isValidRegex.test(value)) {
      // Replace non-numeric symbols
      const newValue = value.replace(/[^\d]/g, '');
      if (!tryOnPaste(newValue)) {
        e.currentTarget.value = newValue.slice(0, 1);
      }
    }
    if (isValidRegex.test(e.currentTarget.value)) {
      e.currentTarget.classList.add(styles.hasValue);
      trySubmitCode(index + 1);
    } else {
      e.currentTarget.classList.remove(styles.hasValue);
    }
  };

  const createOnKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    valueStore.current.set(index, e.currentTarget.value);
  };

  const createOnKeyUp = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
    // If input was empty and user pressed backspace focus previous input
    if (!valueStore.current.get(index) && e.key === 'Backspace') {
      const input = inputs.current.get(index - 1);
      if (input) {
        input.focus();
        input.value = '';
        valueStore.current.set(index - 1, '');
      }
    }
  };

  const createStoreRef = (index: number) => (instance: HTMLInputElement | null) => {
    if (instance != null) {
      inputs.current.set(index, instance);
    }
  };

  return (
    <FormGroup
      className={cn(codeStyles.root, hasError ? styles.hasError : null)}
    >
      <div className={codeStyles.wrapper}>
        {segments.map((_, i) => (
          <FormControl
            disabled={disabled}
            type="number"
            ref={createStoreRef(i)}
            key={i}
            onInput={createOnInput(i)}
            onKeyDown={createOnKeyDown(i)}
            onKeyUp={createOnKeyUp(i)}
          />
        ))}
      </div>
      {help && (
        <FormText className={codeStyles.help}>{help}</FormText>
      )}
    </FormGroup>
  );
};
