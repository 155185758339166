/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

module.hot?.accept();
export type CartFragment = { __typename?: 'CartFront', sum: number, id?: string, products?: Array<{ __typename?: 'Cartproduct', id: string, name: string, slug: string, amount?: number, price: number, attribute_rows?: Array<string>, productValues?: Array<{ __typename?: 'ProductAttributeValues', id: string, attribute_id: string, attribute?: { __typename?: 'Attribute', id: string, parent_attribute_id?: string, slug?: string, title: string }, attributeValue?: { __typename?: 'AttributeValue', id: string, parent_attribute_value_id?: string, slug?: string, vars?: string, value: string } }>, productVariantValues?: Array<{ __typename?: 'ProductAttributeValues', id: string, attribute_id: string, attribute?: { __typename?: 'Attribute', id: string, parent_attribute_id?: string, slug?: string, title: string }, attributeValue?: { __typename?: 'AttributeValue', id: string, parent_attribute_value_id?: string, vars?: string, slug?: string, value: string } }>, productFiles?: Array<{ __typename?: 'ProductFiles', id: string, file_path: string, fileType?: { __typename?: 'ProductFileType', name: string, id: string } }> }> };

export const CartFragmentDoc = `
    fragment cart on CartFront {
  sum
  id
  products {
    id
    name
    slug
    amount
    price
    attribute_rows
    productValues {
      id
      attribute_id
      attribute {
        id
        parent_attribute_id
        slug
        title
      }
      attributeValue {
        id
        parent_attribute_value_id
        slug
        vars
        value
      }
    }
    productVariantValues {
      id
      attribute_id
      attribute {
        id
        parent_attribute_id
        slug
        title
      }
      attributeValue {
        id
        parent_attribute_value_id
        vars
        slug
        value
      }
    }
    productFiles {
      id
      file_path
      fileType {
        name
        id
      }
    }
  }
}
    `;
