/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.5em"
    height="2em"
    fill="none"
    viewBox="0 0 40 32"
    {...props}
  >
    <g fill="#21252E" clipPath="url(#Logo_svg__a)">
      <path d="M26.056 16.947c.15 4.327-3.225 8.136-4.377 9.96-.866 1.742 1.21 2.09 1.903 1.393 1.442-2.497 4.568-7.98 6.23-9.932 1.66-1.952 2.076.348 1.384 1.22-.865 2.09-2.621 5.459-3.288 6.97-.692 1.568.615 1.742 1.038.871 1.385-2.846 4.588-8.353 6.057-9.41 1.211-.871 1.73 0 1.211 1.046-.519.755-1.903 2.997-3.288 5.924-1.73 3.66 1.385 3.485 2.077 3.485s4.499-3.833 5.191-4.879c.554-.836.093-1.045-.346-.522-.634.755-2.076 2.265-3.288 3.31-1.05.908-1.73-.174-1.211-1.22l2.942-5.227c.346-.871.311-2.3-1.212-3.137-1.523-.836-3.691 1.278-4.672 2.44.346-1.046-.346-2.265-1.211-2.44-1.28-.258-2.769 1.51-3.46 2.614 0-1.411-.604-2.33-1.156-2.797-.242-.205-.535.015-.524.331" />
      <path d="M20.433 17.16c-.969.14-4.556 4.24-6.23 6.273-.23.28.174.697.52.175a5.4 5.4 0 0 1 .692-.872c.519-.522 1.384-.174.865.523-1.23 1.653-3.288 6.273 1.558 5.054 3.876-.976 7.498-6.332 8.825-8.887-5.192 7.493-7.614 7.493-8.825 6.97-.97-.419.776-4.72 2.25-7.319.691-1.22 1.314-2.056.345-1.917M23.548 11.933c-.577-.349-1.47.208-1.904.87-.484.742-.553 1.883 0 2.44.97.418 1.788-.523 2.077-1.045.404-.523.692-1.743-.173-2.266" />
      <path d="M7.109 26.047c.157-4.756 6.633-16.032 9.69-21.085.257-.425 1.557-.348.865.871-4.153 7.319-11.767 24.919-5.883 24.919 1.054 0 .519.523.519.523s-.53.438-.865.522c-.692.175-4.5-.522-4.326-5.75" />
      <path d="M12.646 4.44C1.156 6.111.591 9.085 1.744 10.363-2.409 7.75 3.648.954 16.626.606 28.043.299 30.469 5.834 30.123 7.925c-.346 2.09-3.114 2.44-2.076 1.22.83-.976.807-1.917.692-2.266-.577-1.51-4.603-4.112-16.093-2.44" />
    </g>
    <defs>
      <clipPath id="Logo_svg__a">
        <path fill="#fff" d="M0 0h40v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLogo;
