/* eslint-disable react/jsx-props-no-spreading */
import { FormContainer } from 'entities/auth';
import { Button, ContactInput } from 'shared/ui';
import { useResetPasswordForm } from 'features/auth/reset-password/model';
import { useFormErrorMessages, useValidateContact } from 'shared/lib';
import { MultistepPageProps } from 'shared/lib/multistep/types';
import styles from './ResetPasswordForm.module.scss';

export const ResetPasswordForm = ({ pagination }: MultistepPageProps) => {
  const { register, errors, onSubmit } = useResetPasswordForm(pagination);
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  return (
    <FormContainer
      id="reset-password"
      title="Восстановление пароля"
      description="Введите почту или телефон, мы отправим туда код подтверждения"
      onSubmit={onSubmit}
      className={styles.root}
    >
      <ContactInput
        id="contact"
        label="Почта или номер телефона"
        position="center"
        hasError={errors.contact != null}
        help={errors.contact ? errors.contact.message : undefined}
        className={styles.contact}
        {...register('contact', { required: errorMessage.required, validate: validateContact })}
      />
      <Button form="reset-password" type="submit" className={styles.submit}>Получить код</Button>
    </FormContainer>
  );
};
