import { Link } from 'react-router-dom';
import { Paths } from 'shared/config';
import { LoginByPasswordForm } from 'features/auth/login-by-password';
import { Container } from 'react-bootstrap';
import styles from './LoginByPasswordPage.module.scss';

export const LoginByPasswordPage = () => (
  <Container>
    <section className={styles.root}>
      <LoginByPasswordForm footer={(
        <div className="link-container">
          <Link to={Paths.ResetPassword} className="link">Забыли пароль?</Link>
          <Link to={Paths.LoginByCode} className="link">Войти по коду</Link>
        </div>
      )}
      />
    </section>
  </Container>
);
