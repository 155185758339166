import { createBrowserRouter, createRoutesFromElements, Route } from 'react-router-dom';
import { Page404 } from 'pages/404';
import { AuthWrapper } from 'features/auth/data';
import Layout from './Layout';
import { publicRoutes, authRoutes, privateRoutes } from './routes';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<Page404 />} element={<Layout />}>
      {publicRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={route.element}
        />
      ))}
      <Route element={<AuthWrapper reversed />}>
        {authRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.element}
          />
        ))}
      </Route>
      <Route element={<AuthWrapper />}>
        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={route.element}
          />
        ))}
      </Route>
    </Route>,
  ),
);

export default router;
