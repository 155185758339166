/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ResetPasswordStep3MutationVariables = Types.Exact<{
  input: Types.PasswordRecoveryStep3Input;
}>;


export type ResetPasswordStep3Mutation = { __typename?: 'Mutation', PasswordRecoveryStep3: { __typename?: 'AuthResponse', token?: string, tokenType?: string, expiresIn?: number } };


export const ResetPasswordStep3Document = `
    mutation ResetPasswordStep3($input: PasswordRecoveryStep3Input!) {
  PasswordRecoveryStep3(input: $input) {
    token
    tokenType: token_type
    expiresIn: expires_in
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ResetPasswordStep3: build.mutation<ResetPasswordStep3Mutation, ResetPasswordStep3MutationVariables>({
      query: (variables) => ({ document: ResetPasswordStep3Document, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useResetPasswordStep3Mutation } = injectedRtkApi;

