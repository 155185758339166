import { useMemo } from 'react';
import { CatalogItem } from 'entities/catalog';
import { LoadingHOC } from 'shared/ui';
import { useCategoryQuery } from 'shared/api/categories';
import styles from './CategoriesList.module.scss';

export const CategoriesList = () => {
  const query = useCategoryQuery();
  const categories = useMemo(() => query?.currentData?.Category, [query?.currentData]);
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Каталог</h1>
      <p className={styles.subtitle}>
        Нажмите, чтобы открыть
        <br />
        конструктор индивидуального дизайна
      </p>
      <div className={styles.wrapper}>
        <LoadingHOC isLoading={!categories} className={styles.loader}>
          {
            categories?.map((
              { id, name, slug, imagePath },
            ) => (
              <CatalogItem
                name={name}
                slug={slug}
                imagePath={imagePath}
                key={id}
              />
            ))
          }
        </LoadingHOC>
      </div>
    </div>
  );
};
