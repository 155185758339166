/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { CartFragmentDoc } from './cart.fragment.generated';
import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type CartDeleteProductMutationVariables = Types.Exact<{
  input: Types.CartProductInput;
}>;


export type CartDeleteProductMutation = { __typename?: 'Mutation', CartDeleteProduct?: { __typename?: 'CartFront', sum: number, id?: string, products?: Array<{ __typename?: 'Cartproduct', id: string, name: string, slug: string, amount?: number, price: number, attribute_rows?: Array<string>, productValues?: Array<{ __typename?: 'ProductAttributeValues', id: string, attribute_id: string, attribute?: { __typename?: 'Attribute', id: string, parent_attribute_id?: string, slug?: string, title: string }, attributeValue?: { __typename?: 'AttributeValue', id: string, parent_attribute_value_id?: string, slug?: string, vars?: string, value: string } }>, productVariantValues?: Array<{ __typename?: 'ProductAttributeValues', id: string, attribute_id: string, attribute?: { __typename?: 'Attribute', id: string, parent_attribute_id?: string, slug?: string, title: string }, attributeValue?: { __typename?: 'AttributeValue', id: string, parent_attribute_value_id?: string, vars?: string, slug?: string, value: string } }>, productFiles?: Array<{ __typename?: 'ProductFiles', id: string, file_path: string, fileType?: { __typename?: 'ProductFileType', name: string, id: string } }> }> } };


export const CartDeleteProductDocument = `
    mutation CartDeleteProduct($input: CartProductInput!) {
  CartDeleteProduct(input: $input) {
    ...cart
  }
}
    ${CartFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CartDeleteProduct: build.mutation<CartDeleteProductMutation, CartDeleteProductMutationVariables>({
      query: (variables) => ({ document: CartDeleteProductDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCartDeleteProductMutation } = injectedRtkApi;

