/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon, Button, IconButton, TextInput } from 'shared/ui';
import { FormContainer } from 'features/profile/edit/data';
import { useFormErrorMessages } from 'shared/lib';
import { Paths } from 'shared/config';
import { Link } from 'react-router-dom';
import { useNameForm } from '../model';
import styles from './NameChangeForm.module.scss';

export const NameChangeForm = () => {
  const {
    register,
    onSubmit,
    errors,
    name,
    surname,
    middlename,
  } = useNameForm();
  const errorMessage = useFormErrorMessages();
  return (
    <FormContainer
      title="Редактировать ФИО"
      onSubmit={onSubmit}
      className={styles.root}
    >
      <Link to={Paths.Profile}>
        <IconButton className="stepBack">
          <ArrowBackIcon />
        </IconButton>
      </Link>
      <TextInput
        id="surname"
        label="Фамилия"
        hasError={errors.surname != null}
        required
        defaultValue={surname}
        help={errors.surname ? errorMessage.required : undefined}
        {...register('surname', { required: true })}
      />
      <TextInput
        id="name"
        label="Имя"
        hasError={errors.name != null}
        help={errors.name ? errorMessage.required : undefined}
        required
        defaultValue={name}
        {...register('name', { required: true })}
      />
      <TextInput
        id="middlename"
        label="Отчество"
        hasError={errors.middlename != null}
        defaultValue={middlename}
        help={errors.middlename ? errorMessage.required : undefined}
        {...register('middlename')}
      />
      <Button type="submit" className={styles.submit}>Сохранить</Button>
    </FormContainer>
  );
};
