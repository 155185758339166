// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton_root__Rpog0 {
  border: none;
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
}
.IconButton_root__Rpog0 svg {
  font-size: 1.5rem;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/button/IconButton.module.scss","webpack://./src/shared/styles/mixins/_typography.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,uBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAGE;ECGE,iBAAA;EACA,gBAAA;ADHJ","sourcesContent":["@import \"shared/styles/theme\";\n\n.root {\n  border: none;\n  background: transparent;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding: spacing(1);\n\n  svg {\n    @include typography('text-lg');\n  }\n\n}\n","@mixin typography($variant) {\n  @if $variant == 'h1' {\n    font-size: 4rem;\n    font-weight: 400;\n  }\n  @if $variant == 'h2' {\n    font-size: 2.5rem;\n    font-weight: 400;\n  }\n  @if $variant == 'h3' {\n    font-size: 2rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-lg' {\n    font-size: 1.5rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-md' {\n    font-size: 1.25rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'subtitle' {\n    font-size: 1rem;\n    font-weight: 500;\n  }\n  @else if $variant == 'text' {\n    font-size: 1rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-sm' {\n    font-size: 0.75rem;\n    font-weight: 400;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `IconButton_root__Rpog0`
};
export default ___CSS_LOADER_EXPORT___;
