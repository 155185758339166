/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon, Button, IconButton, PhoneInput } from 'shared/ui';
import { FormContainer } from 'features/profile/edit/data';
import { MultistepPageProps, useFormErrorMessages, useValidateContact } from 'shared/lib';
import { Paths } from 'shared/config';
import { Link } from 'react-router-dom';
import styles from 'features/profile/edit/data/ui/MainForms.module.scss';
import { usePhoneForm } from '../model';

export const PhoneChangeForm = ({ pagination }: MultistepPageProps) => {
  const { register, onSubmit, errors, defaultValue } = usePhoneForm(pagination);
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  return (
    <FormContainer
      title="Изменить телефон"
      onSubmit={onSubmit}
      className={styles.root}
      description="Новый телефон нужно будет подтвердить"
      rootClass
    >
      <Link to={Paths.Profile}>
        <IconButton className="stepBack">
          <ArrowBackIcon />
        </IconButton>
      </Link>
      <PhoneInput
        id="phone"
        label="Телефон"
        hasError={errors.phone != null}
        help={errors.phone ? errors.phone.message : undefined}
        defaultValue={defaultValue}
        {...register('phone', { required: errorMessage.required, validate: validateContact })}
      />
      <Button type="submit" className={styles.submit}>Продолжить</Button>
    </FormContainer>
  );
};
