import { ProductAttributeProps } from 'entities/product/attribute/types';
import { ArrowRightIcon, TooltipComponent } from 'shared/ui';
import { AttributesIds } from 'entities/product/attribute/model';
import { useMemo } from 'react';
import styles from './CartAttribute.module.scss';

export const CartAttribute = ({
  value,
  stepNext,
}: ProductAttributeProps) => {
  const imagePath = useMemo(() => {
    const json = { url: '' };
    try {
      json.url = value?.attributeValue?.vars
        && JSON.parse(value?.attributeValue?.vars).thumblains_image_paths[0];
    } catch (error) {
      console.warn('error');
    }
    return json.url;
  }, [value]);
  return (
    <div className={styles.root}>
      <span className={styles.title}>{value?.attribute?.title}</span>
      <button type="button" className={styles.btn} onClick={() => stepNext(value?.attribute_id)}>
        <div className={styles.wrapper}>
          <TooltipComponent
            id={value?.attribute_id}
            value={value?.attributeValue?.value}
            className={styles.tooltip}
          />
          <span className={styles.value}>{value?.attributeValue?.value}</span>
          {
            (value?.attribute_id === AttributesIds.LegColor
              || value?.attribute_id === AttributesIds.TableColor
            ) && (
              <img src={imagePath} alt={value.attributeValue?.value} />
            )
          }
        </div>
        <ArrowRightIcon className={styles.icon} />
      </button>
    </div>
  );
};
