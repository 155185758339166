// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddToCart_inCart__m6aX3 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #21252E;
  font-size: 1rem;
  font-weight: 400;
  width: 132px;
  height: 40px;
}

.AddToCart_spinner__P5mSC {
  width: 2rem;
  height: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/features/cart/ui/AddToCart.module.scss","webpack://./src/shared/styles/vars/_colors.scss","webpack://./src/shared/styles/mixins/_typography.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cCLQ;ECyBN,eAAA;EACA,gBAAA;EFnBF,YAAA;EACA,YAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;AAAF","sourcesContent":["@import 'shared/styles/theme';\n\n.inCart {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: $primary;\n  @include typography('text');\n  width: 132px;\n  height: 40px;\n}\n\n.spinner {\n  width: spacing(8);\n  height: spacing(8);\n}","$main-bg: #C2CBD1;\n$primary: #21252E;\n$secondary: #FFFFFF;\n\n$grey: #95989F;\n$grey-light: #F4F5F6;\n$grey-hover: #EDEFF0;\n$grey-active: #E1E8EC;\n\n$red: #B43737;\n\n$border: #C2CBD1;\n\n$error: #B43737;\n$link: #3B72C5;\n$disabled: #E5E5E5;\n\n$input-focus: #21252E;\n","@mixin typography($variant) {\n  @if $variant == 'h1' {\n    font-size: 4rem;\n    font-weight: 400;\n  }\n  @if $variant == 'h2' {\n    font-size: 2.5rem;\n    font-weight: 400;\n  }\n  @if $variant == 'h3' {\n    font-size: 2rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-lg' {\n    font-size: 1.5rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-md' {\n    font-size: 1.25rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'subtitle' {\n    font-size: 1rem;\n    font-weight: 500;\n  }\n  @else if $variant == 'text' {\n    font-size: 1rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-sm' {\n    font-size: 0.75rem;\n    font-weight: 400;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inCart": `AddToCart_inCart__m6aX3`,
	"spinner": `AddToCart_spinner__P5mSC`
};
export default ___CSS_LOADER_EXPORT___;
