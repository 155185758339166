import cn from 'classnames';
import { InstagramIcon, LogoIcon, PinterestIcon, VkIcon, Object8LogoIcon } from 'shared/ui';
import { Paths, socials, phoneTo, phone } from 'shared/config';
import { Link, useLocation } from 'react-router-dom';
import { useUser } from 'entities/user';
import { useMenuQuery } from 'shared/api/menu';
import { menuIds } from 'entities/menu';
import { useMemo } from 'react';
import styles from './Footer.module.scss';

export const Footer = () => {
  const query = useMenuQuery({ input: { id: [menuIds.footer, menuIds.footerPolicy] } });
  const footerLinks = useMemo(() => query.currentData?.Menu?.[0].items, [query.currentData]);
  const policyLinks = useMemo(() => query.currentData?.Menu?.[1].items, [query.currentData]);
  const location = useLocation();
  const { isLoading } = useUser();
  const isHideOnMobile = location.pathname === Paths.LoginByPassword
    || location.pathname === Paths.LoginByCode
    || location.pathname === Paths.Checkout
    || location.pathname === Paths.ResetPassword || isLoading;
  return (
    <footer className={cn(styles.root, isHideOnMobile && styles.hide)}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <Link to={Paths.Root}>
              <LogoIcon className={styles.logo_icon} />
            </Link>
            <span className={styles.quality}>
              Качество и дизайн
              <br />
              с 2015 года
            </span>
          </div>
          <nav className={styles.menu}>
            {
              footerLinks?.map((item) => <Link to={item.link} key={item.link}>{item.text}</Link>)
            }
          </nav>
          <div className={styles.socials}>
            <Link to={phoneTo} className={styles.phone}>{phone}</Link>
            <div className={styles.links}>
              <Link
                to={socials.Pinterest}
                target="_blank"
              >
                <PinterestIcon className={cn(styles.icon, styles.pinterest)} />
              </Link>
              <Link
                to={socials.Instagram}
                target="_blank"
              >
                <InstagramIcon className={cn(styles.icon, styles.instagram)} />
              </Link>
              <Link
                to={socials.Vk}
                target="_blank"
              >
                <VkIcon className={cn(styles.icon, styles.vk)} />
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <span className={styles.quality}>Качество и дизайн с 2015 года</span>
          <Link to="https://object8.io/" target="_blank" className={styles.contractor}>
            Сделано в
            <Object8LogoIcon />
          </Link>
          <div className={styles.bottom_links}>
            {
              policyLinks?.map((item) => <Link to={item.link} key={item.link}>{item.text}</Link>)
            }
          </div>
        </div>
      </div>
    </footer>
  );
};
