import { SubmitHandler, useForm } from 'react-hook-form';

interface FormData {
  name: string;
  phone: string;
  comment: string;
}
export const useCallbackForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log(data);
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
  };
};
