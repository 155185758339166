// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordInput_input__Zyhsr {
  position: relative;
  width: 100%;
}

.PasswordInput_visibility__KP\\+vQ {
  border: none;
  background: none;
  position: absolute;
  top: 0.5rem;
  right: 0.75rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}
.PasswordInput_visibility__KP\\+vQ:hover {
  background-color: #F4F5F6;
}
.PasswordInput_visibility__KP\\+vQ:active {
  background-color: #EDEFF0;
}

.PasswordInput_icon__jOke9 {
  color: #95989F;
  width: 1.5rem;
  height: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/form/input/PasswordInput.module.scss","webpack://./src/shared/styles/vars/_colors.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,WAAA;AADF;;AAIA;EACE,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,cAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AADF;AAGE;EACE,yBCjBS;ADgBb;AAIE;EACE,yBCpBS;ADkBb;;AAOA;EACE,cC5BK;ED6BL,aAAA;EACA,cAAA;AAJF","sourcesContent":["@import \"shared/styles/theme\";\n\n.input {\n  position: relative;\n  width: 100%;\n}\n\n.visibility {\n  border: none;\n  background: none;\n  position: absolute;\n  top: spacing(2);\n  right: spacing(3);\n  width: spacing(8);\n  height: spacing(8);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 100%;\n  cursor: pointer;\n\n  &:hover {\n    background-color: $grey-light;\n  }\n\n  &:active {\n    background-color: $grey-hover;\n  }\n\n}\n\n.icon {\n  color: $grey;\n  width: spacing(6);\n  height: spacing(6);\n}","$main-bg: #C2CBD1;\n$primary: #21252E;\n$secondary: #FFFFFF;\n\n$grey: #95989F;\n$grey-light: #F4F5F6;\n$grey-hover: #EDEFF0;\n$grey-active: #E1E8EC;\n\n$red: #B43737;\n\n$border: #C2CBD1;\n\n$error: #B43737;\n$link: #3B72C5;\n$disabled: #E5E5E5;\n\n$input-focus: #21252E;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `PasswordInput_input__Zyhsr`,
	"visibility": `PasswordInput_visibility__KP+vQ`,
	"icon": `PasswordInput_icon__jOke9`
};
export default ___CSS_LOADER_EXPORT___;
