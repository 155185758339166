import { CategoriesList } from 'features/categories';
import { DesignerForm } from 'features/designer';
import { Container } from 'react-bootstrap';
import styles from './CategoriesPage.module.scss';

export const CategoriesPage = () => (
  <Container>
    <div className={styles.root}>
      <CategoriesList />
      <DesignerForm />
    </div>
  </Container>
);
