export const data = [
  {
    id: '1',
    value: 'tables',
    items: [
      {
        id: '1',
        text: 'Стол круглый Камень Волна 14.2',
        link: '',
      },
      {
        id: '2',
        text: 'Стол круглый МДФ Эмаль Волна 14',
        link: '',
      }, {
        id: '3',
        text: 'Стол круглый МДФ Фанера Волна 14 длиннющее название показываем  в две строки',
        link: '',
      },
      {
        id: '4',
        text: 'Стол круглый МДФ Шпон Волна 14',
        link: '',
      },
      {
        id: '5',
        text: 'Стол круглый компакт Плита Шпон Волна 14',
        link: '',
      },
    ],
  },
  {
    id: '2',
    value: 'dressers',
    items: [
      {
        id: '431',
        text: 'Комод Камень Волна 14.2',
        link: '',
      },
      {
        id: '15',
        text: 'Комод круглый МДФ Эмаль Волна 14',
        link: '',
      },
      {
        id: '35',
        text: 'Комод круглый МДФ Фанера Волна 14',
        link: '',
      },
    ],
  },
];
