import { invalidateUserTags, TAG_USER } from '../user';
import { api as resetPasswordApi } from './resetPasswordStep3.csr.generated';

export { useResetPasswordStep1Mutation } from './resetPasswordStep1.csr.generated';
export { useResetPasswordStep2Mutation } from './resetPasswordStep2.csr.generated';
export { useResetPasswordStep3Mutation } from './resetPasswordStep3.csr.generated';

const api = resetPasswordApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: {
    ResetPasswordStep3: {
      invalidatesTags: [],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { invalidateTags } = api.util;
        await invalidateUserTags({ dispatch, queryFulfilled, invalidateTags });
      },
    },
  },
});
