import { Container } from 'react-bootstrap';
import cn from 'classnames';
import { useUser } from 'entities/user';
import { ProfileMenu } from 'entities/profile/menu';
import { Button, LoadingHOC } from 'shared/ui';
import { Paths } from 'shared/config';
import { useMemo } from 'react';
import { useCartQuery } from 'shared/api/cart';
import { AddToCart } from 'features/cart/ui';
import { Cartproduct } from 'shared/api';
import { ProductRow } from 'entities/product/row';
import { EditCartItem, RemoveFromCart } from 'features/cart';
import styles from './FavouritesPage.module.scss';

export const FavouritesPage = () => {
  const { user, isLoading } = useUser();
  const query = useCartQuery();
  const cart = useMemo(() => query.currentData?.Cart, [query.currentData]);
  const cartProductIds = useMemo(() => cart?.products?.map(
    (product: Cartproduct) => product.id,
  ) || [], [cart]);
  return (
    <div className={styles.root}>
      <Container>
        <div className={cn(styles.wrapper, !user && styles.center)}>
          <LoadingHOC isLoading={isLoading} className={styles.loader}>
            { user && <ProfileMenu /> }
          </LoadingHOC>
          <div className={styles.content}>
            <h1 className={styles.title}>Избранное</h1>
            {
              cart?.products?.length ? (
                <div className={styles.cards}>
                  {
                    cart?.products.map((item: Cartproduct) => {
                      const isInCart = cartProductIds.includes(item.id);
                      return (
                        <ProductRow
                          key={item.id}
                          product={item}
                          actions={[
                            <RemoveFromCart productId={item.id} key="remove" />,
                            !isInCart && <EditCartItem product={item} key="edit" />]}
                          addToCartAction={isInCart ? <span>В корзине</span> : <AddToCart text="В корзину" />}
                        />
                      );
                    })
                  }
                </div>
              ) : (
                <div className="empty">
                  <h3 className="info">В избранном ничего нет</h3>
                  <Button type="button" href={Paths.Catalog} className="button">
                    В каталог
                  </Button>
                </div>
              )
            }
          </div>
        </div>
      </Container>
    </div>
  );
};
