// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Actions_root__91bSR {
  border: none;
  background-color: transparent;
}

.Actions_icon__qxV7W {
  font-size: 1.5rem;
  font-weight: 400;
  color: #95989F;
}`, "",{"version":3,"sources":["webpack://./src/features/favourites/ui/Actions.module.scss","webpack://./src/shared/styles/mixins/_typography.scss","webpack://./src/shared/styles/vars/_colors.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,6BAAA;AADF;;AAIA;ECOI,iBAAA;EACA,gBAAA;EDNF,cELK;AFKP","sourcesContent":["@import \"shared/styles/theme\";\n\n.root {\n  border: none;\n  background-color: transparent;\n}\n\n.icon {\n  @include typography('text-lg');\n  color: $grey;\n}","@mixin typography($variant) {\n  @if $variant == 'h1' {\n    font-size: 4rem;\n    font-weight: 400;\n  }\n  @if $variant == 'h2' {\n    font-size: 2.5rem;\n    font-weight: 400;\n  }\n  @if $variant == 'h3' {\n    font-size: 2rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-lg' {\n    font-size: 1.5rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-md' {\n    font-size: 1.25rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'subtitle' {\n    font-size: 1rem;\n    font-weight: 500;\n  }\n  @else if $variant == 'text' {\n    font-size: 1rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-sm' {\n    font-size: 0.75rem;\n    font-weight: 400;\n  }\n}","$main-bg: #C2CBD1;\n$primary: #21252E;\n$secondary: #FFFFFF;\n\n$grey: #95989F;\n$grey-light: #F4F5F6;\n$grey-hover: #EDEFF0;\n$grey-active: #E1E8EC;\n\n$red: #B43737;\n\n$border: #C2CBD1;\n\n$error: #B43737;\n$link: #3B72C5;\n$disabled: #E5E5E5;\n\n$input-focus: #21252E;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Actions_root__91bSR`,
	"icon": `Actions_icon__qxV7W`
};
export default ___CSS_LOADER_EXPORT___;
