export { invalidateUserTags, userApi, useMeQuery } from 'shared/api/user';
export {
  authReducer,
  authDataReducer,
  setAuthData,
  clearAuthData,
  useUser,
  selectAuthData,
  type User,
  selectIsAuthenticated,
  castAuthData,
} from './model';
