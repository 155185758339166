/* eslint-disable react/jsx-props-no-spreading */
import { FormContainer } from 'entities/auth';
import { ContactInput, Button } from 'shared/ui';
import { useFormErrorMessages, useValidateContact } from 'shared/lib';
import { MultistepPageProps } from 'shared/lib/multistep/types';
import { useContactForm } from '../model';
import styles from './ContactForm.module.scss';

export const ContactForm = ({ pagination }: MultistepPageProps) => {
  const { onSubmit, register, errors } = useContactForm(pagination);
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  return (
    <FormContainer
      title="Вход"
      id="login-by-code"
      onSubmit={onSubmit}
      description="Введите почту или телефон, мы отправим туда код подтверждения"
      className={styles.root}
    >
      <ContactInput
        id="email-phone"
        label="Почта или номер телефона"
        position="center"
        hasError={errors.contact != null}
        help={errors.contact ? errors.contact.message : undefined}
        {...register('contact', { required: errorMessage.required, validate: validateContact })}
      />
      <Button form="login-by-code" type="submit" className={styles.submit}>Получить код</Button>
    </FormContainer>
  );
};
