import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Tooltip } from 'react-tooltip';
import styles from 'shared/ui/tooltip/TooltipComponent.module.scss';

interface TooltipComponentProps {
  id?: string;
  value?: any;
  children?: React.ReactNode;
  className?: string;
}
export const TooltipComponent = ({ value, id, children, className }: TooltipComponentProps) => {
  const textRef = useRef<HTMLDivElement | null>(null);
  const [textHintVisibility, setTextVisibility] = useState(false);
  useEffect(() => {
    const resizeHandler = () => {
      const { current } = textRef;
      if (current === null) {
        return;
      }
      if (current.scrollWidth !== current.clientWidth) {
        setTextVisibility(current.scrollWidth > current.clientWidth);
      }
    };
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  });

  return (
    <div ref={textRef} className={cn(styles.root, className)}>
      {
        textHintVisibility ? (
          <>
            <span
              data-tooltip-id={id}
              data-tooltip-content={value}
              data-tooltip-place="top"
            >
              {children}
              {' '}
              {value}
            </span>
            <Tooltip
              id={id}
              className="tooltip"
            />
          </>
        ) : (
          <>
            {children}
            {' '}
            {value}
          </>
        )
      }
    </div>
  );
};
