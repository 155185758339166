import { Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'react-bootstrap';
import { ProductRow } from 'entities/product/row';
import { Cartproduct } from 'shared/api';
import { generatePath, Link } from 'react-router-dom';
import { Paths } from 'shared/config';
import styles from './OrderProductsDropdown.module.scss';

interface OrderProductsDropdownProps {
  products?: Cartproduct[];
}
export const OrderProductsDropdown = ({ products }: OrderProductsDropdownProps) => (
  <Accordion defaultActiveKey="0" className={styles.root}>
    <AccordionItem eventKey="0" className={styles.item}>
      <AccordionHeader className={styles.toggle}>{`Список товаров (${products?.length})`}</AccordionHeader>
      <AccordionBody className={styles.body}>
        {
            products?.map((product) => (
              <Link
                to={
                  generatePath(Paths.Product, { path: product!.category!.slug, slug: product.slug })
                }
              >
                <ProductRow product={product} className={styles.product} withAmount />
              </Link>
            ))
          }
      </AccordionBody>
    </AccordionItem>
  </Accordion>
);
