import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { normalizePhone, regex } from 'shared/lib';
import { PasswordRecoveryStep3Input } from 'shared/api';
import { Paths } from 'shared/config';
import { setAuthData, castAuthData } from 'entities/user';
import { useResetPasswordStep3Mutation } from 'shared/api/reset-password';
import { ResetPasswordContext } from './ResetPasswordContext';

interface FormData {
  password: string;
  passwordConfirm: string;
}
export function usePasswordForm() {
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<FormData>();
  const { steps: { step1, step2 } } = useContext(ResetPasswordContext);
  const [resetPasswordStep3] = useResetPasswordStep3Mutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input: PasswordRecoveryStep3Input = {
      password: data.password,
      password_confirmation: data.passwordConfirm,
      code: step2!.code,
    };
    if (regex.email.test(step1!.contact)) {
      input.email = step1!.contact;
    } else {
      input.phone = normalizePhone(step1!.contact);
    }
    resetPasswordStep3({ input })
      .unwrap()
      .then((res) => {
        const authData = castAuthData(res.PasswordRecoveryStep3);
        if (authData == null) {
          throw new Error();
        }
        dispatch(setAuthData(authData));
        navigate(Paths.Root);
      });
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    setValue,
    getValues,
  };
}
