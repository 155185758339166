/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useState } from 'react';
import { NumberFormatBase, usePatternFormat } from 'react-number-format';
import { PatternFormatProps, FormatInputValueFunction } from 'react-number-format/types/types';
import { FormLabel, FormGroup, FormText } from 'react-bootstrap';
import cn from 'classnames';
import styles from './Input.module.scss';

interface PhoneInputProps extends Omit<PatternFormatProps, 'format'> {
  id: string;
  label?: string;
  help?: string;
  required?: boolean;
  hasError?: boolean;
  hasStaticHelp?: boolean;
  defaultValue?: string,
  position?: string;
  externalOnBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>((
  {
    label,
    id,
    // eslint-disable-next-line react/prop-types
    className,
    hasError,
    help,
    required,
    defaultValue,
    position,
    externalOnBlur,
    // eslint-disable-next-line react/prop-types
    disabled,
    ...rest
  },
  ref,
) => {
  const initialValue = defaultValue?.replace(/\+7/g, '');
  const { format: patternFormat, ...patterProps } = usePatternFormat({
    format: '+7 (###) ###-##-##',
    mask: '_',
  });
  const format: FormatInputValueFunction = (inputValue) => patternFormat?.(
    inputValue?.length === 0 ? '_' : inputValue.replace(/^(\+?7|8)/, '_'),
  ) ?? '';
  const [value, setValue] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (externalOnBlur) {
      externalOnBlur(event);
    }
  };
  function hasMoreThanOneDigit() {
    const regex = /\d/g;
    const digits = value.match(regex);
    return digits && digits.length > 1;
  }
  return (
    <FormGroup
      className={cn(
        styles.root,
        hasError ? styles.hasError : null,
        className,
      )}
    >
      {label && (
        <FormLabel
          htmlFor={id}
          className={cn(
            position === 'center' ? styles.center : styles.left,
            disabled && styles.labelDisabled,
          )}
        >
          {label}
          {required ? <span className={styles.required}> *</span> : null}
        </FormLabel>
      )}
      <NumberFormatBase
        {...rest}
        {...patterProps}
        getInputRef={ref}
        format={format}
        className={cn('form-control', styles.phoneMask, hasMoreThanOneDigit() && styles.filled, styles.input)}
        defaultValue={initialValue}
        onBlur={handleChange}
        disabled={disabled}
      />
      { help && <FormText className={styles.help}>{help}</FormText> }
    </FormGroup>
  );
});
