// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetPasswordForm_root__IYx6U {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.ResetPasswordForm_submit__NbYoQ {
  width: 100%;
  height: 56px;
}

.ResetPasswordForm_contact__v0i-b .form-text {
  position: unset;
  transform: unset;
}`, "",{"version":3,"sources":["webpack://./src/features/auth/reset-password/ui/ResetPasswordForm.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,WAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;AADF;;AAME;EACE,eAAA;EACA,gBAAA;AAHJ","sourcesContent":["@import \"shared/styles/theme\";\n\n.root {\n  display: flex;\n  flex-direction: column;\n  gap: spacing(6);\n  width: 100%;\n}\n\n.submit {\n  width: 100%;\n  height: 56px;\n}\n\n.contact {\n\n  :global(.form-text) {\n    position: unset;\n    transform: unset;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ResetPasswordForm_root__IYx6U`,
	"submit": `ResetPasswordForm_submit__NbYoQ`,
	"contact": `ResetPasswordForm_contact__v0i-b`
};
export default ___CSS_LOADER_EXPORT___;
