import { ProductAttributeValues } from 'shared/api';
import { AttributesIds } from './AttributesIds';

export const filterColors = (
  attributes: ProductAttributeValues[],
  materialAttribute: string,
) => attributes.reduce<[ProductAttributeValues[], ProductAttributeValues[]]>(
  ([custom, available], item) => {
    if (item.attributeValue && item.attributeValue.vars) {
      const vars = JSON.parse(item.attributeValue.vars);
      const isMaterialRelated = (
        item.attributeValue.parent_attribute_value_id === materialAttribute
      );

      if (isMaterialRelated || item.attribute_id === AttributesIds.LegColor) {
        if (vars.group_name === 'Под заказ') {
          custom.push(item);
        } else {
          available.push(item);
        }
      }
    }
    return [custom, available];
  },
  [[], []],
);
