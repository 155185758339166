import { Popup } from 'shared/ui';
import { CallbackForm } from 'features/callback';
import { Dispatch, SetStateAction } from 'react';

interface ProductOneClickPurchaseModalProps {
  isPopupVisible: boolean;
  setPopupVisible: Dispatch<SetStateAction<boolean>>
}
export const ProductOneClickPurchaseModal = (
  { isPopupVisible, setPopupVisible }: ProductOneClickPurchaseModalProps,
) => (
  <Popup
    isPopupVisible={isPopupVisible}
    setPopupVisible={setPopupVisible}
    redirect={false}
    size="xl"
  >
    <CallbackForm title="Заказать свой дизайн" headerContent />
  </Popup>
);
