/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from 'react';
import { FormControl, FormControlProps, FormLabel, FormGroup, FormText } from 'react-bootstrap';
import cn from 'classnames';
import styles from 'shared/ui/form/input/Input.module.scss';

interface EmailInputProps extends FormControlProps {
  label: string,
  placeholder: string,
  id: string
  hasError?: boolean,
  help?: string | null,
  required?: boolean,
}
export const EmailInput = forwardRef<HTMLInputElement, EmailInputProps>(({
  label,
  placeholder,
  id,
  help,
  hasError,
  required,
  // eslint-disable-next-line react/prop-types
  className,
  // eslint-disable-next-line react/prop-types
  defaultValue,
  ...props
}, ref) => (
  <FormGroup
    className={cn(
      styles.root,
      hasError ? styles.hasError : null,
      className,
    )}
  >
    <FormLabel htmlFor={id} className={styles.label}>
      {label}
      {required ? <span>*</span> : null}
    </FormLabel>
    <FormControl
      type="email"
      className={styles.input}
      placeholder={placeholder}
      id={id}
      ref={ref}
      defaultValue={defaultValue}
      {...props}
    />
    {
      help && (
        <FormText className={styles.help}>{help}</FormText>
      )
    }
  </FormGroup>
));
