export function formatDate(inputDate: string, includeTime: boolean = false): string {
  const [datePart, timePart] = inputDate.split(' ');
  const [year, month, day] = datePart.split('-');

  const formattedDate = `${day}.${month}.${year}`;

  if (includeTime) {
    const [hours, minutes] = timePart.split(':');
    return `${formattedDate} в ${hours}:${minutes}`;
  }
  return formattedDate;
}
