import { Container } from 'react-bootstrap';
import { ProfileMenu } from 'entities/profile/menu';
import { ProfileAddress } from 'features/profile/info';
import styles from './ProfileAddressPage.module.scss';

export const ProfileAddressPage = () => (
  <div className={styles.root}>
    <Container>
      <div className={styles.wrapper}>
        <ProfileMenu />
        <div className={styles.content}>
          <ProfileAddress />
        </div>
      </div>
    </Container>
  </div>
);
