/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgPinterest = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Pinterest_svg__a)">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m8.521 21.664-.817-.28c-.104-.925-.199-2.347.041-3.357.218-.913 1.403-5.812 1.403-5.812s-.358-.7-.358-1.736c0-1.626.964-2.84 2.164-2.84 1.02 0 1.513.749 1.513 1.647 0 .64-.266 1.483-.546 2.37-.159.503-.322 1.02-.444 1.523-.282 1.164.597 2.113 1.771 2.113 2.126 0 3.76-2.191 3.76-5.354 0-2.8-2.058-4.757-4.996-4.757-3.403 0-5.4 2.495-5.4 5.074 0 1.005.396 2.082.89 2.668a.34.34 0 0 1 .083.336l-.21.84-.123.486c-.052.214-.173.26-.4.156-1.493-.68-2.427-2.815-2.427-4.53 0-3.688 2.741-7.075 7.902-7.075 4.15 0 7.373 2.89 7.373 6.753 0 4.03-2.598 7.272-6.206 7.272-1.212 0-2.351-.615-2.741-1.342 0 0-.6 2.232-.745 2.78-.27 1.015-1 2.288-1.487 3.065"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="Pinterest_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgPinterest;
