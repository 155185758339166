import { CloseIcon } from 'shared/ui';
import { useCartDeleteProductMutation } from 'shared/api/cart';
import styles from './Actions.module.scss';

interface RemoveFromCartProps {
  productId: string;
}
export const RemoveFromCart = ({ productId }: RemoveFromCartProps) => {
  const [removeFromCart] = useCartDeleteProductMutation();
  const onClick = () => {
    const input = { product_id: productId };
    removeFromCart({ input });
  };
  return (
    <button
      type="button"
      onClick={onClick}
      className={styles.root}
    >
      <CloseIcon className={styles.icon} />
    </button>
  );
};
