import { Navigate, Outlet } from 'react-router-dom';
import { useUser } from 'entities/user';
import { Paths } from 'shared/config';
import { LoadingHOC } from 'shared/ui';

interface AuthWrapperProps {
  reversed?: boolean;
}

export const AuthWrapper = ({ reversed = false }: AuthWrapperProps) => {
  const { user, isLoading } = useUser();
  return (
    <LoadingHOC isLoading={isLoading}>
      {
        reversed !== (user != null)
          ? <Outlet />
          : <Navigate to={reversed ? Paths.Root : Paths.LoginByCode} />
      }
    </LoadingHOC>
  );
};
