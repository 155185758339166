/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { Attributes, UpdateAttributePayload } from './types';

const attributeConfig = {
  key: 'attribute',
  storage,
  blacklist: [
    'isUpdated',
    'attributes',
  ],
};

const initialState: Attributes = { attributes: {}, isUpdated: false };

const slice = createSlice({
  name: 'attributes',
  initialState,
  reducers: {
    setAttributes(state, action: PayloadAction<Record<string, string>>) {
      state.attributes = action.payload;
    },
    clearAttributes(state) {
      state.attributes = initialState.attributes;
      state.isUpdated = initialState.isUpdated;
    },
    updateAttribute(state, action: PayloadAction<UpdateAttributePayload>) {
      const { attributeId, attributeValueId } = action.payload;
      if (attributeId in state.attributes) {
        state.attributes[attributeId] = attributeValueId;
        state.isUpdated = true;
      }
    },
    clearAttributeValue(state, action: PayloadAction<string>) {
      const attributeId = action.payload;
      if (attributeId in state.attributes) {
        delete state.attributes[attributeId];
        state.isUpdated = true;
      }
    },
  },
});

export const {
  setAttributes,
  updateAttribute,
  clearAttributeValue,
  clearAttributes,
} = slice.actions;
export const selectedAttributes = (state: RootState) => state.attributes;
export const selectedIsUpdated = (state: RootState) => state.attributes.isUpdated;

export const attributesReducer = persistReducer(attributeConfig, slice.reducer);
