import { Link } from 'react-router-dom';
import { formatPrice } from 'shared/lib/format';
import styles from './CatalogItem.module.scss';

interface CatalogItemProps {
  name: string;
  slug: string;
  imagePath?: string;
  price?: number;
}
export const CatalogItem = ({ name, slug, imagePath, price }: CatalogItemProps) => (
  <div className={styles.root}>
    <Link to={slug} className={styles.link}>
      <div className={styles.img}>
        <img src={imagePath} alt={name} />
      </div>
      <h2 className={styles.name}>{name}</h2>
      {
          price && (
            <span className={styles.price}>{`от ${formatPrice(price)}`}</span>
          )
        }
    </Link>
  </div>
);
