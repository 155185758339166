import { invalidateUserTags, TAG_USER } from '../user';
import { api as loginApi } from './loginStep2.csr.generated';

export { useLoginStep1Mutation } from './loginStep1.csr.generated';
export { useLoginStep2Mutation } from './loginStep2.csr.generated';

const api = loginApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: {
    LoginStep2: {
      invalidatesTags: [],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { invalidateTags } = api.util;
        await invalidateUserTags({ dispatch, queryFulfilled, invalidateTags });
      },
    },
  },
});
