import { createContext } from 'react';

export type Steps = {
  step1?: {
    contact: string;
  };
  step2?: {
    code: string;
  };
  step3?: {
    password: string;
    passwordConfirm: string;
  }
};

export type SetStep = {
  setStep: <T extends (1 | 2)>(step: T, value: Steps[`step${T}`]) => void;
};

type ContextValue =
  {
    steps: Steps;
  } & SetStep;

export const ResetPasswordContext = createContext<ContextValue>({ steps: {}, setStep: () => {} });
