/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import { FormControl, FormControlProps } from 'react-bootstrap';
import {
  type FormEvent,
  forwardRef,
  useRef,
  useState,
  type KeyboardEvent, LegacyRef,
} from 'react';
import cn from 'classnames';
import InputStyles from './Input.module.scss';
import styles from './SearchInput.module.scss';

export interface SearchInputProps extends FormControlProps {
  ref?: LegacyRef<HTMLInputElement>;
  delay?: number;
  onSearch: (searchTerm: string) => void;
  withClear?: boolean;
  allowEnterSubmit?: boolean;
  hasError?: boolean;
  help?: string;
  disabled?: boolean;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(({
  id = 'search',
  delay = 300,
  onSearch,
  className,
  defaultValue,
  allowEnterSubmit = false,
  disabled,
  ...rest
}: SearchInputProps, ref) => {
  const timeout = useRef<NodeJS.Timeout>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [searchTerm, setSearchTerm] = useState(String(defaultValue ?? ''));

  const onInput = (e: FormEvent<HTMLInputElement>) => {
    const { value } = (e.target as HTMLInputElement);
    clearTimeout(timeout.current);

    setSearchTerm(value);
    timeout.current = setTimeout(() => {
      onSearch(value);
    }, delay);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!allowEnterSubmit && e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const setRef = (instance: HTMLInputElement | null) => {
    inputRef.current = instance;
    if (instance != null) {
      // eslint-disable-next-line no-param-reassign
      instance.value = searchTerm;
    }
    // Set external ref
    if (typeof ref === 'function') {
      ref(instance);
    } else if (ref != null) {
      // eslint-disable-next-line no-param-reassign
      ref.current = instance;
    }
  };

  return (
    <div className={InputStyles.root}>
      <FormControl
        id={id}
        ref={setRef}
        onInput={onInput}
        onKeyDown={onKeyDown}
        disabled={disabled}
        className={cn(className, styles.root, disabled && styles.disabled)}
        {...rest}
      />
    </div>
  );
});
