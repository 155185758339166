import { Container } from 'react-bootstrap';
import { ProfileMenu } from 'entities/profile/menu';
import {
  EmailChangeForm,
  useChangeEmailContext,
  ChangeEmailContext,
  PasswordConfirmationForm,
  CodeForm,
} from 'features/profile/edit/email';
import { useState } from 'react';
import { Multistep } from 'shared/lib';
import styles from './ProfileChangeEmailPage.module.scss';

export const ProfileChangeEmailPage = () => {
  const stepState = useState(0);
  const changeEmailContextValue = useChangeEmailContext();
  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.wrapper}>
          <ProfileMenu />
          <ChangeEmailContext.Provider value={changeEmailContextValue}>
            <Multistep
              state={stepState}
              steps={[
                EmailChangeForm,
                CodeForm,
                PasswordConfirmationForm,
              ]}
            />
          </ChangeEmailContext.Provider>
        </div>
      </Container>
    </div>
  );
};
