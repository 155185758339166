import { createContext } from 'react';

export type Steps = {
  step1?: {
    phone: string;
  };
  step2?: {
    code: string;
  };
  step3?: {
    password: string;
  };
};

export type SetStep = {
  setStep: <T extends (1 | 2 | 3)>(step: T, value: Steps[`step${T}`]) => void;
};

type ContextValue =
  {
    steps: Steps;
  } & SetStep;

export const ChangePhoneContext = createContext<ContextValue>({ steps: {}, setStep: () => {} });
