import { useState, useEffect, type HTMLAttributes, FormEvent } from 'react';

type UseHasValueOptions = {
  value?: string | number | null;
  defaultValue?: HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>['defaultValue'] | null,
  onInput?: HTMLAttributes<HTMLInputElement | HTMLTextAreaElement>['onInput'],
};

export function useHasValue(options: UseHasValueOptions) {
  const [hasValue, setHasValue] = useState(Boolean(options.value || options.defaultValue));
  // NOTE: this is a hack
  useEffect(() => {
    setHasValue(Boolean(options.value || options.defaultValue));
  }, [setHasValue, options.value, options.defaultValue]);
  const onInput = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setHasValue(e.currentTarget.value !== '');
    if (typeof options.onInput === 'function') {
      options.onInput(e);
    }
  };
  return { hasValue, onInput };
}
