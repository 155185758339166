import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import type { MainMenuItem as IMainMenuItem, ActiveMenuItem } from '../model/types';
import { getRouteActiveItem } from './getRouteActiveItem';

export const useActiveItemByLocation = (items: IMainMenuItem[]) => {
  const location = useLocation();
  const routeActiveItem = useMemo(
    () => getRouteActiveItem(items, location.pathname),
    [items, location.pathname],
  );

  const [activeItem, setActiveItem] = useState<ActiveMenuItem>(routeActiveItem);

  useEffect(() => {
    if (activeItem?.text !== routeActiveItem?.text) {
      setActiveItem(routeActiveItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return { activeItem, setActiveItem };
};
