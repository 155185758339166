/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgVisible = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M10 12a2 2 0 1 0 4 0 2 2 0 0 0-4 0"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21 12q-3.6 6-9 6t-9-6q3.6-6 9-6t9 6"
    />
  </svg>
);
export default SvgVisible;
