/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import { Button, PhoneInput, Popup } from 'shared/ui';
import { useDesignerForm } from 'features/designer/model';
import { useFormErrorMessages, useValidateContact } from 'shared/lib';
import { phone, phoneTo } from 'shared/config';
import styles from './Designer.module.scss';

export const DesignerForm = () => {
  const { register, onSubmit, errors, isPopupVisible, setPopupVisible } = useDesignerForm();
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Дизайнер интерьера?</h2>
      <p className={styles.subtitle}>Приглашаем к сотрудничеству</p>
      <Button className={styles.btn} onClick={() => setPopupVisible(true)}>Оставить заявку</Button>
      <Popup
        isPopupVisible={isPopupVisible}
        setPopupVisible={setPopupVisible}
        size="lg"
      >
        <form onSubmit={onSubmit} className={styles.form} id="designer-form">
          <h2 className={styles.title}>Форма обратной связи</h2>
          <div className={styles.form_subtitle}>
            <span>Позвоните нам по номеру </span>
            <Link to={phoneTo}>{phone}</Link>
          </div>
          <PhoneInput
            className={styles.input}
            id="phone"
            label="Или оставьте свой номер телефона"
            position="center"
            hasError={errors.phone !== undefined}
            {...register('phone', { required: true, validate: validateContact })}
          />
          <Button type="submit" className={styles.submit}>Оставить заявку</Button>
          <small className={styles.error}>
            {errors.phone ? errorMessage[errors.phone.type] : undefined}
          </small>
        </form>
      </Popup>
    </div>
  );
};
