import { useLocation } from 'react-router';
import { Button } from 'shared/ui';
import { Paths } from 'shared/config';
import styles from './CheckoutSuccessPage.module.scss';

export const CheckoutSuccessPage = () => {
  const { state } = useLocation();
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Спасибо за заказ!</h1>
      <h3 className={styles.order}>
        Номер вашего заказа
        <span className={styles.orderNumber}>{` ${state}`}</span>
      </h3>
      <p className={styles.info}>
        Посмотреть статус и информацию о заказе
        <br />
        можно в личном кабинете
      </p>
      <Button href={Paths.ProfileHistory} className={styles.btn}>
        В личный кабинет
      </Button>
    </div>
  );
};
