import { SubmitHandler, useForm } from 'react-hook-form';
import { useState } from 'react';

interface FormData {
  phone: string;
}
export const useDesignerForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>();

  const [isPopupVisible, setPopupVisible] = useState(false);

  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log(data);
  };
  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    isPopupVisible,
    setPopupVisible,
  };
};
