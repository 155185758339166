import { Link } from 'react-router-dom';
import { useRef, useEffect } from 'react';
import type { MainMenuItem as IMainMenuItem, LinePosition, ActiveMenuItem } from '../model/types';

interface MenuItemProps {
  item: IMainMenuItem;
  onPositionChange: (position: LinePosition) => void;
  setLinePosition: (v: LinePosition) => void;
  setActiveItem: (activeItem: ActiveMenuItem) => void;
}

export const MainMenuItem = (
  { item, onPositionChange, setLinePosition, setActiveItem }: MenuItemProps,
) => {
  const ref = useRef<HTMLLIElement>(null);
  // helpers
  const getPosition = () => ({
    left: ref.current?.offsetLeft || 0,
    width: ref.current?.clientWidth || 0,
  });

  const updateLinePosition = () => {
    if (ref.current) {
      setLinePosition(getPosition());
    }
  };

  // on position change
  useEffect(() => {
    const updatePosition = () => {
      onPositionChange(getPosition());
    };
    // TODO: use resize observer
    window.addEventListener('resize', updatePosition);
    setTimeout(updatePosition, 0);
    return () => window.removeEventListener('resize', updatePosition);
  }, [onPositionChange]);

  return (
    <li ref={ref} onMouseEnter={updateLinePosition}>
      <Link to={item.link} onClick={() => setActiveItem({ text: item.text })}>
        {item.icon ? item.icon : item.text}
      </Link>
    </li>
  );
};
