/* eslint-disable */
export type Maybe<T> = T;
export type InputMaybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Attribute = {
  __typename?: 'Attribute';
  id: Scalars['ID']['output'];
  parent_attribute_id?: Maybe<Scalars['ID']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type AttributeValue = {
  __typename?: 'AttributeValue';
  id: Scalars['ID']['output'];
  parent_attribute_value_id?: Maybe<Scalars['ID']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  vars?: Maybe<Scalars['String']['output']>;
};

export type AttributeValues = {
  attribute_value_id?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  expires_in?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  token_type?: Maybe<Scalars['String']['output']>;
};

export type CartFront = {
  __typename?: 'CartFront';
  id?: Maybe<Scalars['ID']['output']>;
  products?: Maybe<Array<Cartproduct>>;
  products_count: Scalars['Int']['output'];
  sum: Scalars['Float']['output'];
  user?: Maybe<User>;
};

export type CartProductChangeInput = {
  attribute_values?: InputMaybe<Array<Scalars['ID']['input']>>;
  product_id: Scalars['ID']['input'];
};

export type CartProductInput = {
  product_id: Scalars['ID']['input'];
};

export type Cartproduct = {
  __typename?: 'Cartproduct';
  amount?: Maybe<Scalars['Float']['output']>;
  attribute_rows?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  category?: Maybe<Category>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  productFiles?: Maybe<Array<ProductFiles>>;
  productValues?: Maybe<Array<ProductAttributeValues>>;
  productVariantValues?: Maybe<Array<ProductAttributeValues>>;
  slug: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type CatalogInput = {
  category_id?: InputMaybe<Scalars['ID']['input']>;
  category_slug?: InputMaybe<Scalars['String']['input']>;
  is_new?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type CatalogResponse = {
  __typename?: 'CatalogResponse';
  current_category?: Maybe<Category>;
  products?: Maybe<Subproducts>;
};

export type Category = {
  __typename?: 'Category';
  active?: Maybe<YesNoEnum>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  image_path: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type CheckoutInput = {
  city: Scalars['String']['input'];
  city_guid?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  delivery_id: Scalars['Int']['input'];
  family_name: Scalars['String']['input'];
  flat?: InputMaybe<Scalars['String']['input']>;
  house?: InputMaybe<Scalars['String']['input']>;
  house_guid?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  pickup_point?: InputMaybe<Scalars['ID']['input']>;
  postindex?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_guid?: InputMaybe<Scalars['String']['input']>;
};

export type CountriesFront = {
  __typename?: 'CountriesFront';
  code: Scalars['ID']['output'];
  name_full: Scalars['String']['output'];
  name_short: Scalars['String']['output'];
};

export type DadataResult = {
  __typename?: 'DadataResult';
  data: DadataResultData;
  unrestricted_value?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type DadataResultData = {
  __typename?: 'DadataResultData';
  area?: Maybe<Scalars['String']['output']>;
  area_fias_id?: Maybe<Scalars['String']['output']>;
  area_kladr_id?: Maybe<Scalars['String']['output']>;
  area_type?: Maybe<Scalars['String']['output']>;
  area_type_full?: Maybe<Scalars['String']['output']>;
  area_with_type?: Maybe<Scalars['String']['output']>;
  beltway_distance?: Maybe<Scalars['String']['output']>;
  beltway_hit?: Maybe<Scalars['String']['output']>;
  block?: Maybe<Scalars['String']['output']>;
  block_type?: Maybe<Scalars['String']['output']>;
  block_type_full?: Maybe<Scalars['String']['output']>;
  capital_marke?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  city_area?: Maybe<Scalars['String']['output']>;
  city_district?: Maybe<Scalars['String']['output']>;
  city_district_fias_id?: Maybe<Scalars['String']['output']>;
  city_district_kladr_id?: Maybe<Scalars['String']['output']>;
  city_district_type?: Maybe<Scalars['String']['output']>;
  city_district_type_full?: Maybe<Scalars['String']['output']>;
  city_district_with_type?: Maybe<Scalars['String']['output']>;
  city_fias_id?: Maybe<Scalars['String']['output']>;
  city_kladr_id?: Maybe<Scalars['String']['output']>;
  city_type?: Maybe<Scalars['String']['output']>;
  city_type_full?: Maybe<Scalars['String']['output']>;
  city_with_type?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  country_iso_code?: Maybe<Scalars['String']['output']>;
  divisions?: Maybe<Scalars['String']['output']>;
  entrance?: Maybe<Scalars['String']['output']>;
  federal_district?: Maybe<Scalars['String']['output']>;
  fias_actuality_state?: Maybe<Scalars['String']['output']>;
  fias_code?: Maybe<Scalars['String']['output']>;
  fias_id?: Maybe<Scalars['String']['output']>;
  fias_level?: Maybe<Scalars['String']['output']>;
  flat?: Maybe<Scalars['String']['output']>;
  flat_area?: Maybe<Scalars['String']['output']>;
  flat_cadnum?: Maybe<Scalars['String']['output']>;
  flat_fias_id?: Maybe<Scalars['String']['output']>;
  flat_price?: Maybe<Scalars['String']['output']>;
  flat_type?: Maybe<Scalars['String']['output']>;
  flat_type_full?: Maybe<Scalars['String']['output']>;
  floor?: Maybe<Scalars['String']['output']>;
  geo_lat?: Maybe<Scalars['String']['output']>;
  geo_lon?: Maybe<Scalars['String']['output']>;
  geoname_id?: Maybe<Scalars['String']['output']>;
  house?: Maybe<Scalars['String']['output']>;
  house_cadnum?: Maybe<Scalars['String']['output']>;
  house_fias_id?: Maybe<Scalars['String']['output']>;
  house_kladr_id?: Maybe<Scalars['String']['output']>;
  house_type?: Maybe<Scalars['String']['output']>;
  house_type_full?: Maybe<Scalars['String']['output']>;
  kladr_id?: Maybe<Scalars['String']['output']>;
  metro?: Maybe<Scalars['String']['output']>;
  okato?: Maybe<Scalars['String']['output']>;
  oktmo?: Maybe<Scalars['String']['output']>;
  postal_box?: Maybe<Scalars['String']['output']>;
  postal_code?: Maybe<Scalars['String']['output']>;
  qc?: Maybe<Scalars['String']['output']>;
  qc_complete?: Maybe<Scalars['String']['output']>;
  qc_geo?: Maybe<Scalars['String']['output']>;
  qc_house?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  region_fias_id?: Maybe<Scalars['String']['output']>;
  region_iso_code?: Maybe<Scalars['String']['output']>;
  region_kladr_id?: Maybe<Scalars['String']['output']>;
  region_type?: Maybe<Scalars['String']['output']>;
  region_type_full?: Maybe<Scalars['String']['output']>;
  region_with_type?: Maybe<Scalars['String']['output']>;
  settlement?: Maybe<Scalars['String']['output']>;
  settlement_fias_id?: Maybe<Scalars['String']['output']>;
  settlement_kladr_id?: Maybe<Scalars['String']['output']>;
  settlement_type?: Maybe<Scalars['String']['output']>;
  settlement_type_full?: Maybe<Scalars['String']['output']>;
  settlement_with_type?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  square_meter_price?: Maybe<Scalars['String']['output']>;
  stead?: Maybe<Scalars['String']['output']>;
  stead_cadnum?: Maybe<Scalars['String']['output']>;
  stead_fias_id?: Maybe<Scalars['String']['output']>;
  stead_type?: Maybe<Scalars['String']['output']>;
  stead_type_full?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_fias_id?: Maybe<Scalars['String']['output']>;
  street_kladr_id?: Maybe<Scalars['String']['output']>;
  street_type?: Maybe<Scalars['String']['output']>;
  street_type_full?: Maybe<Scalars['String']['output']>;
  street_with_type?: Maybe<Scalars['String']['output']>;
  tax_office?: Maybe<Scalars['String']['output']>;
  tax_office_legal?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  unparsed_parts?: Maybe<Scalars['String']['output']>;
};

export type DeliveryCalcFront = {
  __typename?: 'DeliveryCalcFront';
  pickup_point?: Maybe<Array<Maybe<DeliveryPickupPointFront>>>;
  price: Scalars['Float']['output'];
};

export type DeliveryGroupFront = {
  __typename?: 'DeliveryGroupFront';
  calculate_price: Scalars['Boolean']['output'];
  has_pickup: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  parameters?: Maybe<Scalars['JSON']['output']>;
  title: Scalars['String']['output'];
};

export type DeliveryPickupPointFront = {
  __typename?: 'DeliveryPickupPointFront';
  address: Scalars['String']['output'];
  coordinates?: Maybe<PickupPointCoordinates>;
  id: Scalars['ID']['output'];
  schedule?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type DeliveryProvider = {
  __typename?: 'DeliveryProvider';
  calculate_price: Scalars['Boolean']['output'];
  delivery_class: Scalars['String']['output'];
  has_pickup: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  parameters?: Maybe<Scalars['JSON']['output']>;
  title: Scalars['String']['output'];
};

export type ImageData = {
  __typename?: 'ImageData';
  id?: Maybe<Scalars['ID']['output']>;
  url: Scalars['String']['output'];
};

export type IsUserExistsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type LoginByCodeStep1Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  is_sms?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type LoginByCodeStep2Input = {
  code: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  merge_carts?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type LoginUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  merge_carts?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type Menu = {
  __typename?: 'Menu';
  id: Scalars['ID']['output'];
  items?: Maybe<Array<MenuItemListItem>>;
  name: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type MenuFront = {
  __typename?: 'MenuFront';
  id: Scalars['ID']['output'];
  items?: Maybe<Array<MenuItemFront>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MenuInput = {
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  parent_id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type MenuItemFront = {
  __typename?: 'MenuItemFront';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  parent_id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type MenuItemListItem = {
  __typename?: 'MenuItemListItem';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  parent_id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
  visible: Scalars['Boolean']['output'];
};

export type MenuItemResponse = {
  __typename?: 'MenuItemResponse';
  item: MenuItem;
  parents?: Maybe<Array<MenuItemSelectItem>>;
};

export type MenuItemSelectItem = {
  __typename?: 'MenuItemSelectItem';
  id: Scalars['ID']['output'];
  text: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  CartAddProduct?: Maybe<CartFront>;
  CartChangeAttribute?: Maybe<CartFront>;
  CartDecreaseAmount?: Maybe<CartFront>;
  CartDeleteProduct?: Maybe<CartFront>;
  CartIncreaseAmount?: Maybe<CartFront>;
  CheckoutCreateOrder: OrderFront;
  Generate: Scalars['String']['output'];
  Login: AuthResponse;
  LoginByCodeStep1: Scalars['String']['output'];
  LoginByCodeStep2: AuthResponse;
  Logout: Scalars['Boolean']['output'];
  PasswordRecoveryStep1: Scalars['String']['output'];
  PasswordRecoveryStep2: Scalars['String']['output'];
  PasswordRecoveryStep3: AuthResponse;
  PersonalUpdateProfile: User;
};


export type MutationCartAddProductArgs = {
  input: CartProductInput;
};


export type MutationCartChangeAttributeArgs = {
  input: CartProductChangeInput;
};


export type MutationCartDecreaseAmountArgs = {
  input: CartProductInput;
};


export type MutationCartDeleteProductArgs = {
  input: CartProductInput;
};


export type MutationCartIncreaseAmountArgs = {
  input: CartProductInput;
};


export type MutationCheckoutCreateOrderArgs = {
  input: CheckoutInput;
};


export type MutationGenerateArgs = {
  input?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLoginArgs = {
  input: LoginUserInput;
};


export type MutationLoginByCodeStep1Args = {
  input: LoginByCodeStep1Input;
};


export type MutationLoginByCodeStep2Args = {
  input: LoginByCodeStep2Input;
};


export type MutationPasswordRecoveryStep1Args = {
  input: PasswordRecoveryStep1Input;
};


export type MutationPasswordRecoveryStep2Args = {
  input: PasswordRecoveryStep2Input;
};


export type MutationPasswordRecoveryStep3Args = {
  input: PasswordRecoveryStep3Input;
};


export type MutationPersonalUpdateProfileArgs = {
  input: PersonalUpdateProfileInput;
};

export type OrderByClause = {
  column: Scalars['String']['input'];
  order: SortOrder;
};

export enum OrderByRelationAggregateFunction {
  Count = 'COUNT'
}

export enum OrderByRelationWithColumnAggregateFunction {
  Avg = 'AVG',
  Count = 'COUNT',
  Max = 'MAX',
  Min = 'MIN',
  Sum = 'SUM'
}

export type OrderFront = {
  __typename?: 'OrderFront';
  address: Scalars['String']['output'];
  auth?: Maybe<AuthResponse>;
  comission: Scalars['Float']['output'];
  created_at: Scalars['DateTime']['output'];
  fio: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  is_available_payment?: Maybe<Scalars['Boolean']['output']>;
  is_available_track?: Maybe<Scalars['Boolean']['output']>;
  products?: Maybe<Array<Cartproduct>>;
  status: OrderStatus;
  total: Scalars['Float']['output'];
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type OrdersListItemFront = {
  __typename?: 'OrdersListItemFront';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  products?: Maybe<Array<Cartproduct>>;
  products_count: Scalars['Int']['output'];
  status: OrderStatus;
  total: Scalars['Float']['output'];
};

export type OrdersListResponse = {
  __typename?: 'OrdersListResponse';
  data?: Maybe<Array<OrdersListItemFront>>;
  paginatorInfo?: Maybe<PaginatorData>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  count: Scalars['Int']['output'];
  currentPage: Scalars['Int']['output'];
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  lastPage: Scalars['Int']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type PaginatorData = {
  __typename?: 'PaginatorData';
  count?: Maybe<Scalars['Int']['output']>;
  currentPage?: Maybe<Scalars['Int']['output']>;
  firstItem?: Maybe<Scalars['Int']['output']>;
  hasMorePages?: Maybe<Scalars['Boolean']['output']>;
  lastItem?: Maybe<Scalars['Int']['output']>;
  lastPage?: Maybe<Scalars['Int']['output']>;
  perPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  count: Scalars['Int']['output'];
  currentPage: Scalars['Int']['output'];
  firstItem?: Maybe<Scalars['Int']['output']>;
  hasMorePages: Scalars['Boolean']['output'];
  lastItem?: Maybe<Scalars['Int']['output']>;
  lastPage: Scalars['Int']['output'];
  perPage: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PasswordRecoveryStep1Input = {
  email?: InputMaybe<Scalars['String']['input']>;
  is_sms?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type PasswordRecoveryStep2Input = {
  code: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type PasswordRecoveryStep3Input = {
  code: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  merge_carts?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  password_confirmation: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type PersonalUpdateProfileInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  city_guid?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  flat?: InputMaybe<Scalars['String']['input']>;
  house?: InputMaybe<Scalars['String']['input']>;
  house_guid?: InputMaybe<Scalars['String']['input']>;
  middlename?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postindex?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  street_guid?: InputMaybe<Scalars['String']['input']>;
  surname?: InputMaybe<Scalars['String']['input']>;
};

export type PickupPointCoordinates = {
  __typename?: 'PickupPointCoordinates';
  lat?: Maybe<Scalars['Float']['output']>;
  lon?: Maybe<Scalars['Float']['output']>;
};

export type ProductAttributeValues = {
  __typename?: 'ProductAttributeValues';
  attribute?: Maybe<Attribute>;
  attributeValue?: Maybe<AttributeValue>;
  attribute_id: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  value_double?: Maybe<Scalars['Float']['output']>;
  value_string?: Maybe<Scalars['String']['output']>;
};

export type ProductChangeAttributesInput = {
  attribute_values: AttributeValues;
};

export type ProductFileType = {
  __typename?: 'ProductFileType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ProductFiles = {
  __typename?: 'ProductFiles';
  fileType?: Maybe<ProductFileType>;
  file_path: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type ProductFront = {
  __typename?: 'ProductFront';
  category?: Maybe<Category>;
  category_id?: Maybe<Scalars['ID']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  productFiles?: Maybe<Array<ProductFiles>>;
  productValues?: Maybe<Array<ProductAttributeValues>>;
  productVariantValues?: Maybe<Array<ProductAttributeValues>>;
  slug: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ProductInput = {
  attribute_values?: InputMaybe<AttributeValues>;
  slug?: InputMaybe<Scalars['String']['input']>;
};

export type Query = {
  __typename?: 'Query';
  Cart?: Maybe<CartFront>;
  Catalog: CatalogResponse;
  Category?: Maybe<Array<Category>>;
  CheckoutCountries?: Maybe<Array<CountriesFront>>;
  CheckoutDelivery?: Maybe<Array<DeliveryGroupFront>>;
  DeliveryCalc: DeliveryCalcFront;
  DeliveryProvider?: Maybe<Array<DeliveryProvider>>;
  IsUserExists: Scalars['Boolean']['output'];
  Menu?: Maybe<Array<MenuFront>>;
  Order: OrderFront;
  OrdersList?: Maybe<OrdersListResponse>;
  Product: ProductFront;
  ProductByAttributeValues: ProductFront;
  Profile: User;
  SuggestAddress?: Maybe<Array<Maybe<DadataResult>>>;
  me?: Maybe<User>;
};


export type QueryCatalogArgs = {
  input?: InputMaybe<CatalogInput>;
};


export type QueryCategoryArgs = {
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryDeliveryCalcArgs = {
  input: DeliveryCalcInput;
};


export type QueryDeliveryProviderArgs = {
  id?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryIsUserExistsArgs = {
  input?: InputMaybe<IsUserExistsInput>;
};


export type QueryMenuArgs = {
  input: MenuInput;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdersListArgs = {
  first?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductArgs = {
  input: ProductInput;
};


export type QueryProductByAttributeValuesArgs = {
  input: ProductChangeAttributesInput;
};


export type QuerySuggestAddressArgs = {
  input: SuggestAddressInput;
};

export type RegisterUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  password_confirmation: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  count: Scalars['Int']['output'];
  currentPage: Scalars['Int']['output'];
  firstItem?: Maybe<Scalars['Int']['output']>;
  hasMorePages: Scalars['Boolean']['output'];
  lastItem?: Maybe<Scalars['Int']['output']>;
  perPage: Scalars['Int']['output'];
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Subproduct = {
  __typename?: 'Subproduct';
  created_at?: Maybe<Scalars['DateTime']['output']>;
  first_image_url?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  slug: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Subproducts = {
  __typename?: 'Subproducts';
  data?: Maybe<Array<Subproduct>>;
  paginatorInfo?: Maybe<PaginatorData>;
};

export enum Trashed {
  Only = 'ONLY',
  With = 'WITH',
  Without = 'WITHOUT'
}

export type User = {
  __typename?: 'User';
  cdek_pvz_id?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  city_guid?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  flat?: Maybe<Scalars['String']['output']>;
  house?: Maybe<Scalars['String']['output']>;
  house_guid?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  middlename?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postindex?: Maybe<Scalars['String']['output']>;
  shipping_method_id?: Maybe<Scalars['Int']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  street_guid?: Maybe<Scalars['String']['output']>;
  surname?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export enum YesNoEnum {
  No = 'NO',
  Yes = 'YES'
}

export type DeliveryCalcInput = {
  city_fias?: InputMaybe<Scalars['String']['input']>;
  country_code?: InputMaybe<Scalars['String']['input']>;
  delivery_id: Scalars['ID']['input'];
  postal_code?: InputMaybe<Scalars['String']['input']>;
};

export type SuggestAddressInput = {
  guid?: InputMaybe<Scalars['String']['input']>;
  needle: Scalars['String']['input'];
  type: Scalars['String']['input'];
};
