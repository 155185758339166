import { useContext } from 'react';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { useCartAddProductMutation } from 'shared/api/cart';
import { NotificationContext, updateList } from 'entities/notification';
import { CartAddProductMutation } from 'shared/api/cart/addToCart.csr.generated';

export const useAddToFavorite = (productId?: string) => {
  const [addProduct, result] = useCartAddProductMutation();
  const { setData } = useContext(NotificationContext);
  const addToFavorite = () => {
    if (productId) {
      const input = { product_id: productId ?? '' };
      updateList(
        addProduct({ input })
          .unwrap(),
        { setData, convert: (response: CartAddProductMutation) => response.CartAddProduct, actionType: 'favorite' },
      );
    }
  };
  return {
    addToFavorite,
    inProcess: result.status === QueryStatus.pending,
  };
};
