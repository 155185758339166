/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef } from 'react';
import { FormControl, FormControlProps, FormLabel, FormGroup, FormText } from 'react-bootstrap';
import cn from 'classnames';
import styles from './Input.module.scss';

interface TextInputProps extends FormControlProps {
  label?: string,
  id: string,
  hasError?: boolean,
  help?: string,
  required?: boolean,
  placeholder?: string;
}
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({
  label,
  id,
  // eslint-disable-next-line react/prop-types
  className,
  hasError,
  help,
  required,
  // eslint-disable-next-line react/prop-types
  defaultValue,
  placeholder,
  // eslint-disable-next-line react/prop-types
  disabled,
  ...props
}, ref) => (
  <FormGroup className={cn(
    styles.root,
    hasError ? styles.hasError : null,
    className,
  )}
  >
    {label && (
      <FormLabel htmlFor={id} className={cn(styles.label, disabled && styles.labelDisabled)}>
        {label}
        {required ? <span className={styles.required}> *</span> : null}
      </FormLabel>
    )}
    <FormControl
      type="text"
      className={styles.input}
      id={id}
      ref={ref}
      defaultValue={defaultValue}
      placeholder={placeholder}
      disabled={disabled}
      {...props}
    />
    <FormText className={styles.help}>{help}</FormText>
  </FormGroup>
));
