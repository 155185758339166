import { ModalHeader } from 'react-bootstrap';
import cn from 'classnames';
import { ArrowBackIcon, CloseIcon } from 'shared/ui';
import styles from './AttributeModal.module.scss';

interface AttributePopupHeaderProps {
  stepBack: () => void;
  modalHeader?: string;
  setPopupVisible: (visible: boolean) => void;
}

export const AttributeModalHeader = (
  { stepBack, modalHeader, setPopupVisible }: AttributePopupHeaderProps,
) => (
  <ModalHeader className={styles.header}>
    <div className={cn(styles.back, styles.headerIcon)}>
      <ArrowBackIcon onClick={stepBack} className={styles.backIcon} />
    </div>
    <span className={styles.titleValue}>{modalHeader}</span>
    <div className={cn(styles.close, styles.headerIcon)}>
      <CloseIcon
        onClick={() => setPopupVisible(false)}
        className={styles.closeIcon}
      />
    </div>
  </ModalHeader>
);
