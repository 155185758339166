import { DecIcon, IncIcon } from 'shared/ui/icons';
import styles from './IncDecInput.module.scss';

interface IncDecInputProps {
  value?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  onInc: () => void;
  onDec: () => void;
  isIncLoading: boolean;
  isDecLoading: boolean;
}

export const IncDecInput = ({
  min = 1,
  max = 999,
  value,
  disabled,
  onInc,
  onDec,
  isIncLoading,
  isDecLoading,
}: IncDecInputProps) => (
  <div className={styles.root}>
    <button
      type="button"
      onClick={onDec}
      disabled={disabled || value! <= min || isDecLoading}
      className={styles.button}
    >
      <DecIcon className={styles.icon} />
    </button>
    <input className={styles.value} value={`${value} шт`} disabled />
    <button
      type="button"
      onClick={onInc}
      disabled={disabled || value! >= max || isIncLoading}
      className={styles.button}
    >
      <IncIcon className={styles.icon} />
    </button>
  </div>
);
