import { SubmitHandler, useForm } from 'react-hook-form';

interface FormData {
  password: string;
  confirmPassword: string;
}

export const usePasswordCreateForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>();

  const onSubmit:SubmitHandler<FormData> = (data) => {
    // TODO: Delete log after integration with backend
    console.log(data);
  };

  return {
    register,
    errors,
    onSubmit: handleSubmit(onSubmit),
  };
};
