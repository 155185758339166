import { Link } from 'react-router-dom';
import { formatPrice, formatDate } from 'shared/lib/format';
import ArrowRight from 'shared/ui/icons/ArrowRight';
import { HistoryType, ProductsFiles } from '../types';
import styles from './OrderHistoryItem.module.scss';
import { OrderStatus } from './OrderStatus';
import { orderStatusMap } from '../config';

export const OrderHistoryItem = (
  { id, products, status, productsCount, total, createdAt }: HistoryType,
) => (
  <Link to={id} className={styles.link}>
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.info}>
          <span className={styles.number}>{`№ ${id}`}</span>
          <div className={styles.images}>
            {
                products?.map((product: ProductsFiles) => (
                  <img
                    src={product.productFiles?.[0].filePath}
                    key={product.productFiles?.[0].filePath}
                    className={styles.image}
                    alt=""
                  />
                ))
              }
          </div>
        </div>
        <OrderStatus status={orderStatusMap[status.id]} className={styles.status} />
      </div>
      <div className={styles.price}>
        <div className={styles.wrapper}>
          <span className={styles.text}>Позиций</span>
          <span className={styles.text}>{productsCount}</span>
        </div>
        <div className={styles.wrapper}>
          <span className={styles.text}>Сумма</span>
          <span className={styles.text}>{formatPrice(total)}</span>
        </div>
      </div>
      <div className={styles.date}>
        <span>
          {`от ${formatDate(createdAt)}`}
        </span>
        <ArrowRight className={styles.icon} />
      </div>
    </div>
  </Link>
);
