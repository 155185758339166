import { ModalBody } from 'react-bootstrap';
import cn from 'classnames';
import { ProductAttributeValues } from 'shared/api';
import { ArrowRightIcon } from 'shared/ui';
import styles from './AttributeModal.module.scss';

interface AttributePopupBodyColorsProps {
  customColors: ProductAttributeValues[];
  availableColors: ProductAttributeValues[];
  stepNext: (id: string) => void;
}

export const AttributeModalBodyColors = ({
  customColors,
  availableColors,
  stepNext,
}: AttributePopupBodyColorsProps) => (
  <ModalBody className={cn(styles.rootBody, styles.colors)}>
    {
      availableColors?.map((attribute) => (
        <div key={attribute.id} className={styles.color}>
          <button
            type="button"
            onClick={() => stepNext(attribute.id)}
            className={cn(styles.btn, styles.colorBtn)}
          >
            <div className={styles.colorValue}>
              <img src={attribute?.attributeValue?.vars && JSON.parse(attribute!.attributeValue!.vars).thumblains_image_paths[0]} alt="" />
              <span title={attribute?.attributeValue?.value}>
                {attribute?.attributeValue?.value}
              </span>
            </div>
            <ArrowRightIcon />
          </button>
        </div>
      ))
    }
    {
      customColors.length > 0 && (
        <div className={styles.wrapper}>
          <div className={styles.custom}>
            <span className={styles.wrapperTitle}>Под заказ</span>
            <div className={styles.line} />
          </div>
          <span className={styles.wrapperSubtitle}>Срок изготовления будет увеличен</span>
        </div>
      )
    }
    {
      customColors?.map((attribute) => (
        <div key={attribute.id} className={styles.color}>
          <button
            type="button"
            onClick={() => stepNext(attribute.id)}
            className={cn(styles.btn, styles.colorBtn)}
          >
            <div className={styles.colorValue}>
              <img src={attribute?.attributeValue?.vars && JSON.parse(attribute!.attributeValue!.vars).thumblains_image_paths[0]} alt="" />
              <span title={attribute?.attributeValue?.value}>
                {attribute?.attributeValue?.value}
              </span>
            </div>
            <ArrowRightIcon />
          </button>
        </div>
      ))
    }
  </ModalBody>
);
