// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddressChangeForm_root__NmhhI {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.AddressChangeForm_wrapper__QH8vE {
  display: flex;
  gap: 1.5rem;
}

.AddressChangeForm_submit__QCptp {
  height: 56px;
  margin-top: 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/edit/address/ui/AddressChangeForm.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;AADF;;AAIA;EACE,aAAA;EACA,WAAA;AADF;;AAIA;EACE,YAAA;EACA,kBAAA;AADF","sourcesContent":["@import 'shared/styles/theme';\n\n.root {\n  display: flex;\n  flex-direction: column;\n  gap: spacing(5);\n}\n\n.wrapper {\n  display: flex;\n  gap: spacing(6);\n}\n\n.submit {\n  height: 56px;\n  margin-top: spacing(2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AddressChangeForm_root__NmhhI`,
	"wrapper": `AddressChangeForm_wrapper__QH8vE`,
	"submit": `AddressChangeForm_submit__QCptp`
};
export default ___CSS_LOADER_EXPORT___;
