/* eslint-disable react/jsx-props-no-spreading */
import { FormContainer } from 'entities/auth';
import { Button, PasswordInput } from 'shared/ui';
import { useFormErrorMessages, regex } from 'shared/lib';
import { usePasswordForm } from '../model';
import styles from './PasswordForm.module.scss';

export const PasswordForm = () => {
  const { register, errors, onSubmit, getValues } = usePasswordForm();
  const errorMessage = useFormErrorMessages();
  return (
    <FormContainer
      id="set-password"
      title="Изменение пароля"
      description="Пароль должен состоять из 8+ символов, содержать буквы разного регистра и хотя бы одну цифру"
      onSubmit={onSubmit}
      className={styles.root}
    >
      <PasswordInput
        id="password"
        label="Новый пароль"
        position="center"
        hasError={errors.password != null}
        help={errors.password ? errorMessage[errors.password.type] : undefined}
        {...register('password', { required: true, pattern: regex.password })}
      />
      <PasswordInput
        id="passwordConfirm"
        label="Новый пароль еще раз"
        position="center"
        hasError={errors.passwordConfirm != null}
        help={errors.passwordConfirm ? errorMessage[errors.passwordConfirm.type] : undefined}
        {...register('passwordConfirm', { required: true, validate: (value) => value === getValues('password') })}
      />
      <Button form="set-password" type="submit" className={styles.submit}>Сохранить</Button>
    </FormContainer>
  );
};
