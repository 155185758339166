import { CloseIcon } from 'shared/ui';
import styles from './Actions.module.scss';

export const RemoveFromFavourites = () => {
  const onClick = () => {
    console.log('remove');
  };
  return (
    <button type="button" onClick={onClick} className={styles.root} aria-label="remove"><CloseIcon className={styles.icon} /></button>
  );
};
