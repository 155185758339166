import { Container } from 'react-bootstrap';
import { ProfileMenu } from 'entities/profile/menu';
import { AddressChangeForm } from 'features/profile/edit/address';
import styles from './ProfileChangeAddressPage.module.scss';

export const ProfileChangeAddressPage = () => (
  <div className={styles.root}>
    <Container>
      <div className={styles.wrapper}>
        <ProfileMenu />
        <AddressChangeForm />
      </div>
    </Container>
  </div>
);
