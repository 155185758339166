import { Button } from 'shared/ui';
import { Dispatch, SetStateAction } from 'react';

interface ProductOneClickPurchaseButtonProps {
  productOneClickPurchase: Dispatch<SetStateAction<boolean>>
  text: string;
}
export const ProductOneClickPurchaseButton = (
  { productOneClickPurchase, text }: ProductOneClickPurchaseButtonProps,
) => (
  <Button variant="secondary" onClick={() => productOneClickPurchase(true)}>{text}</Button>
);
