export const data = [
  {
    id: '1',
    file_path: '/banner/banner-1.jpg',
    fileType: {
      id: '2',
      name: 'gallery',
    },
    info: {
      id: '3',
      title: 'Флакон',
      subtitle: 'Новая серия столов',
    },
  },
  {
    id: '2',
    file_path: '/banner/banner-2.jpg',
    fileType: {
      id: '2',
      name: 'gallery',
    },
    info: {
      id: '56',
      title: 'Стул',
      subtitle: 'Новая серия стульев',
    },
  },
  {
    id: '3',
    file_path: '/banner/banner-3.jpg',
    fileType: {
      id: '2',
      name: 'gallery',
    },
    info: {
      id: '14',
      title: 'Стол',
      subtitle: 'Ниче такой стол, зачетный',
    },
  },
  {
    id: '4',
    file_path: '/banner/banner-4.jpg',
    fileType: {
      id: '2',
      name: 'gallery',
    },
    info: {
      id: '5',
      title: 'Раковина',
      subtitle: 'Крутышкинская раковина',
    },
  },
];
