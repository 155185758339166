import { Button } from 'shared/ui';
import { Paths } from 'shared/config';
import { useUser } from 'entities/user';
import styles from './ProfilePassword.module.scss';

export const ProfilePassword = () => {
  const { userHasPassword } = useUser();
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Пароль</h1>
      <span className={styles.subtitle}>
        {
        userHasPassword
          ? 'Изменен 20 дней назад'
          : 'Вы можете установить пароль и выполнять вход по нему'
        }
      </span>
      <Button
        className={styles.btn}
        href={
        userHasPassword
          ? Paths.ProfileChangePassword
          : Paths.ProfileCreatePassword
        }
      >
        {
          userHasPassword
            ? 'Изменить пароль'
            : 'Установить пароль'
        }
      </Button>
    </div>
  );
};
