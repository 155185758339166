import { Container } from 'react-bootstrap';
import { DesignerForm } from 'features/designer';
import styles from './HomePage.module.scss';

export const HomePage = () => (
  <Container>
    <section className={styles.section}>
      <h2 className={styles.title}>Создайте мебель мечты</h2>
      <p className={styles.subtitle}>
        Нажмите, чтобы открыть
        <br />
        конструктор индивидуального дизайна
      </p>
    </section>
    <DesignerForm />
    <section className={styles.section}>
      <h2 className={styles.title}>Новые модели</h2>
    </section>
    <section className={styles.description}>
      <p>
        «Тим столы и комоды» – новый проект «Тим мебель», создан
        по многочисленным просьбам дизайнеров интерьеров
      </p>
    </section>
  </Container>
);
