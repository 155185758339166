import { CartIcon } from 'shared/ui';
import cn from 'classnames';
import { useMemo } from 'react';
import { useGetCartCountQuery } from 'shared/api/cart';
import styles from './CartLink.module.scss';

interface CartLinkProps {
  className?: string;
}

export const CartLink = ({ className }: CartLinkProps) => {
  const query = useGetCartCountQuery();
  const cart = useMemo(() => query.currentData?.Cart, [query.currentData]);
  return (
    <div className={styles.root}>
      {cart?.count! > 0 && <div className={styles.count}>{cart?.count}</div>}
      <CartIcon className={cn(styles.icon, className)} />
    </div>
  );
};
