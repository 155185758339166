import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { Link } from 'react-router-dom';
import { phone, phoneTo, socials } from 'shared/config';
import cn from 'classnames';
import { InstagramIcon, PinterestIcon, VkIcon } from 'shared/ui';
import { CallbackForm } from 'features/callback';
import styles from './ContactsPage.module.scss';

export const ContactsPage = () => (
  <div className={styles.root}>
    <h1 className={styles.title}>Контакты</h1>
    <div className={styles.wrapper}>
      <div className={styles.map}>
        <YMaps>
          <Map
            defaultState={{ center: [56.052482, 92.890669], zoom: 16 }}
            width="350px"
            height="231px"
          >
            <Placemark geometry={[56.052482, 92.890669]} />
          </Map>
        </YMaps>
      </div>
      <div className={styles.info}>
        <div className={styles.item}>
          <span className={styles.property}>Телефон</span>
          <Link to={phoneTo} className={styles.value}>{phone}</Link>
        </div>
        <div className={styles.item}>
          <span className={styles.property}>Адрес</span>
          <div className={styles.address}>
            <span className={styles.value}>Красноярск, Ломако 16</span>
            <span className={styles.time}>Время работы: 10:00 — 20:00</span>
          </div>
        </div>
        <div className={styles.item}>
          <span className={styles.property}>Соц. сети</span>
          <div className={styles.socials}>
            <Link
              to={socials.Pinterest}
              target="_blank"
            >
              <PinterestIcon className={cn(styles.icon, styles.pinterest)} />
            </Link>
            <Link
              to={socials.Instagram}
              target="_blank"
            >
              <InstagramIcon className={cn(styles.icon, styles.instagram)} />
            </Link>
            <Link
              to={socials.Vk}
              target="_blank"
            >
              <VkIcon className={cn(styles.icon, styles.vk)} />
            </Link>
          </div>
        </div>
      </div>
    </div>
    <CallbackForm title="Написать нам" />
  </div>
);
