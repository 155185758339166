import { orderStatusLocale } from '../config';
import { OrderStatusValues } from '../types';

const orderStatusColor = {
  [OrderStatusValues.OrderApproval]: '#4094E2',
  [OrderStatusValues.AwaitingPayment]: '#EB7853',
  [OrderStatusValues.Paid]: '#00A27B',
  [OrderStatusValues.InAssembly]: '#E19605',
  [OrderStatusValues.IsCreated]: '#8756C7',
  [OrderStatusValues.InProgress]: '#3B72C5',
  [OrderStatusValues.Delivered]: '#3DA64E',
  [OrderStatusValues.Cancelled]: '#B43737',
};

interface OrderStatusProps {
  status: OrderStatusValues;
  className: string;
}
export const OrderStatus = ({ status, className }: OrderStatusProps) => {
  const text = orderStatusLocale[status];
  const color = orderStatusColor[status];
  return <span style={{ color }} className={className}>{text}</span>;
};
