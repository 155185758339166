import { type FC, createElement, useMemo, Dispatch, SetStateAction, useState } from 'react';
import { MultistepPageProps } from './types';

interface MultistepProps {
  state?: [number, Dispatch<SetStateAction<number>>];
  steps: FC<MultistepPageProps>[];
}

export function Multistep({ state, steps }: MultistepProps) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [stepIndex, setStepIndex] = state ?? useState(0);
  const stepPage = useMemo(() => createElement(
    steps[stepIndex],
    {
      pagination: {
        page: stepIndex,
        next: () => {
          const nextStep = stepIndex + 1;
          if (nextStep < steps.length) {
            setStepIndex(nextStep);
          }
        },
        goTo: (page) => setStepIndex(page),
        back: () => {
          const nextStep = stepIndex - 1;
          if (nextStep >= 0) {
            setStepIndex(nextStep);
          }
        },
      },
    },
  ), [setStepIndex, stepIndex, steps]);

  return stepPage;
}
