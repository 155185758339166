/* eslint-disable react/jsx-props-no-spreading */
import { type FC, useMemo, useState } from 'react';
import cn from 'classnames';
import { Notification, NotificationContext, type NotificationContextData } from 'entities/notification';
import { Container } from 'react-bootstrap';
import styles from './withNotification.module.scss';

export const withNotification = <P extends {}>(Component: FC<P>): FC<P> => (props: P) => {
  const [data, setData] = useState<NotificationContextData>({ product: undefined });
  const contextValue = useMemo(() => ({
    setData(newData: NotificationContextData) {
      setData(newData);
    },
  }), [setData]);
  return (
    <NotificationContext.Provider value={contextValue}>
      <Container className={styles.root}>
        <div className={cn(styles.wrapper, data.product && styles.wrapperAnimate)}>
          {
            data.product && (
              <Notification
                actionType={data.actionType}
                product={data.product}
                onClose={() => setData({ product: undefined })}
              />
            )
          }
        </div>
      </Container>
      <Component {...props} />
    </NotificationContext.Provider>
  );
};
