import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from 'entities/user';
import { useNavigate } from 'react-router';
import { Paths } from 'shared/config';
import { useContext } from 'react';
import { ChangePhoneContext } from './ChangePhoneContext';

interface FormData {
  code: string;
}
export const useCodeForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    reset,
    clearErrors,
  } = useForm<FormData>();
  const { user } = useUser();
  const navigate = useNavigate();
  const { steps: { step1 } } = useContext(ChangePhoneContext);
  const setResendCode = () => null;
  const onSubmit: SubmitHandler<FormData> = (data) => {
    // TODO: Delete log after integration with backend
    console.log(data);
    navigate(Paths.Profile);
  };
  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    setValue,
    setError,
    reset,
    clearErrors,
    phone: user?.phone || step1?.phone,
    setResendCode,
  };
};
