/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type MeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, name?: string, surname?: string, middlename?: string, phone?: string, email?: string, city?: string, country?: string, street?: string, house?: string, flat?: string, postindex?: string, cityId?: string, streetId?: string, houseId?: string } };


export const MeDocument = `
    query me {
  me {
    id
    name
    surname
    middlename
    phone
    email
    city
    cityId: city_guid
    country
    street
    streetId: street_guid
    house
    houseId: house_guid
    flat
    postindex
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    me: build.query<MeQuery, MeQueryVariables | void>({
      query: (variables) => ({ document: MeDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMeQuery, useLazyMeQuery } = injectedRtkApi;

