import { regex } from 'shared/lib/regex';

export function useValidateContact(): (value: string) => boolean | string {
  return (value: string) => {
    if (value.indexOf('+7') === 0) {
      return regex.phoneSimplified.test(value)
        || regex.phone.test(value)
        || regex.phoneContinuous.test(value)
        || 'Неверный номер телефона';
    }
    return true;
  };
}
