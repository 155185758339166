import { ProductAttributeValues } from 'shared/api';

export const processAttributes = (
  attributes: ProductAttributeValues[],
) => {
  const result: Record<string, string> = {};
  attributes.forEach((attribute) => {
    if (attribute && attribute.attributeValue) {
      result[attribute.attribute_id] = attribute.attributeValue.id;
    }
  });
  return result;
};
