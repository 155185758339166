import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { SetStateAction, Dispatch } from 'react';
import { ArrowBackIcon, Button, CloseIcon } from 'shared/ui';
import { ProductAttributeValues } from 'shared/api';
import { AttributesIds, filterColors, filterSizes } from 'entities/product/attribute/model';
import styles from './AttributeModal.module.scss';
import { AttributeModalHeader } from './AttributeModalHeader';
import { AttributeModalBodyColors } from './AttributeModalBodyColors';
import { AttributeModalBodyDefault } from './AttributeModalBodyDefault';
import { AttributeModalFooter } from './AttributeModalFooter';
import { AttributeConfirmationModal } from './AttributeConfirmationModal';
import { CartAttribute } from '../CartAttribute';

interface AttributePopupPops {
  isPopupVisible: boolean;
  setPopupVisible: Dispatch<SetStateAction<boolean>>;
  updateAttributes: (attribute: ProductAttributeValues) => void;
  selectedAttributeId: string[];
  setSelectedAttributeId: Dispatch<SetStateAction<string[]>>;
  currentAttributes?: ProductAttributeValues[];
  formId: string;
  materialId: string;
  productValues?: ProductAttributeValues[];
  steps: number;
}

export const AttributeModal = ({
  currentAttributes,
  setPopupVisible,
  isPopupVisible,
  updateAttributes,
  selectedAttributeId,
  setSelectedAttributeId,
  formId,
  materialId,
  productValues,
  steps,
}: AttributePopupPops) => {
  const stepBack = () => {
    const currentLength = selectedAttributeId.length;
    selectedAttributeId.splice(currentLength - 1, 1);
    setSelectedAttributeId([...selectedAttributeId]);
  };
  const stepNext = (id: string) => {
    setSelectedAttributeId((prev: string[]) => [...prev, id]);
  };
  const modalHeader = currentAttributes?.filter((item) => (
    item.id === selectedAttributeId[steps]
  ))[0]?.attributeValue?.value;
  const attributeTitle = currentAttributes?.[0]?.attribute?.title;
  const [customColors, availableColors] = filterColors(
    currentAttributes || [],
    materialId,
  );
  const sizes = filterSizes(currentAttributes || [], formId);
  return (
    <Modal
      show={isPopupVisible}
      centered
      onHide={() => setPopupVisible(false)}
      className={
        currentAttributes?.[0]?.attribute_id === AttributesIds.LegStyle
        || currentAttributes?.[0]?.attribute_id === AttributesIds.Size
        || currentAttributes?.[0]?.attribute_id === AttributesIds.Form
          ? styles.root
          : styles.large
      }
    >
      {
        // eslint-disable-next-line no-nested-ternary
        selectedAttributeId.length > steps ? (
          <>
            <AttributeModalHeader
              stepBack={stepBack}
              modalHeader={modalHeader}
              setPopupVisible={setPopupVisible}
            />
            <ModalBody className={styles.body}>
              {currentAttributes?.filter((item) => item.id === selectedAttributeId[steps])
                .map((item) => (
                  <div key={item.id} className={styles.container}>
                    <AttributeConfirmationModal
                      attributeId={item.attribute_id}
                      attribute={item}
                    />
                  </div>
                ))}
            </ModalBody>
            <AttributeModalFooter
              attributes={currentAttributes?.filter(
                (item) => item.id === selectedAttributeId[steps],
              ) || []}
              updateAttributes={updateAttributes}
            />
          </>
        ) : selectedAttributeId.length < steps && steps === 2 ? (
          <ModalBody className={styles.mainBody}>
            <h3 className={styles.header}>Характеристики</h3>
            <div className={styles.close}>
              <CloseIcon onClick={() => setPopupVisible(false)} className={styles.closeIcon} />
            </div>
            {
              productValues?.map((value) => (
                <CartAttribute value={value} stepNext={stepNext} />
              ))
            }
            <Button
              onClick={() => setPopupVisible(false)}
              className={styles.button}
            >
              Применить
            </Button>
          </ModalBody>
        ) : (
          <>
            {
              (currentAttributes?.[0]?.attribute_id === AttributesIds.TableColor
                || currentAttributes?.[0]?.attribute_id === AttributesIds.LegColor
              ) ? (
                <ModalHeader className={styles.colorsHeader}>
                  <h3 className={styles.rootTitle}>{attributeTitle}</h3>
                  <div className={styles.close}>
                    <CloseIcon
                      onClick={() => setPopupVisible(false)}
                      className={styles.closeIcon}
                    />
                  </div>
                  {
                    // eslint-disable-next-line max-len
                    steps >= 2 && <div className={styles.back}><ArrowBackIcon onClick={stepBack} className={styles.backIcon} /></div>
                  }
                </ModalHeader>
                ) : null
            }
            {
              // eslint-disable-next-line no-nested-ternary
              currentAttributes?.[0]?.attribute_id === AttributesIds.TableColor
              || currentAttributes?.[0]?.attribute_id === AttributesIds.LegColor ? (
                <AttributeModalBodyColors
                  customColors={customColors}
                  availableColors={availableColors}
                  stepNext={stepNext}
                />
                ) : (
                  currentAttributes?.[0]?.attribute_id === AttributesIds.Size ? (
                    <AttributeModalBodyDefault
                      attributes={sizes || []}
                      attributeTitle={attributeTitle}
                      stepNext={stepNext}
                      updateAttributes={updateAttributes}
                      setPopupVisible={setPopupVisible}
                      stepBack={steps === 2 ? stepBack : undefined}
                    />
                  ) : (
                    <AttributeModalBodyDefault
                      attributes={currentAttributes || []}
                      attributeTitle={attributeTitle}
                      stepNext={stepNext}
                      updateAttributes={updateAttributes}
                      setPopupVisible={setPopupVisible}
                      stepBack={steps === 2 ? stepBack : undefined}
                    />
                  )
                )
            }
          </>
        )
      }
    </Modal>
  );
};
