/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import { useFormErrorMessages } from 'shared/lib';
import { PasswordInput, Button, IconButton, ArrowBackIcon } from 'shared/ui';
import { Paths } from 'shared/config';
import { FormContainer } from 'features/profile/edit/data';
import { usePasswordChangeForm } from '../model';
import styles from './PasswordChangeForm.module.scss';

export const PasswordChangeForm = () => {
  const {
    register,
    errors,
    onSubmit,
  } = usePasswordChangeForm();
  const errorMessage = useFormErrorMessages();
  return (
    <FormContainer
      title="Изменение пароля"
      description="Пароль должен состоять из 8+ символов, содержать буквы разного регистра и хотя бы одну цифру"
      onSubmit={onSubmit}
      className={styles.root}
    >
      <Link to={Paths.ProfilePassword}>
        <IconButton className="stepBack">
          <ArrowBackIcon />
        </IconButton>
      </Link>
      <PasswordInput
        id="oldPassword"
        label="Старый пароль"
        hasError={errors.oldPassword != null}
        help={errors.oldPassword ? errorMessage[errors.oldPassword.type] : undefined}
        {...register('oldPassword', { required: true })}
      />
      <PasswordInput
        id="newPassword"
        label="Новый пароль"
        hasError={errors.newPassword != null}
        help={errors.newPassword ? errorMessage[errors.newPassword.type] : undefined}
        {...register('newPassword', { required: true })}
      />
      <PasswordInput
        id="confirmNewPassword"
        label="Новый пароль еще раз"
        hasError={errors.confirmNewPassword != null}
        help={errors.confirmNewPassword ? errorMessage[errors.confirmNewPassword.type] : undefined}
        {...register('confirmNewPassword', { required: true })}
      />
      <Button type="submit" className={styles.submit}>Сохранить</Button>
      <Link to={Paths.ResetPassword} className="link">Забыли пароль?</Link>
    </FormContainer>
  );
};
