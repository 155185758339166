import cn from 'classnames';
import { FavoriteIcon } from 'shared/ui';
import styles from './AddToFavorite.module.scss';
import { useAddToFavorite } from '../model';

interface AddToFavoriteProps {
  productId?: string;
  onClick: () => void;
  inFavorite: boolean;
}
export const AddToFavorite = ({ productId, onClick, inFavorite }: AddToFavoriteProps) => {
  const { addToFavorite } = useAddToFavorite(productId);
  const handleClick = () => {
    addToFavorite();
    onClick();
  };
  return (
    <FavoriteIcon
      className={cn(styles.root, inFavorite && styles.rootInFavorite)}
      onClick={handleClick}
    />
  );
};
