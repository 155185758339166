/* eslint-disable react/jsx-props-no-spreading */
import { ArrowBackIcon, Button, EmailInput, IconButton } from 'shared/ui';
import { FormContainer } from 'features/profile/edit/data';
import { MultistepPageProps, useFormErrorMessages } from 'shared/lib';
import { Paths } from 'shared/config';
import { Link } from 'react-router-dom';
import styles from 'features/profile/edit/data/ui/MainForms.module.scss';
import { useEmailForm } from '../model';

export const EmailChangeForm = ({ pagination }: MultistepPageProps) => {
  const { register, onSubmit, errors, defaultValue } = useEmailForm(pagination);
  const errorMessage = useFormErrorMessages();
  return (
    <FormContainer
      title="Изменить почту"
      onSubmit={onSubmit}
      className={styles.root}
      description="Новую почту нужно будет подтвердить"
      rootClass
    >
      <Link to={Paths.Profile}>
        <IconButton className="stepBack">
          <ArrowBackIcon />
        </IconButton>
      </Link>
      <EmailInput
        id="email"
        label="Почта"
        placeholder="example@mail.ru"
        hasError={errors.email != null}
        help={errors.email ? errorMessage.required : undefined}
        defaultValue={defaultValue}
        {...register('email', { required: true })}
      />
      <Button type="submit" className={styles.submit}>Продолжить</Button>
    </FormContainer>
  );
};
