import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from 'entities/user';
import { Paths } from 'shared/config';
import { useNavigate } from 'react-router';

interface FormData {
  password: string;
}
export const usePasswordConfirmationForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>();
  const { user } = useUser();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    // TODO: Delete log after integration with backend
    console.log(data);
    navigate(Paths.Profile);
  };
  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    defaultValue: user?.email,
  };
};
