import { ReactNode, useEffect, useState } from 'react';
import { ProductFiles, ProductFront } from 'shared/api';
import { ProductAttribute } from 'entities/product/attribute';
import {
  DeliveryIcon,
  DownloadIcon,
  ModelIcon,
  TermIcon,
} from 'shared/ui';
import { motion } from 'framer-motion';
import styles from './Product.module.scss';

interface ProductProps {
  product?: ProductFront;
  productImg?: ProductFiles[];
  favoriteAction: ReactNode;
  actions?: ReactNode[];
  animationState: boolean;
}

export const Product = (
  {
    product,
    productImg,
    favoriteAction,
    actions,
    animationState,
  }: ProductProps,
) => {
  const [viewport, setViewport] = useState(window.scrollY);

  const updateViewport = () => {
    setViewport(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateViewport);

    return () => {
      window.removeEventListener('scroll', updateViewport);
    };
  }, []);
  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <div className={styles.icons}>
          {favoriteAction}
          <ModelIcon />
        </div>
        <div>
          <img src={productImg && productImg[0].file_path} alt="" />
          {
            animationState && (
              <motion.img
                src={productImg && productImg[0].file_path}
                initial={{ scale: 1, y: 0 }}
                animate={animationState
                  ? { scale: 0, y: 650 + viewport, opacity: 0.1 }
                  : { scale: 1, y: 0 }}
                transition={{ duration: 1 }}
                className={styles.animate}
              />
            )
          }
          <div className={styles.download}>
            <button type="button">
              <DownloadIcon />
              3D модель
            </button>
            <button type="button">
              <DownloadIcon />
              Спецификация
            </button>
          </div>
        </div>
      </div>
      <div className={styles.wrapper}>
        <h2 className={styles.name}>{product?.name}</h2>
        <div className={styles.attributes}>
          {
            product?.productValues?.map((value) => (
              <ProductAttribute
                key={value.id}
                value={value}
                productVariantValues={product?.productVariantValues}
              />
            ))
          }
        </div>
        <div className={styles.actions}>
          {actions}
        </div>
        <div className={styles.info}>
          <div className={styles.item}>
            <TermIcon />
            <div>
              <h3>Срок изготовления</h3>
              <span>До 21 календарного дня</span>
            </div>
          </div>
          <div className={styles.item}>
            <DeliveryIcon />
            <div>
              <h3>Доставка по РФ</h3>
              <span>СДЭКом из Красноярска</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
