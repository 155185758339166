// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordForm_root__4aa0w {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.PasswordForm_submit__UmMBq {
  width: 100%;
  height: 56px;
}`, "",{"version":3,"sources":["webpack://./src/features/auth/reset-password/ui/PasswordForm.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,WAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;AADF","sourcesContent":["@import \"shared/styles/theme\";\n\n.root {\n  display: flex;\n  flex-direction: column;\n  gap: spacing(6);\n  width: 100%;\n}\n\n.submit {\n  width: 100%;\n  height: 56px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PasswordForm_root__4aa0w`,
	"submit": `PasswordForm_submit__UmMBq`
};
export default ___CSS_LOADER_EXPORT___;
