/* eslint-disable react/jsx-props-no-spreading */
import {
  ButtonHTMLAttributes, DetailedHTMLProps,
  PropsWithChildren,
} from 'react';
import cn from 'classnames';
import styles from './IconButton.module.scss';

export const IconButton = ({ className, children, ...rest }: PropsWithChildren<
DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>,
HTMLButtonElement
>
>) => (
  <button
    className={cn(styles.root, className)}
    type="button"
    {...rest}
  >
    {children}
  </button>
);
