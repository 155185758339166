import cn from 'classnames';
import { addGetParamToHref } from 'shared/lib';
import styles from './Pagination.module.scss';
import { PaginationNextItem } from './PaginationNextItem';
import { PaginationPrevItem } from './PaginationPrevItem';
import { type PaginationItemProps } from './PaginationItems';
import { PaginationItems } from './PaginationItems';
import { PaginationItem } from './PaginationItem';

interface PaginationProps {
  page: number;
  total: number;
  href: string;
  className?: string;
}

const PAGINATION_PAGE_COUNT = 7;
function createPaginationItems({
  page,
  total,
  generateHref,
}: { page: number, total: number, generateHref: (page: number) => string }): PaginationItemProps[] {
  // Get items size - subtract 2 because first and last item are fixed
  const dynamicPageCount = Math.min(PAGINATION_PAGE_COUNT - 2, total - 2);
  const items: PaginationItemProps[] = new Array(dynamicPageCount);
  const step = Math.floor(dynamicPageCount / 2);
  // Generate items from page number
  let from = Math.max(page - step, 2);
  // Generate items to page number (including)
  let to = from + dynamicPageCount - 1;
  const overflow = to - (total - 1);
  // Prevent overflow above total - 1
  if (overflow > 0) {
    from -= overflow;
    to -= overflow;
  }
  let j = 0;
  for (let i = from; i <= to; i += 1) {
    items[j] = { href: generateHref(i), page: i };
    j += 1;
  }
  return items;
}

const addPageToHref = (href: string, page: number, hasSearch: boolean) => addGetParamToHref(href, 'page', page === 1 ? '' : page, hasSearch);

/**
 * @see https://www.ycmc.com/collections/mens-sneakers?page=6
 */
export const Pagination = (
  { page, total, href, className }: PaginationProps,
) => {
  if (total <= 1) {
    return null;
  }
  const hasSearch = href.indexOf('?') !== -1;
  const isFirst = page === 1;
  const isLast = page === total;
  const items = createPaginationItems({
    page,
    total,
    generateHref: (itemPage: number) => addPageToHref(href, itemPage, hasSearch),
  });
  return (
    <div className={cn(styles.root, className)}>
      <nav className={styles.nav}>
        {/* Previous */}
        {
          !isFirst && (
            <PaginationPrevItem
              href={addPageToHref(href, page - 1, hasSearch)}
              isFirst={isFirst}
            />
          )
        }
        {/* First */}
        <PaginationItem
          href={addPageToHref(href, 1, hasSearch)}
          text="1"
          currentPage={page}
          itemPage={1}
        />
        {/* Dynamic */}
        <PaginationItems
          items={items}
          href={href}
          page={page}
          total={total}
          hasSearch={hasSearch}
        />
        {/* Last */}
        <PaginationItem
          href={addPageToHref(href, total, hasSearch)}
          text={`${total}`}
          currentPage={page}
          itemPage={total}
        />
        {/* Next */}
        {
          !isLast && (
            <PaginationNextItem
              href={addPageToHref(href, page + 1, hasSearch)}
              isLast={isLast}
            />
          )
        }
      </nav>
    </div>
  );
};
