import { Container } from 'react-bootstrap';
import { ProfileMenu } from 'entities/profile/menu';
import { ProfileInfo } from 'features/profile/info';
import styles from './ProfilePage.module.scss';

export const ProfilePage = () => (
  <div className={styles.root}>
    <Container>
      <div className={styles.wrapper}>
        <ProfileMenu />
        <ProfileInfo />
      </div>
    </Container>
  </div>
);
