/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, useState, FormEvent, KeyboardEvent } from 'react';
import { FormControl, FormControlProps, FormLabel, FormGroup } from 'react-bootstrap';
import cn from 'classnames';
import styles from 'shared/ui/form/input/Input.module.scss';
import textareaStyles from './Textarea.module.scss';

interface TextAreaProps extends FormControlProps {
  label?: string,
  id: string,
  hasError?: boolean,
  help?: string,
  required?: boolean,
  rows?: number,
  maxLength: number,
  placeholder?: string;
}
export const Textarea = forwardRef<HTMLInputElement, TextAreaProps>(({
  label,
  id,
  // eslint-disable-next-line react/prop-types
  className,
  hasError,
  help,
  required,
  // eslint-disable-next-line react/prop-types
  defaultValue,
  rows = 5,
  maxLength,
  placeholder,
  ...props
}, ref) => {
  const [charCount, setCharCount] = useState<number>(
    // eslint-disable-next-line react/prop-types
    (props.value && String(props.value).length)
    || (defaultValue && String(defaultValue).length)
    || 0,
  );
  const onInput = (e: FormEvent<HTMLTextAreaElement>) => {
    const { length } = e.currentTarget.value;
    setCharCount(length);
  };
  const disableLineBreak = (e: KeyboardEvent) => {
    if (e.keyCode === 13) {
      e.preventDefault();
    }
  };

  return (
    <FormGroup
      className={cn(
        styles.root,
        hasError && styles.hasError,
        className,
      )}
    >
      <div className={textareaStyles.wrapper}>
        {label && (
          <FormLabel htmlFor={id} className={styles.label}>
            {label}
            {required && <span className={styles.required}>*</span>}
          </FormLabel>
        )}
        <span className={textareaStyles.help}>{`${charCount}/${maxLength}`}</span>
      </div>
      <FormControl
        className={textareaStyles.textarea}
        id={id}
        ref={ref}
        as="textarea"
        placeholder={placeholder}
        rows={rows}
        onInput={onInput}
        onKeyDown={disableLineBreak}
        {...props}
      />
    </FormGroup>
  );
});
