import { addGetParamToHref } from 'shared/lib';
import { PaginationItem } from './PaginationItem';

export type PaginationItemProps = {
  href: string;
  page: number;
};

interface PaginationItemsProps {
  items: PaginationItemProps[];
  href: string;
  hasSearch: boolean;
  page: number;
  total: number;
}

const addPageToHref = (href: string, page: number, hasSearch: boolean) => addGetParamToHref(href, 'page', page === 1 ? '' : page, hasSearch);

export const PaginationItems = ({ items, href, page, total, hasSearch }: PaginationItemsProps) => {
  const getHrefAndText = (item: PaginationItemProps, index: number) => {
    let text = item.page.toString();
    let { href: itemHref } = item;
    if (index === 0) {
      const diff = Math.abs(item.page - 1);
      if (diff > 1) {
        itemHref = addPageToHref(href, Math.ceil(item.page - diff / 2), hasSearch);
        text = '...';
      }
    } else if (index === items.length - 1 && item.page + 1 !== total) {
      const diff = Math.abs(total - item.page);
      if (diff > 1) {
        itemHref = addPageToHref(href, Math.floor(item.page + diff / 2), hasSearch);
        text = '...';
      }
    }
    return { itemHref, text };
  };
  return (
    <>
      {items.map((item, i) => {
        const { itemHref, text } = getHrefAndText(item, i);
        return (
          <PaginationItem
            key={item.page}
            href={itemHref}
            text={text}
            itemPage={item.page}
            currentPage={page}
          />
        );
      })}
    </>
  );
};
