/* eslint-disable react/jsx-props-no-spreading */
import { Container } from 'react-bootstrap';
import { ProfileMenu } from 'entities/profile/menu';
import { OrderCard } from 'features/order-card';
import { useParams } from 'react-router';
import { useGetOrderQuery } from 'shared/api/orderHistory';
import { useMemo } from 'react';
import { LoadingHOC } from 'shared/ui';
import styles from './OrderCardPage.module.scss';

interface UserParams {
  id: string
}
export const OrderCardPage = () => {
  const { id } = useParams<keyof UserParams>() as UserParams;
  const query = useGetOrderQuery({ id });
  const orderCard = useMemo(() => query.currentData?.Order, [query.currentData]);
  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.wrapper}>
          <ProfileMenu />
          <div className={styles.content}>
            <LoadingHOC isLoading={!orderCard} className={styles.loader}>
              {
                orderCard && (
                  <OrderCard orderCard={orderCard} />
                )
              }
            </LoadingHOC>
          </div>
        </div>
      </Container>
    </div>
  );
};
