/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type CheckoutDeliveryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CheckoutDeliveryQuery = { __typename?: 'Query', CheckoutDelivery?: Array<{ __typename?: 'DeliveryGroupFront', id: string, title: string, parameters?: any, calculatePrice: boolean, hasPickup: boolean }> };


export const CheckoutDeliveryDocument = `
    query CheckoutDelivery {
  CheckoutDelivery {
    id
    title
    parameters
    calculatePrice: calculate_price
    hasPickup: has_pickup
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    CheckoutDelivery: build.query<CheckoutDeliveryQuery, CheckoutDeliveryQueryVariables | void>({
      query: (variables) => ({ document: CheckoutDeliveryDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCheckoutDeliveryQuery, useLazyCheckoutDeliveryQuery } = injectedRtkApi;

