// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Textarea_textarea__VyXaP {
  max-height: 100% !important;
  height: 148px;
  resize: none;
  padding: 0.75rem !important;
}

.Textarea_help__K7-aS {
  display: block;
  text-align: right;
  font-size: 1rem;
  font-weight: 400;
  color: #95989F;
  margin-bottom: 0.375rem;
}

.Textarea_wrapper__qOfNn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/shared/ui/form/input/Textarea.module.scss","webpack://./src/shared/styles/mixins/_typography.scss","webpack://./src/shared/styles/vars/_colors.scss"],"names":[],"mappings":"AAEA;EACE,2BAAA;EACA,aAAA;EACA,YAAA;EACA,2BAAA;AADF;;AAIA;EACE,cAAA;EACA,iBAAA;ECeE,eAAA;EACA,gBAAA;EDdF,cETK;EFUL,uBAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;AAAF","sourcesContent":["@import \"shared/styles/theme\";\n\n.textarea {\n  max-height: 100% !important;\n  height: 148px;\n  resize: none;\n  padding: spacing(3) !important;\n}\n\n.help {\n  display: block;\n  text-align: right;\n  @include typography('text');\n  color: $grey;\n  margin-bottom: spacing(1.5);\n}\n\n.wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}","@mixin typography($variant) {\n  @if $variant == 'h1' {\n    font-size: 4rem;\n    font-weight: 400;\n  }\n  @if $variant == 'h2' {\n    font-size: 2.5rem;\n    font-weight: 400;\n  }\n  @if $variant == 'h3' {\n    font-size: 2rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-lg' {\n    font-size: 1.5rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-md' {\n    font-size: 1.25rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'subtitle' {\n    font-size: 1rem;\n    font-weight: 500;\n  }\n  @else if $variant == 'text' {\n    font-size: 1rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-sm' {\n    font-size: 0.75rem;\n    font-weight: 400;\n  }\n}","$main-bg: #C2CBD1;\n$primary: #21252E;\n$secondary: #FFFFFF;\n\n$grey: #95989F;\n$grey-light: #F4F5F6;\n$grey-hover: #EDEFF0;\n$grey-active: #E1E8EC;\n\n$red: #B43737;\n\n$border: #C2CBD1;\n\n$error: #B43737;\n$link: #3B72C5;\n$disabled: #E5E5E5;\n\n$input-focus: #21252E;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": `Textarea_textarea__VyXaP`,
	"help": `Textarea_help__K7-aS`,
	"wrapper": `Textarea_wrapper__qOfNn`
};
export default ___CSS_LOADER_EXPORT___;
