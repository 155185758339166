import { useState } from 'react';
import { Link } from 'react-router-dom';
import { LoginByCodeContext, ContactForm, useLoginByCodeContext, CodeForm } from 'features/auth/login-by-code';
import { Multistep } from 'shared/lib';
import { Paths } from 'shared/config';
import { Container } from 'react-bootstrap';
import styles from './LoginByCodePage.module.scss';

export const LoginByCodePage = () => {
  const stepState = useState(0);
  const loginByCodeContextValue = useLoginByCodeContext();
  const [step] = stepState;

  return (
    <Container>
      <div className={styles.root}>
        <div className={styles.wrapper}>
          <LoginByCodeContext.Provider value={loginByCodeContextValue}>
            <Multistep
              state={stepState}
              steps={[
                ContactForm,
                CodeForm,
              ]}
            />
          </LoginByCodeContext.Provider>
          {step === 0 && (
            <div className="link-container">
              <Link to={Paths.LoginByPassword} className="link">Войти с паролем</Link>
            </div>
          )}
        </div>
        {step === 0 && (
          <div className={styles.policy}>
            <p>
              Нажимая кнопку «получить код», вы соглашаетесь с нашей
              <Link to="/policy" target="_blank"> политикой приватности и договором оферты</Link>
            </p>
            <p>
              Этот сайт защищен reCaptcha и применяются
              <Link to="https://policies.google.com/privacy" target="_blank"> политика приватности</Link>
              {' '}
              и
              <Link to="https://policies.google.com/terms" target="_blank"> условия использования Google</Link>
            </p>
          </div>
        )}
      </div>
    </Container>
  );
};
