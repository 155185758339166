import { Link } from 'react-router-dom';
import cn from 'classnames';
import styles from './PaginationItem.module.scss';

interface PaginationItemProps {
  href: string;
  text: string;
  currentPage: number;
  itemPage: number;
}

export const PaginationItem = ({ href, text, currentPage, itemPage }: PaginationItemProps) => (
  <Link
    to={href}
    className={cn(styles.item, currentPage === itemPage ? styles.itemActive : null)}
  >
    {text}
  </Link>
);
