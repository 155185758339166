import { IncDecInput } from 'shared/ui';
import { Cartproduct } from 'shared/api';
import { useCartDecreaseAmountMutation, useCartIncreaseAmountMutation } from 'shared/api/cart';

interface IncDecCartItemProps {
  product: Cartproduct;
}
export const IncDecCartItem = ({ product }: IncDecCartItemProps) => {
  const [incCart, isIncLoading] = useCartIncreaseAmountMutation();
  const [decCart, isDecLoading] = useCartDecreaseAmountMutation();
  const onInc = () => {
    const input = { product_id: product.id };
    incCart({ input });
  };
  const onDec = () => {
    const input = { product_id: product.id };
    decCart({ input });
  };
  return (
    <IncDecInput
      value={product?.amount}
      onInc={onInc}
      onDec={onDec}
      isIncLoading={isIncLoading.isLoading}
      isDecLoading={isDecLoading.isLoading}
    />
  );
};
