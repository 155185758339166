import { useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Paths } from 'shared/config/constants';
import { CloseIcon } from 'shared/ui/icons';
import { PopupProps } from '../types';
import styles from './Popup.module.scss';

export const Popup = (
  {
    isPopupVisible,
    setPopupVisible,
    children,
    redirect = false,
    size,
  }: PopupProps,
) => {
  const navigate = useNavigate();

  useEffect(() => {
    let startTouchY = 0;
    let endTouchY = 0;

    document.addEventListener('touchstart', (e) => {
      endTouchY = e.changedTouches[0].pageY;
    });
    document.addEventListener('touchend', (e) => {
      startTouchY = e.changedTouches[0].pageY;
      if (endTouchY < startTouchY && isPopupVisible) {
        return redirect ? navigate(Paths.Root) : setPopupVisible(false);
      }
      return null;
    });
  });
  return (
    <Modal
      show={isPopupVisible}
      centered
      className={cn(styles.modal, { [styles[size]]: styles[size] })}
      onHide={() => setPopupVisible(false)}
      size={size}
    >
      <ModalHeader className={styles.header}>
        {
          redirect ? (
            <Link to={Paths.Root}>
              <CloseIcon className={styles.icon} />
            </Link>
          ) : <CloseIcon className={styles.icon} onClick={() => setPopupVisible(false)} />
        }
        <div className={styles.swiper} />
      </ModalHeader>
      <ModalBody className={styles.body}>
        {children}
      </ModalBody>
    </Modal>
  );
};
