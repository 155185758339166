import { useMemo } from 'react';
import { useMeQuery } from 'shared/api/user';
import { User } from './types';

export function useUser() {
  const query = useMeQuery();
  const user: User | null = useMemo(() => query.currentData?.me ?? null, [query.currentData]);
  // TODO:
  //  Delete userHasPassword after a field with information
  //  about the password appears in the user object
  return { user: query.isError ? null : user, isLoading: query.isFetching, userHasPassword: true };
}
