/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type SuggestAddressQueryVariables = Types.Exact<{
  input: Types.SuggestAddressInput;
}>;


export type SuggestAddressQuery = { __typename?: 'Query', SuggestAddress?: Array<{ __typename?: 'DadataResult', value?: string, data: { __typename?: 'DadataResultData', city?: string, settlement?: string, street?: string, house?: string, cityId?: string, settlementId?: string, streetId?: string, houseId?: string, postalCode?: string } }> };


export const SuggestAddressDocument = `
    query SuggestAddress($input: suggestAddressInput!) {
  SuggestAddress(input: $input) {
    value
    data {
      city
      cityId: city_fias_id
      settlement
      settlementId: settlement_fias_id
      street
      streetId: street_fias_id
      house
      houseId: house_fias_id
      postalCode: postal_code
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    SuggestAddress: build.query<SuggestAddressQuery, SuggestAddressQueryVariables>({
      query: (variables) => ({ document: SuggestAddressDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useSuggestAddressQuery, useLazySuggestAddressQuery } = injectedRtkApi;

