/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1.5em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.5 14a.5.5 0 0 0-1 0zM10 4.5a.5.5 0 0 0 0-1zm-5.782.592.445.227zm.874-.874.227.445zm0 15.564.227-.445zm-.874-.874-.446.227zm15.564 0-.445-.227zm-.874.874-.227-.445zM10 14h-.5a.5.5 0 0 0 .5.5zm0-3-.354-.354A.5.5 0 0 0 9.5 11zm9-9 .354-.354a.5.5 0 0 0-.708 0zm3 3 .354.354a.5.5 0 0 0 0-.708zm-9 9v.5a.5.5 0 0 0 .354-.146zm3.354-9.354a.5.5 0 0 0-.708.708zm2.292 3.708a.5.5 0 0 0 .708-.708zM19.5 14v2.8h1V14zm-2.7 5.5H7.2v1h9.6zM4.5 16.8V7.2h-1v9.6zM7.2 4.5H10v-1H7.2zM4.5 7.2c0-.568 0-.964.026-1.273.024-.302.07-.476.137-.608l-.89-.454c-.151.296-.214.616-.244.98-.03.36-.029.803-.029 1.355zm2.7-3.7c-.552 0-.996 0-1.354.029-.365.03-.685.093-.981.243l.454.891c.131-.067.305-.113.608-.137C6.236 4.5 6.632 4.5 7.2 4.5zM4.663 5.319a1.5 1.5 0 0 1 .656-.656l-.454-.89a2.5 2.5 0 0 0-1.093 1.092zM7.2 19.5c-.568 0-.964 0-1.273-.026-.303-.024-.477-.07-.608-.137l-.454.89c.296.151.616.214.98.244.36.03.803.029 1.355.029zm-3.7-2.7c0 .552 0 .996.029 1.354.03.365.093.685.243.981l.891-.454c-.067-.132-.113-.306-.137-.608-.026-.308-.026-.705-.026-1.273zm1.819 2.537a1.5 1.5 0 0 1-.656-.656l-.89.454a2.5 2.5 0 0 0 1.092 1.092zM19.5 16.8c0 .568 0 .965-.026 1.273-.024.303-.07.476-.137.608l.89.454c.152-.296.215-.616.244-.98.03-.36.029-.803.029-1.355zm-2.7 3.7c.552 0 .996 0 1.354-.029.365-.03.685-.093.98-.244l-.453-.89c-.132.067-.306.113-.608.137-.308.026-.704.026-1.273.026zm2.537-1.819a1.5 1.5 0 0 1-.656.656l.454.89a2.5 2.5 0 0 0 1.093-1.092zM10.5 14v-3h-1v3zm-.146-2.646 9-9-.708-.708-9 9zm8.292-9 3 3 .708-.708-3-3zm3 2.292-9 9 .708.708 9-9zM13 13.5h-3v1h3zm2.646-8.146 3 3 .708-.708-3-3z"
    />
  </svg>
);
export default SvgPencil;
