// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PickupPoint_root__TZ\\+Fz {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #C2CBD1;
}

.PickupPoint_id__RzUUU {
  color: #21252E;
}

.PickupPoint_name__iWgAE {
  display: flex;
  gap: 0.25rem;
}

.PickupPoint_schedule__sRC0n {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #21252E;
  font-size: 0.75rem;
  font-weight: 400;
}
.PickupPoint_schedule__sRC0n svg {
  width: 1rem;
  height: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/entities/address/ui/PickupPoint.module.scss","webpack://./src/shared/styles/vars/_colors.scss","webpack://./src/shared/styles/mixins/_typography.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,uBAAA;EACA,gCAAA;AADF;;AAIA;EACE,cCVQ;ADSV;;AAIA;EACE,aAAA;EACA,YAAA;AADF;;AAIA;EACE,aAAA;EACA,WAAA;EACA,mBAAA;EACA,cCtBQ;EC6BN,kBAAA;EACA,gBAAA;AFPJ;AACE;EACE,WAAA;EACA,YAAA;AACJ","sourcesContent":["@import \"shared/styles/theme\";\n\n.root {\n  display: flex;\n  flex-direction: column;\n  gap: spacing(2);\n  padding-bottom: spacing(3);\n  border-bottom: 1px solid $border;\n}\n\n.id {\n  color: $primary;\n}\n\n.name {\n  display: flex;\n  gap: spacing(1);\n}\n\n.schedule {\n  display: flex;\n  gap: spacing(2);\n  align-items: center;\n  color: $primary;\n\n  svg {\n    width: spacing(4);\n    height: spacing(4);\n  }\n\n  @include typography('text-sm');\n}\n","$main-bg: #C2CBD1;\n$primary: #21252E;\n$secondary: #FFFFFF;\n\n$grey: #95989F;\n$grey-light: #F4F5F6;\n$grey-hover: #EDEFF0;\n$grey-active: #E1E8EC;\n\n$red: #B43737;\n\n$border: #C2CBD1;\n\n$error: #B43737;\n$link: #3B72C5;\n$disabled: #E5E5E5;\n\n$input-focus: #21252E;\n","@mixin typography($variant) {\n  @if $variant == 'h1' {\n    font-size: 4rem;\n    font-weight: 400;\n  }\n  @if $variant == 'h2' {\n    font-size: 2.5rem;\n    font-weight: 400;\n  }\n  @if $variant == 'h3' {\n    font-size: 2rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-lg' {\n    font-size: 1.5rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-md' {\n    font-size: 1.25rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'subtitle' {\n    font-size: 1rem;\n    font-weight: 500;\n  }\n  @else if $variant == 'text' {\n    font-size: 1rem;\n    font-weight: 400;\n  }\n  @else if $variant == 'text-sm' {\n    font-size: 0.75rem;\n    font-weight: 400;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PickupPoint_root__TZ+Fz`,
	"id": `PickupPoint_id__RzUUU`,
	"name": `PickupPoint_name__iWgAE`,
	"schedule": `PickupPoint_schedule__sRC0n`
};
export default ___CSS_LOADER_EXPORT___;
