/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type CatalogQueryVariables = Types.Exact<{
  input?: Types.InputMaybe<Types.CatalogInput>;
}>;


export type CatalogQuery = { __typename?: 'Query', Catalog: { __typename?: 'CatalogResponse', products?: { __typename?: 'Subproducts', data?: Array<{ __typename?: 'Subproduct', id: string, name: string, price: number, slug: string, imagePath?: string }> } } };


export const CatalogDocument = `
    query Catalog($input: CatalogInput) {
  Catalog(input: $input) {
    products {
      data {
        id
        name
        price
        imagePath: first_image_url
        slug
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    Catalog: build.query<CatalogQuery, CatalogQueryVariables | void>({
      query: (variables) => ({ document: CatalogDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCatalogQuery, useLazyCatalogQuery } = injectedRtkApi;

