import { Button } from 'shared/ui';
import { Paths } from 'shared/config';
import { OrderStatusValues } from 'entities/order-history/types';
import styles from './OrderCard.module.scss';

interface OrderActionsProps {
  status: string;
}
export const OrderActions = ({ status }: OrderActionsProps) => (
  <>
    <Button
      variant="secondary"
      className={styles.btn}
      href={Paths.Contacts}
    >
      Нужна помощь?
    </Button>
    {
      status === OrderStatusValues.InProgress && (
        <Button className={styles.btn}>Отследить</Button>
      )
    }
    {
      status === OrderStatusValues.AwaitingPayment && (
        <Button className={styles.btn}>Оплатить</Button>
      )
    }
  </>
);
