/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgArrowBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path stroke="currentColor" strokeLinecap="square" d="M4 12h17M8 17l-5-5 5-5" />
  </svg>
);
export default SvgArrowBack;
