import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { withStore } from 'app/providers/with-store';
import { router } from './router';
import 'shared/styles/index.scss';

const App = () => (
  <RouterProvider router={router} />
);

export default withStore(App);
