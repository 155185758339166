/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type LoginMutationVariables = Types.Exact<{
  input: Types.LoginUserInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', Login: { __typename?: 'AuthResponse', token?: string, tokenType?: string, expiresIn?: number } };


export const LoginDocument = `
    mutation Login($input: LoginUserInput!) {
  Login(input: $input) {
    token
    tokenType: token_type
    expiresIn: expires_in
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    Login: build.mutation<LoginMutation, LoginMutationVariables>({
      query: (variables) => ({ document: LoginDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useLoginMutation } = injectedRtkApi;

