import { invalidateUserTags, TAG_USER } from '../user';
import { api as loginApi } from './login.csr.generated';

export { useLoginMutation } from './login.csr.generated';

const api = loginApi.enhanceEndpoints({
  addTagTypes: [TAG_USER],
  endpoints: {
    Login: {
      invalidatesTags: [],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { invalidateTags } = api.util;
        await invalidateUserTags({ dispatch, queryFulfilled, invalidateTags });
      },
    },
  },
});
