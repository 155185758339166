/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgCatalog = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 6h11M9 12h11M9 18h11M5 6v.01M5 12v.01M5 18v.01"
    />
  </svg>
);
export default SvgCatalog;
