import { DownloadIcon } from 'shared/ui';
import { Link } from 'react-router-dom';
import { ICatalogItem } from '../types';
import styles from './CatalogsItem.module.scss';

interface CatalogItemProps {
  item: ICatalogItem;
}
export const CatalogsItem = ({ item }: CatalogItemProps) => (
  <Link to={item.link}>
    <div className={styles.root}>
      <span className={styles.text}>{item.text}</span>
      <DownloadIcon className={styles.icon} />
    </div>
  </Link>
);
