import { Slider } from 'shared/ui';
import { useGetPortfolio } from '../model';
import styles from './PortfolioPage.module.scss';

export const PortfolioPage = () => {
  const data = useGetPortfolio();
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Портфолио</h1>
      <span className={styles.subtitle}>Вдохновение от наших дизайнеров</span>
      <div>
        <Slider items={data} />
      </div>
    </div>
  );
};
