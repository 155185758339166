import { Cartproduct, OrderStatus } from 'shared/api';

interface ProductFile {
  filePath: string;
  fileType?: {
    id: string;
    name: string;
  };
  id: string
}
export interface ProductsFiles {
  productFiles?: ProductFile[]
}

export interface HistoryType {
  id: string;
  status: OrderStatus;
  products?: ProductsFiles[],
  productsCount?: number;
  total: number;
  createdAt: any;
}

export interface OrderCardType {
  id?: string;
  status: OrderStatus;
  products?: Cartproduct[],
  total: number;
  comission: number;
  createdAt: string;
  address: string;
  fio: string;
  isAvailablePayment?: boolean;
  isAvailableTrack?: boolean;
}

export enum OrderStatusValues {
  OrderApproval = 'OrderApproval',
  AwaitingPayment = 'AwaitingPayment',
  Paid = 'Paid',
  InAssembly = 'InAssembly',
  IsCreated = 'IsCreated',
  InProgress = 'InProgress',
  Delivered = 'Delivered',
  Cancelled = 'Cancelled',
}
