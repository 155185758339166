import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Paths } from 'shared/config';
import { useEffect, Dispatch, SetStateAction } from 'react';
import { useUser, castAuthData, setAuthData } from 'entities/user';
import { useLazyIsUserExistsQuery, TAG_USER } from 'shared/api/user';
import { useCheckoutCreateOrderMutation } from 'shared/api/checkout';
import { normalizePhone, regex } from 'shared/lib';
import { useLoginStep1Mutation } from 'shared/api/login-by-code';
import { PickupPoint } from 'entities/address';
import { useDispatch } from 'react-redux';
import { api } from 'shared/api';
import { useCdekDelivery } from './useCdekDelivery';

interface UseCheckoutFormProps {
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}

interface FormData {
  name: string;
  surname: string;
  phone: string;
  comment?: string;
  delivery: string;
  country: string;
  city: any;
  cityFias: string;
  street: any;
  house: string;
  apartment: string;
  code: string;
  pvz?: PickupPoint;
}
export const useCheckoutForm = ({ setIsModalVisible }: UseCheckoutFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
    unregister,
    resetField,
  } = useForm<FormData>();
  const dispatch = useDispatch();
  const [isUserExists] = useLazyIsUserExistsQuery();
  const [loginStep1] = useLoginStep1Mutation();
  const [createOrder] = useCheckoutCreateOrderMutation();
  const navigate = useNavigate();
  const cdekDelivery = useCdekDelivery();
  const { user } = useUser();
  useEffect(() => {
    if (cdekDelivery.length > 0) {
      setValue('delivery', cdekDelivery[0].value);
    }
  }, [cdekDelivery, setValue]);
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const {
      name,
      surname,
      phone,
      country,
      city,
      street,
      apartment,
      code,
      delivery,
      pvz,
      comment,
    } = data;
    const orderInput = {
      name,
      family_name: surname,
      phone: normalizePhone(phone),
      country,
      city: city?.city ?? city,
      street: street?.street ?? undefined,
      house: street?.house ?? undefined,
      flat: apartment,
      postindex: code ?? undefined,
      delivery_id: Number(delivery),
      pickup_point: pvz?.id ?? undefined,
      city_guid: city?.cityId,
      street_guid: street?.streetId,
      house_guid: street?.houseId,
      comment,
    };
    if (!regex.phoneContinuous.test(data.phone) && !regex.phoneSimplified.test(data.phone)) {
      return;
    }
    const userInput = { phone: normalizePhone(data.phone) };
    if (user === null) {
      isUserExists({ input: userInput })
        .then(({ data: exists }) => {
          if (exists?.IsUserExists) {
            setIsModalVisible(true);
            loginStep1({ input: userInput });
          } else {
            createOrder({ input: orderInput })
              .unwrap()
              .then((res) => {
                const authData = castAuthData(res.CheckoutCreateOrder.auth!);
                if (authData == null) {
                  throw new Error();
                }
                dispatch(setAuthData(authData));
                dispatch(api.util.invalidateTags([TAG_USER as never]));
                navigate(Paths.CheckoutSuccess, { state: res.CheckoutCreateOrder.id });
              });
          }
        });
    } else {
      createOrder({ input: orderInput })
        .unwrap()
        .then((res) => {
          navigate(Paths.CheckoutSuccess, { state: res.CheckoutCreateOrder.id });
        });
    }
  };
  return {
    cdekDelivery,
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    watch,
    getValues,
    setValue,
    unregister,
    resetField,
    userPhone: user?.phone,
  };
};
