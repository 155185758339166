/* eslint-disable react/jsx-props-no-spreading */
import { Button, PhoneInput, Textarea, TextInput } from 'shared/ui';
import { useFormErrorMessages, useValidateContact } from 'shared/lib';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { phone, phoneTo } from 'shared/config/constants';
import { useCallbackForm } from '../model';
import styles from './CallbackForm.module.scss';

interface CallbackFormProps {
  title: string;
  subtitle?: string;
  headerContent?: boolean;
}
export const CallbackForm = ({ title, subtitle, headerContent }: CallbackFormProps) => {
  const { onSubmit, register, errors } = useCallbackForm();
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  return (
    <section className={styles.root}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        {headerContent && (
        <div className={styles.headerContent}>
          <span className={styles.hint}>Можно позвонить</span>
          <Link to={phoneTo} className={styles.link}>{phone}</Link>
        </div>
        )}
      </div>
      {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
      <form onSubmit={onSubmit} className={styles.form}>
        <div className={cn(styles.wrapper, styles.item)}>
          <TextInput
            id="name"
            label="Имя"
            required
            hasError={errors.name != null}
            className={styles.input}
            help={errors.name ? errors.name.message : undefined}
            placeholder="Как вас зовут?"
            {...register('name', { required: errorMessage.required })}
          />
          <PhoneInput
            id="phone"
            label="Телефон"
            required
            hasError={errors.phone != null}
            help={errors.phone ? errors.phone.message : undefined}
            {...register('phone', { required: errorMessage.required, validate: validateContact })}
          />
        </div>
        <div className={styles.item}>
          <Textarea
            id="comment"
            label="Комментарий"
            placeholder="Ваше сообщение"
            maxLength={512}
            {...register('comment')}
          />
          <Button type="submit" className={styles.submit}>Оставить заявку</Button>
        </div>
      </form>
    </section>
  );
};
