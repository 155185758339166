/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      stroke="#21252E"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#Download_svg__a)"
    >
      <path d="M2.667 11.333v1.334A1.333 1.333 0 0 0 4 14h8a1.333 1.333 0 0 0 1.334-1.333v-1.334M4.667 7.333 8 10.667l3.334-3.334M8 2.667v8" />
    </g>
  </svg>
);
export default SvgDownload;
