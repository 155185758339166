import { PropsWithChildren } from 'react';
import { Spinner } from 'react-bootstrap';
import cn from 'classnames';
import styles from 'shared/ui/layout/LoadingHOC.module.scss';

interface LoadingHOCProps extends PropsWithChildren {
  isLoading: boolean;
  className?: string;
}

export const LoadingHOC = (
  { isLoading, className, children = null }: LoadingHOCProps,
) => (isLoading ? (
  <div className={cn(styles.root, className)}>
    <Spinner />
  </div>
// eslint-disable-next-line react/jsx-no-useless-fragment
) : <>{children}</>);
