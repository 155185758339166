/* eslint-disable react/jsx-props-no-spreading */
import cn from 'classnames';
import { FC, PropsWithChildren, ReactNode, useMemo, useState } from 'react';
import { FormGroup, FormLabel, FormText, Spinner } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownIcon } from 'shared/ui/icons';
import styles from './Select.module.scss';

export type SelectOption<T> = { label: string; value: T };

export interface SelectProps<T> {
  label?: string;
  name?: string;
  value?: T;
  defaultValue?: T;
  options?: SelectOption<T>[];
  onChange?: (ev: { target: { value: T, name?: string } }, value: T) => void;
  className?: string;
  displayElement?: FC<PropsWithChildren>;
  itemElement?: (option: SelectOption<T>) => ReactNode;
  getItemKey?: (value: T) => any;
  disabled?: boolean;
  hasError?: boolean;
  help?: string;
  isLoading?: boolean;
  required?: boolean;
  withIcon?: boolean;
  isEmpty?: boolean;
}

// eslint-disable-next-line react/function-component-definition
export function Select<T>({
  label, value, options = [], onChange, defaultValue, disabled,
  className, displayElement, name, itemElement, getItemKey,
  hasError, help, isLoading, required, withIcon = true, isEmpty,
}: SelectProps<T>) {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  const selectedValueLabel = useMemo(() => (
    options.find((option) => (
      getItemKey && currentValue != null
        ? getItemKey(option.value) === getItemKey(currentValue)
        : option.value === currentValue
    ))?.label
  ), [currentValue, getItemKey, options]);
  const onClick = (data: T) => {
    onChange?.({ target: { value: data, name } }, data);
    setCurrentValue(data);
  };
  if (value != null && value !== currentValue) {
    setCurrentValue(value);
  }
  const renderMenuChildren = () => {
    if (isLoading) {
      return (
        <Spinner className={styles.loader} />
      );
    }
    if (isEmpty) {
      return <div className={styles.isEmpty}>Введите 3 или больше символов</div>;
    }
    if (options?.length === 0) {
      return <div className={styles.isEmpty}>Ничего не найдено</div>;
    }

    return (
      <>
        {
          options.map((option, index) => (
            <Dropdown.Item
              key={[index].toString()}
              onClick={() => onClick(option.value)}
              className={styles.item}
              title={option.label.toString()}
            >
              {
                itemElement
                  ? itemElement(option)
                  : option.label
              }
            </Dropdown.Item>
          ))
        }
      </>
    );
  };
  return (
    <FormGroup
      className={cn(hasError && styles.hasError)}
    >
      {label && (
        <FormLabel className={cn(styles.label, disabled && styles.labelDisabled)}>
          {label}
          {required ? <span className={styles.required}> *</span> : null}
        </FormLabel>
      )}
      <Dropdown>
        <Dropdown.Toggle
          className={cn(
            styles.root,
            disabled && styles.disabled,
            !withIcon && styles.rootIcon,
            className,
          )}
          as="button"
          type="button"
          title={selectedValueLabel}
        >
          {
            displayElement
              ? displayElement({ children: selectedValueLabel })
              : <span className={styles.value}>{selectedValueLabel}</span>
          }
          {
            withIcon && (
              <DropdownIcon
                className={cn(options.length === 1 ? styles.arrow_disabled : styles.arrow)}
              />
            )
          }
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {renderMenuChildren()}
        </Dropdown.Menu>
      </Dropdown>
      <FormText className={styles.help}>{help}</FormText>
    </FormGroup>
  );
}
