import { Dispatch, FormEvent, SetStateAction } from 'react';
import { normalizePhone, regex } from 'shared/lib';
import { useLazyIsUserExistsQuery } from 'shared/api/user';
import { useLoginStep1Mutation } from 'shared/api/login-by-code';
import { useUser } from 'entities/user';

interface UseCheckoutShowAuthProps {
  setIsModalVisible: Dispatch<SetStateAction<boolean>>;
}
export const useCheckoutShowAuth = (
  { setIsModalVisible }: UseCheckoutShowAuthProps,
) => {
  const [isUserExists] = useLazyIsUserExistsQuery();
  const [loginStep1] = useLoginStep1Mutation();
  const { user } = useUser();
  const onBlur = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!regex.phoneSimplified.test(value)) {
      return;
    }
    const input = { phone: normalizePhone(value) };
    if (user === null) {
      isUserExists({ input })
        .then(({ data }) => {
          if (data?.IsUserExists) {
            setIsModalVisible(true);
            loginStep1({ input });
          }
        });
    }
  };
  return { onBlur };
};
