/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 3.273c-2.37 0-2.667.01-3.598.053-.93.042-1.563.19-2.118.405a4.3 4.3 0 0 0-1.546 1.006A4.3 4.3 0 0 0 3.73 6.283c-.216.555-.363 1.19-.405 2.118-.042.931-.053 1.229-.053 3.6s.011 2.666.053 3.597c.043.929.19 1.563.406 2.118.223.574.52 1.06 1.006 1.546.485.485.972.784 1.546 1.007.555.216 1.19.363 2.118.406.93.042 1.228.052 3.598.052s2.667-.01 3.598-.052c.929-.043 1.564-.19 2.119-.406a4.3 4.3 0 0 0 1.545-1.007 4.3 4.3 0 0 0 1.007-1.546c.215-.555.362-1.19.406-2.118.041-.93.052-1.228.052-3.598s-.01-2.667-.052-3.598c-.044-.93-.191-1.564-.406-2.119a4.3 4.3 0 0 0-1.007-1.546 4.3 4.3 0 0 0-1.546-1.006c-.556-.216-1.19-.363-2.12-.405s-1.227-.053-3.597-.053zm-.783 1.573H12c2.33 0 2.607.008 3.527.05.85.039 1.312.181 1.62.3.407.159.698.348 1.003.653s.494.596.653 1.004c.12.307.262.769.3 1.62.042.92.051 1.196.051 3.525 0 2.33-.009 2.606-.05 3.526-.04.85-.182 1.312-.301 1.62a2.7 2.7 0 0 1-.653 1.002 2.7 2.7 0 0 1-1.003.653c-.307.12-.77.262-1.62.3-.92.043-1.197.052-3.527.052s-2.606-.01-3.526-.051c-.851-.04-1.313-.182-1.62-.301a2.7 2.7 0 0 1-1.004-.653 2.7 2.7 0 0 1-.653-1.003c-.12-.307-.262-.77-.301-1.62-.042-.92-.05-1.196-.05-3.527 0-2.33.008-2.605.05-3.525.039-.851.181-1.313.3-1.62.159-.408.348-.699.654-1.004a2.7 2.7 0 0 1 1.003-.654c.308-.12.77-.261 1.62-.3.806-.037 1.118-.048 2.744-.05zm5.442 1.449a1.047 1.047 0 1 0 0 2.095 1.047 1.047 0 0 0 0-2.096M12 7.518a4.482 4.482 0 1 0 0 8.964 4.482 4.482 0 0 0 0-8.964m0 1.573a2.91 2.91 0 1 1 0 5.818 2.91 2.91 0 0 1 0-5.818"
    />
  </svg>
);
export default SvgInstagram;
