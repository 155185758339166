import { createContext } from 'react';
import { Product } from '../types';

export type NotificationContextData = {
  product?: Product;
  actionType?: 'cart' | 'favorite',
};

export type NotificationContextValue = {
  setData: (data: NotificationContextData) => void;
};

export const NotificationContext = createContext<NotificationContextValue>({ setData() { } });
