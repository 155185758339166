import { useEffect, useState } from 'react';
import { timestamp } from 'shared/lib';
import styles from './ResendCode.module.scss';

interface ResetCondeProps {
  startTime: number;
  setResendCode: any;
  onResend?: (requestTime: number) => void;
  isPhone?: boolean;
}
export const ResendCode = ({ startTime, onResend, setResendCode, isPhone }: ResetCondeProps) => {
  const [start, setStart] = useState(startTime);
  const [current, setCurrent] = useState(timestamp());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent(timestamp());
    }, 1000);

    return () => clearInterval(interval);
  });
  const onClick = (isSms?: boolean) => {
    setResendCode(isSms);
    setStart(timestamp());
    onResend?.(timestamp());
  };

  const remainingSeconds = 60 - (current - start);
  // TODO: Restore the sending of the code in SMS when the SMS mailing service appears
  const render = () => (isPhone ? (
    <div className={styles.wrapper}>
      <button type="button" className="link" disabled={remainingSeconds > 0} onClick={() => onClick()}>
        Позвонить повторно
      </button>
      {/* eslint-disable-next-line max-len */}
      {/* <button type="button" className="link" disabled={remainingSeconds > 0} onClick={() => onClick(true)}> */}
      {/*  Отправить через SMS */}
      {/* </button> */}
    </div>
  ) : (
    <button type="button" className="link" disabled={remainingSeconds > 0} onClick={() => onClick()}>
      Отправить повторно
    </button>
  ));
  return (
    <div className={styles.root}>
      {
        remainingSeconds > 0 ? (
          <button type="button" className={styles.time} disabled={remainingSeconds > 0}>
            {`Повторная отправка через ${remainingSeconds} сек.`}
          </button>
        ) : render()
      }
    </div>
  );
};
