import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { Paths } from 'shared/config';
import { usePersonalUpdateProfileMutation } from 'shared/api/profile';

interface FormData {
  country: string;
  // TODO: Fix type
  city: any;
  address: any;
  flat: string;
  code: string;
}
export const useAddressChangeForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    resetField,
    watch,
    setValue,
  } = useForm<FormData>();
  const [updateProfile] = usePersonalUpdateProfileMutation();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const {
      country,
      city,
      address,
      flat,
      code,
    } = data;
    const input = {
      country,
      city: city?.city,
      city_guid: city?.cityId,
      street: address?.street,
      street_guid: address?.streetId,
      house: address?.house,
      house_guid: address?.houseId,
      flat,
      postindex: code,
    };
    updateProfile({ input })
      .unwrap()
      .then(() => navigate(Paths.ProfileAddress));
  };
  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    resetField,
    watch,
    setValue,
  };
};
