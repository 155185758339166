import { SubmitHandler, useForm } from 'react-hook-form';
import { useUser } from 'entities/user';
import { usePersonalUpdateProfileMutation } from 'shared/api/profile';
import { Paths } from 'shared/config';
import { useNavigate } from 'react-router';

interface FormData {
  name: string;
  surname: string;
  middlename: string;
}
export const useNameForm = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>();
  const { user } = useUser();
  const navigate = useNavigate();
  const [updateProfile] = usePersonalUpdateProfileMutation();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const input = { ...data };
    updateProfile({ input })
      .unwrap()
      .then(() => navigate(Paths.Profile));
  };
  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
    name: user?.name,
    surname: user?.surname,
    middlename: user?.middlename,
  };
};
