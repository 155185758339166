import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Pagination, Autoplay } from 'swiper/modules';
import cn from 'classnames';
import styles from './Slider.module.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import { Banners } from './types';

interface SliderProps {
  items?: Banners[];
  className?: string;
  slides?: string[];
}
export const Slider = ({ items, className, slides }: SliderProps) => (
  <div className={cn(styles.root, className)}>
    <Swiper
      slidesPerView={1}
      pagination={{ clickable: true }}
      effect="fade"
      speed={900}
      modules={[Navigation, EffectFade, Pagination, Autoplay]}
      navigation
      loop
      autoplay={{ delay: 4500 }}
    >
      <div className="next-overlay" />
      <div className="prev-overlay" />
      {
        slides ? slides.map((item) => (
          <SwiperSlide key={item}>
            <img src={item} alt="" />
          </SwiperSlide>
        )) : items?.map((item) => (
          <SwiperSlide key={item.id}>
            <img src={item.file_path} alt="" />
            {
              item.info && (
                <div className={styles.container}>
                  <div className={styles.block} />
                  <h1 className={styles.title}>{item.info.title}</h1>
                  <span className={styles.subtitle}>{item.info.subtitle}</span>
                </div>
              )
            }
          </SwiperSlide>
        ))
      }
    </Swiper>
  </div>
);
