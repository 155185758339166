import { CatalogsItem } from 'entities/catalogs';
import { useGet3DMaxCatalog } from '../model';
import styles from './CatalogsPages.module.scss';

export const ThreeDMaxCatalogPage = () => {
  const data = useGet3DMaxCatalog();
  const tables = data.filter((item) => item.value === 'tables')[0];
  const dressers = data.filter((item) => item.value === 'dressers')[0];
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>3D-каталог столов</h2>
        <div className={styles.items}>
          {
            tables.items.map((item) => (
              <CatalogsItem item={item} key={item.id} />
            ))
          }
        </div>
      </div>
      <div className={styles.wrapper}>
        <h2 className={styles.title}>3D-каталог комодов</h2>
        <div className={styles.items}>
          {
            dressers.items.map((item) => (
              <CatalogsItem item={item} key={item.id} />
            ))
          }
        </div>
      </div>
    </div>
  );
};
