/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ResetPasswordStep1MutationVariables = Types.Exact<{
  input: Types.PasswordRecoveryStep1Input;
}>;


export type ResetPasswordStep1Mutation = { __typename?: 'Mutation', PasswordRecoveryStep1: string };


export const ResetPasswordStep1Document = `
    mutation ResetPasswordStep1($input: PasswordRecoveryStep1Input!) {
  PasswordRecoveryStep1(input: $input)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    ResetPasswordStep1: build.mutation<ResetPasswordStep1Mutation, ResetPasswordStep1MutationVariables>({
      query: (variables) => ({ document: ResetPasswordStep1Document, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useResetPasswordStep1Mutation } = injectedRtkApi;

