import { Paths } from 'shared/config';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { EditInput, PencilIcon } from 'shared/ui';
import { useUser } from 'entities/user';
import styles from './ProfileAddress.module.scss';

export const ProfileAddress = () => {
  const { user } = useUser();
  const isAddress = user?.country;
  const navigate = useNavigate();
  const address = `${user?.postindex}, ${user?.country}, ${user?.city}, ${user?.street} ${user?.house}, ${user?.flat && 'кв'} ${user?.flat}`;
  return (
    <div className={styles.root}>
      {
        isAddress ? (
          <>
            <h1 className={styles.title}>Адрес</h1>
            <EditInput
              className={styles.input}
              value={address}
              onEdit={() => navigate(Paths.ProfileAddressEdit)}
            />
          </>
        ) : (
          <>
            <h1 className={styles.title}>Адрес</h1>
            <Link to={Paths.ProfileAddressEdit} className={styles.wrapper}>
              <span className={styles.text}>
                Нажмите на иконку карандаша, чтобы заполнить адрес
              </span>
              <PencilIcon className={styles.icon} />
            </Link>
          </>
        )
      }
    </div>
  );
};
