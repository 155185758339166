/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type OrdersListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type OrdersListQuery = { __typename?: 'Query', OrdersList?: { __typename?: 'OrdersListResponse', data?: Array<{ __typename?: 'OrdersListItemFront', id: string, total: number, productsCount: number, createdAt: any, status: { __typename?: 'OrderStatus', id: string, title: string, createdAt?: any }, products?: Array<{ __typename?: 'Cartproduct', productFiles?: Array<{ __typename?: 'ProductFiles', id: string, filePath: string, fileType?: { __typename?: 'ProductFileType', name: string, id: string } }> }> }>, paginatorInfo?: { __typename?: 'PaginatorData', count?: number, currentPage?: number, firstItem?: number, hasMorePages?: boolean, lastItem?: number, lastPage?: number, perPage?: number, total?: number } } };


export const OrdersListDocument = `
    query OrdersList($first: Int, $page: Int) {
  OrdersList(first: $first, page: $page) {
    data {
      id
      status {
        id
        title
        createdAt: created_at
      }
      products {
        productFiles {
          id
          filePath: file_path
          fileType {
            name
            id
          }
        }
      }
      productsCount: products_count
      total
      createdAt: created_at
    }
    paginatorInfo {
      count
      currentPage
      firstItem
      hasMorePages
      lastItem
      lastPage
      perPage
      total
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    OrdersList: build.query<OrdersListQuery, OrdersListQueryVariables | void>({
      query: (variables) => ({ document: OrdersListDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useOrdersListQuery, useLazyOrdersListQuery } = injectedRtkApi;

