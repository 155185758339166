import { Maybe, Scalars } from 'shared/api/types.generated';
import { timestamp } from 'shared/lib';

type AuthResponse = {
  expiresIn?: Maybe<Scalars['Int']>['output'];
  token?: Maybe<Scalars['String']>['output'];
  tokenType?: Maybe<Scalars['String']>['output'];
};

export function castAuthData(response: AuthResponse): AuthData | null {
  const { token = '', tokenType = '', expiresIn } = response ?? {};
  if (!token || !tokenType) {
    return null;
  }
  const expiresInSec = expiresIn ? expiresIn * 60 : undefined;
  return {
    token,
    tokenType,
    expiresIn: expiresInSec,
    expiresAt: expiresInSec ? timestamp() + expiresInSec : expiresInSec,
  };
}
