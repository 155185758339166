/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 21v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2M8 7a4 4 0 1 0 8 0 4 4 0 0 0-8 0"
    />
  </svg>
);
export default SvgUser;
