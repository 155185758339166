import { Product } from 'features/product';
import { useNavigate, useParams } from 'react-router';
import { useProductQuery } from 'shared/api/product';
import { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import { LoadingHOC, Slider } from 'shared/ui';
import { CallbackForm } from 'features/callback';
import { ProductOneClickPurchaseModal, ProductOneClickPurchaseButton } from 'widgets/product/one-click-purchase';
import { AddToCart } from 'features/cart/ui';
import { AddToFavorite } from 'features/favourites/ui';
import { formatPrice } from 'shared/lib/format';
import { useDispatch, useSelector } from 'react-redux';
import type { ProductFront } from 'shared/api';
import {
  selectedAttributes,
  setAttributes,
  transformAttributes,
  selectedIsUpdated,
  clearAttributes,
  processAttributes,
} from 'entities/product/attribute';
import { generatePath } from 'react-router-dom';
import { Paths } from 'shared/config';
import { withNotification } from 'widgets/layout';
import { Cartproduct } from 'shared/api';
import { useCartQuery } from 'shared/api/cart';
import styles from './ProductPage.module.scss';

export const ProductPage = () => {
  const cartQuery = useCartQuery();
  const cart = useMemo(() => cartQuery.currentData?.Cart, [cartQuery.currentData]);
  const cartProductIds = useMemo(() => cart?.products?.map(
    (product: Cartproduct) => product.id,
  ) || [], [cart]);
  const { slug } = useParams();
  const navigate = useNavigate();
  const attributeValues = useSelector(selectedAttributes);
  const isUpdated = useSelector(selectedIsUpdated);
  const attributesId = transformAttributes(attributeValues);
  const input = useMemo(() => {
    if (isUpdated) {
      return { attribute_values: { attribute_value_id: attributesId } };
    }
    return { slug };
  }, [slug, attributesId, isUpdated]);
  const dispatch = useDispatch();
  const query = useProductQuery(
    { input },
    { skip: slug === null && attributesId.length === 0 },
  );
  const queryProduct = query.currentData?.Product;
  const [prevProduct, setPrevProduct] = useState<ProductFront | undefined>(queryProduct);
  const product = useMemo(() => {
    if (!queryProduct) {
      return prevProduct;
    }
    setPrevProduct(queryProduct);
    return queryProduct;
  }, [prevProduct, queryProduct]);
  const productImg = product?.productFiles?.filter((file) => file.fileType?.name === 'photo');
  const banners = product?.productFiles?.filter((file) => file.fileType?.name === 'galery');
  useEffect(() => {
    if (query.isError) {
      navigate('*');
    }
  }, [navigate, query.isError]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  useEffect(() => {
    const processedAttributes = processAttributes(product?.productValues ?? []);
    if (!(Object.keys(processedAttributes).length === 0)) {
      dispatch(setAttributes(processedAttributes));
      navigate(generatePath(Paths.Product, { path: product!.category!.slug, slug: product!.slug }));
    }
  }, [dispatch, product, navigate]);
  useEffect(() => () => {
    dispatch(clearAttributes());
    // eslint-disable-next-line
  }, []);
  const [animationState, setAnimationState] = useState(false);
  const handleClick = () => {
    setAnimationState(true);
    setTimeout(() => {
      setAnimationState(false);
    }, 1000);
  };
  const inCart = cartProductIds.includes(product?.id ?? '');
  return (
    <LoadingHOC isLoading={!prevProduct} className={styles.loader}>
      <div className={styles.root}>
        <div className={prevProduct !== queryProduct ? styles.animation : styles.animationNone}>
          <Container>
            <div className={styles.wrapper}>
              <Product
                product={product}
                productImg={productImg}
                animationState={animationState}
                favoriteAction={(
                  <AddToFavorite
                    productId={product?.id}
                    onClick={handleClick}
                    inFavorite={inCart}
                  />
              )}
                actions={[
                  <AddToCart
                    text={`В корзину ${formatPrice(product?.price)}`}
                    productId={product?.id}
                    key="cart"
                    onClick={handleClick}
                  />,
                  <ProductOneClickPurchaseButton
                    productOneClickPurchase={setPopupVisible}
                    text="Свой дизайн"
                    key="oneClick"
                  />]}
              />
            </div>
          </Container>
          { banners?.length !== 0 && (
            <>
              <h2 className={styles.title}>Галерея</h2>
              <Slider items={banners} className={styles.slider} />
            </>
          )}
        </div>
        <Container>
          <CallbackForm
            title="Есть особые пожелания?"
            headerContent
          />
        </Container>
        <ProductOneClickPurchaseModal
          isPopupVisible={isPopupVisible}
          setPopupVisible={setPopupVisible}
        />
      </div>
    </LoadingHOC>
  );
};

export const EnhancedProductPage = withNotification(ProductPage);
