export { default as VisibleIcon } from './Visible';
export { default as InvisibleIcon } from './Invisible';
export { default as DropdownIcon } from './Dropdown';
export { default as ClockIcon } from './Clock';
export { default as InstagramIcon } from './Instagram';
export { default as VkIcon } from './Vk';
export { default as PinterestIcon } from './Pinterest';
export { default as LogoIcon } from './Logo';
export { default as CartIcon } from './Cart';
export { default as FavoriteIcon } from './Favorite';
export { default as UserIcon } from './User';
export { default as CallIcon } from './Call';
export { default as CatalogIcon } from './Catalog';
export { default as DesignerIcon } from './Designer';
export { default as Object8LogoIcon } from './Object8Logo';
export { default as ArrowBackIcon } from './ArrowBack';
export { default as CloseIcon } from './Close';
export { default as ArrowRightIcon } from './ArrowRight';
export { default as TermIcon } from './Term';
export { default as DeliveryIcon } from './Delivery';
export { default as ModelIcon } from './Model';
export { default as DownloadIcon } from './Download';
export { default as IncIcon } from './Inc';
export { default as DecIcon } from './Dec';
export { default as EditCartIcon } from './EditCart';
export { default as PencilIcon } from './Pencil';
