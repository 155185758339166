import { ClockIcon } from 'shared/ui';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PickupPoint } from '../types';
import styles from './PickupPoint.module.scss';

interface PickupPointItemProps {
  pickupPoint: PickupPoint
}

export const PickupPointItem = ({ pickupPoint }: PickupPointItemProps) => (
  <div className={styles.root}>
    <OverlayTrigger
      delay={{ hide: 0, show: 150 }}
      overlay={(
        <Tooltip id={`tooltip-${pickupPoint.id}`}>
          {pickupPoint.address}
        </Tooltip>
      )}
    >
      <div>{pickupPoint.address}</div>
    </OverlayTrigger>
    <div className={styles.schedule}>
      <ClockIcon />
      <span>{pickupPoint.schedule}</span>
    </div>
  </div>
);
