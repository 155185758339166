// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddToFavorite_root__xBLV1 {
  width: 32px;
  height: 32px;
  color: #21252E;
  fill: transparent;
  transition: all 0.15s;
}
.AddToFavorite_root__xBLV1:hover {
  fill: #B43737;
  color: #B43737;
}
.AddToFavorite_rootInFavorite__R01fx {
  fill: #B43737;
  color: #B43737;
}`, "",{"version":3,"sources":["webpack://./src/features/favourites/ui/AddToFavorite.module.scss","webpack://./src/shared/styles/vars/_colors.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,YAAA;EACA,cCJQ;EDKR,iBAAA;EACA,qBAAA;AADF;AAGE;EACE,aCDE;EDEF,cCFE;ADCN;AAIE;EACE,aCNE;EDOF,cCPE;ADKN","sourcesContent":["@import 'shared/styles/theme';\n\n.root {\n  width: 32px;\n  height: 32px;\n  color: $primary;\n  fill: transparent;\n  transition: all 0.15s;\n\n  &:hover {\n    fill: $red;\n    color: $red;\n  }\n\n  &InFavorite {\n    fill: $red;\n    color: $red;\n  }\n\n}","$main-bg: #C2CBD1;\n$primary: #21252E;\n$secondary: #FFFFFF;\n\n$grey: #95989F;\n$grey-light: #F4F5F6;\n$grey-hover: #EDEFF0;\n$grey-active: #E1E8EC;\n\n$red: #B43737;\n\n$border: #C2CBD1;\n\n$error: #B43737;\n$link: #3B72C5;\n$disabled: #E5E5E5;\n\n$input-focus: #21252E;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AddToFavorite_root__xBLV1`,
	"rootInFavorite": `AddToFavorite_rootInFavorite__R01fx`
};
export default ___CSS_LOADER_EXPORT___;
