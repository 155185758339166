import { Header } from 'widgets/header';
import { Footer, FooterMobile } from 'widgets/footer';
import { Button } from 'shared/ui';
import { Paths } from 'shared/config';
import styles from './Page404.module.scss';

export const Page404 = () => (
  <>
    <Header />
    <div className={styles.root}>
      <h1>Ошибка 404</h1>
      <span>
        Всё сломалось, может быть починим,
        <br />
        перейдите по кнопке ниже на главную
      </span>
      <Button href={Paths.Root} className={styles.button}>На главную</Button>
    </div>
    <Footer />
    <FooterMobile />
  </>
);
