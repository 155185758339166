import { useMemo } from 'react';
import { useDeliveryCalcQuery } from 'shared/api/checkout';

interface UsePvzListProps {
  deliveryId: string;
  postalCode: string;
  countryCode?: string;
  city: string;
}
export const usePvzList = ({ deliveryId, postalCode, countryCode = 'RU', city }: UsePvzListProps) => {
  const input = {
    delivery_id: deliveryId,
    postal_code: postalCode,
    country_code: countryCode,
    city_fias: city,
  };
  const pvzQuery = useDeliveryCalcQuery({ input }, { skip: !city || !postalCode });
  const deliveryPrice = useMemo(() => pvzQuery.currentData?.DeliveryCalc.price, [pvzQuery]);
  const pvzList = useMemo(() => (
    pvzQuery.currentData?.DeliveryCalc.pickup_point?.map((item) => {
      const { address, id, schedule } = item;

      return {
        label: address,
        value: {
          id,
          address,
          schedule,
        },
      };
    }) ?? []
  ), [pvzQuery]);

  return {
    pvzList,
    deliveryPrice,
  };
};
