import { Paths } from 'shared/config';
import { Link } from 'react-router-dom';
import { CallIcon, CatalogIcon, DesignerIcon, UserIcon } from 'shared/ui';
import { CartLink } from 'features/cart';
import styles from './FooterMobile.module.scss';

export const FooterMobile = () => (
  <div className={styles.root}>
    <Link to={Paths.Catalog} className={styles.item}>
      <CatalogIcon className={styles.icon} />
      <span>Каталог</span>
    </Link>
    <div className={styles.item}>
      <CallIcon className={styles.icon} />
      <span>Связаться</span>
    </div>
    <Link to={Paths.Cart} className={styles.item}>
      <CartLink className={styles.icon} />
      <span>Корзина</span>
    </Link>
    <div className={styles.item}>
      <DesignerIcon className={styles.icon} />
      <span>Я дизайнер</span>
    </div>
    <Link to={Paths.Profile} className={styles.item}>
      <UserIcon className={styles.icon} />
      <span>Профиль</span>
    </Link>
  </div>
);
