import { OrderStatusValues } from './types';

export const orderStatusLocale = {
  [OrderStatusValues.OrderApproval]: 'Согласование  заказа',
  [OrderStatusValues.AwaitingPayment]: 'Ожидает оплаты',
  [OrderStatusValues.Paid]: 'Оплачен',
  [OrderStatusValues.InAssembly]: 'В сборке',
  [OrderStatusValues.IsCreated]: 'Производится',
  [OrderStatusValues.InProgress]: 'В пути',
  [OrderStatusValues.Delivered]: 'Доставлен',
  [OrderStatusValues.Cancelled]: 'Отменен',
};

export const orderStatusMap:
Record<string, OrderStatusValues> = {
  1: OrderStatusValues.OrderApproval,
  2: OrderStatusValues.AwaitingPayment,
  3: OrderStatusValues.Paid,
  4: OrderStatusValues.InAssembly,
  5: OrderStatusValues.IsCreated,
  6: OrderStatusValues.InProgress,
  7: OrderStatusValues.Delivered,
  8: OrderStatusValues.Cancelled,
};
