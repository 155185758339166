import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext } from 'react';
import { MultistepPagination } from 'shared/lib/multistep/types';
import { PasswordRecoveryStep1Input } from 'shared/api';
import { normalizePhone, regex } from 'shared/lib';
import { useResetPasswordStep1Mutation } from 'shared/api/reset-password';
import { ResetPasswordContext } from './ResetPasswordContext';

interface FormData {
  contact: string,
}
export function useResetPasswordForm(pagination: MultistepPagination) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<FormData>();
  const [resetPasswordStep1] = useResetPasswordStep1Mutation();
  const { setStep } = useContext(ResetPasswordContext);
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const isSms = data.contact.indexOf('+7') === 0;
    const input: PasswordRecoveryStep1Input = { is_sms: isSms };
    if (regex.email.test(data.contact)) {
      input.email = data.contact;
    } else if (regex.phone.test(data.contact)) {
      input.phone = normalizePhone(data.contact);
    }
    resetPasswordStep1({ input })
      .unwrap()
      .then(() => {
        setStep(1, data);
        pagination.next();
      })
      .catch((error) => {
        setError('contact', { type: error, message: error.message });
      });
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
  };
}
