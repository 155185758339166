/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgDec = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1.5em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#Dec_svg__a)">
      <path stroke="currentColor" strokeLinecap="square" d="m15 6-6 6 6 6" />
    </g>
    <defs>
      <clipPath id="Dec_svg__a">
        <path fill="#fff" d="M24 0H0v24h24z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDec;
