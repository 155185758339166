import { useMemo } from 'react';
import { useCheckoutDeliveryQuery } from 'shared/api/checkout';

export const useCdekDelivery = () => {
  const cdekQuery = useCheckoutDeliveryQuery();
  const mapFunction = <T extends Record<any, any>>(item: T) => {
    const { id, title } = item;
    return { label: title, value: id };
  };
  const cdekDelivery = useMemo(() => (
    cdekQuery.currentData?.CheckoutDelivery?.map(mapFunction) ?? []
  ), [cdekQuery]);

  return cdekDelivery;
};
