import { useContext } from 'react';
import { useCartAddProductMutation } from 'shared/api/cart';
import { NotificationContext, updateList } from 'entities/notification';
import { CartAddProductMutation } from 'shared/api/cart/addToCart.csr.generated';
import { QueryStatus } from '@reduxjs/toolkit/query';

export function useAddToCart(productId?: string) {
  const [addProduct, result] = useCartAddProductMutation();
  const { setData } = useContext(NotificationContext);
  const addToCart = () => {
    if (productId) {
      const input = { product_id: productId ?? '' };
      updateList(
        addProduct({ input })
          .unwrap(),
        { setData, convert: (response: CartAddProductMutation) => response.CartAddProduct, actionType: 'cart' },
      );
    }
  };

  return {
    addToCart,
    inProcess: result.status === QueryStatus.pending,
  };
}
