/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgTerm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.5em"
    height="1.5em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="#21252E"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#Term_svg__a)"
    >
      <path d="M13.5 21H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5M16 3v4M8 3v4M4 11h16M19 16l-2 3h4l-2 3" />
    </g>
    <defs>
      <clipPath id="Term_svg__a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTerm;
