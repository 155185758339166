/* eslint-disable react/jsx-props-no-spreading */
import { Container } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { ProfileMenu } from 'entities/profile/menu';
import { OrderHistoryItem } from 'entities/order-history';
import { Button, LoadingHOC } from 'shared/ui';
import { Paths } from 'shared/config';
import { useOrdersListQuery } from 'shared/api/orderHistory';
import { useMemo } from 'react';
import { Pagination } from 'shared/ui/pagination';
import styles from './OrderHistoryPage.module.scss';

export const OrderHistoryPage = () => {
  // TODO: Move this to useOrderHistory hook
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Number(searchParams.get('page') ?? '1');
  const query = useOrdersListQuery({ page: pageNumber, first: 5 });
  const history = useMemo(() => query.currentData?.OrdersList?.data, [query.currentData]);
  const paginatorInfo = useMemo(
    () => query.currentData?.OrdersList?.paginatorInfo,
    [query.currentData],
  );
  if (paginatorInfo?.lastPage !== undefined && pageNumber > paginatorInfo?.lastPage) {
    searchParams.set('page', String(paginatorInfo?.lastPage));
    setSearchParams(searchParams);
  }
  return (
    <div className={styles.root}>
      <Container>
        <div className={styles.wrapper}>
          <ProfileMenu />
          <div className={styles.content}>
            <h1 className={styles.title}>История заказов</h1>
            <LoadingHOC isLoading={!history} className={styles.loader}>
              {
                history?.length ? (
                  history?.map((item) => (
                    <OrderHistoryItem key={item.id} {...item} />
                  ))
                ) : (
                  <div className="empty">
                    <h2 className="info">Заказов еще нет</h2>
                    <Button href={Paths.Catalog} className="button">В каталог</Button>
                  </div>
                )
              }
            </LoadingHOC>
          </div>
        </div>
        {
          paginatorInfo && (
            <div className={styles.pagination}>
              <Pagination
                page={paginatorInfo.currentPage ?? 1}
                total={paginatorInfo.lastPage ?? 1}
                href=""
              />
            </div>
          )
        }
      </Container>
    </div>
  );
};
