import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storageLocal from 'redux-persist/lib/storage';
import { timestamp } from 'shared/lib';

const initialState: () => AuthData | null = () => null;

const slice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setAuthData(_, { payload }: PayloadAction<AuthData>) {
      return payload;
    },
    clearAuthData() {
      return null;
    },
  },
});

export const { setAuthData, clearAuthData } = slice.actions;

export const selectAuthData = (state: RootState): AuthData | null => {
  const { token, tokenType, expiresIn, expiresAt } = state.auth.data ?? {};
  if (token && tokenType) {
    return { token, tokenType, expiresIn, expiresAt };
  }
  return null;
};

export const selectIsAuthenticated = (state: RootState): boolean => {
  const authData = selectAuthData(state);
  // NOTE: This is not sufficient because user can revoke tokens
  return authData != null && (!authData.expiresAt || timestamp() < authData.expiresAt);
};

// NOTE: Local storage is vulnerable to XSS attacks, consider other storage options
// @see https://auth0.com/docs/secure/security-guidance/data-security/token-storage#browser-in-memory-scenarios
export const authDataReducer = persistReducer({ key: 'authData', storage: storageLocal }, slice.reducer);
