/* eslint-disable react/jsx-props-no-spreading */
import { Select, SelectProps } from 'shared/ui/form/select';
import { SearchInput } from 'shared/ui/form/input';
import {
  FC,
  PropsWithChildren,
  useCallback, useEffect, useState,
  Dispatch, SetStateAction,
} from 'react';
import { QueryDefinition } from '@reduxjs/toolkit/query';
import { QueryArgFrom } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { UseFormResetField } from 'react-hook-form';

export interface AutocompleteProps<
  SelectValueType = any,
  D extends QueryDefinition<any, any, string, any>
  = QueryDefinition<any, any, string, any>,
> extends Omit<SelectProps<SelectValueType>, 'defaultValue'> {
  useQuery: any;
  arg?: QueryArgFrom<D> & { filter?: { q?: string } };
  debounce?: number;
  defaultValue?: any;
  setCode?: Dispatch<SetStateAction<string>>
  resetField: UseFormResetField<any>;
  mapFunction?: Record<string, any>;
}

export const Autocomplete = <
  SelectValueType = any,
  D extends QueryDefinition<any, any, string, any>
  = QueryDefinition<any, any, string, any>,
>({
    arg,
    useQuery,
    debounce,
    defaultValue,
    isLoading,
    disabled,
    setCode,
    resetField,
    name,
    mapFunction,
    ...restSelectProps
  }: AutocompleteProps<SelectValueType, D>) => {
  const [searchTerm, setSearchTerm] = useState<string>(defaultValue?.value);
  const input = {
    ...arg,
    needle: searchTerm,
  };
  const {
    data,
    isFetching,
  } = useQuery({ input }, { skip: searchTerm == null || searchTerm.length < 3 });
  const [isEmpty, setIsEmpty] = useState(!defaultValue);
  const onSearch = (value: string) => {
    setIsEmpty(false);
    if (value.length === 0 && name) {
      setIsEmpty(true);
      resetField(name);
    }
    if (value.length < 3) {
      setIsEmpty(true);
    }
    setSearchTerm(value);
  };
  const options = data?.SuggestAddress.map(mapFunction);
  const value: Record<string, string> = options?.find(
    (item: Record<string, string>) => JSON.stringify(item.value) === JSON.stringify(defaultValue),
  );
  useEffect(() => {
    if (setCode && value) {
      setCode(value.postalCode);
    }
  }, [setCode, value]);
  const displayElement: FC<PropsWithChildren> = useCallback(() => (
    <SearchInput
      key={value?.label || defaultValue?.value || defaultValue}
      onSearch={onSearch}
      autoComplete="off"
      delay={debounce}
      defaultValue={value?.label ?? defaultValue?.value ?? defaultValue}
      disabled={disabled}
    />
    // eslint-disable-next-line
  ), [debounce, disabled, value, defaultValue]);
  return (
    <Select
      name={name}
      displayElement={displayElement}
      required
      options={options}
      isLoading={isFetching}
      disabled={disabled}
      withIcon={false}
      isEmpty={isEmpty}
      {...restSelectProps}
    />
  );
};
