/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgModel = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2em"
    height="3.5em"
    fill="none"
    viewBox="0 0 32 52"
    {...props}
  >
    <rect
      width={52}
      height={32}
      x={32}
      fill="#FDFDFD"
      rx={16}
      transform="rotate(90 32 0)"
    />
    <g
      stroke="#21252E"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipPath="url(#Model_svg__a)"
    >
      <path d="m16 5 10.666 6v12L16 29 5.333 23V11zM16 17l10.667-6M16 17v12M16 17 5.333 11" />
    </g>
    <path
      fill="#21252E"
      d="M12.635 44.145q-.535 0-.985-.19a2.2 2.2 0 0 1-.775-.54 2.2 2.2 0 0 1-.475-.845l.7-.21q.18.536.59.81.415.275.935.265.48-.01.825-.215.35-.206.535-.57.185-.365.185-.845 0-.735-.43-1.18-.425-.45-1.125-.45-.195 0-.41.055a1.7 1.7 0 0 0-.4.145l-.37-.585 2.8-2.585.12.3h-3.67V36.8h4.16v.715l-2.44 2.33-.01-.29q.75-.095 1.32.17.57.266.89.81.325.54.325 1.27 0 .69-.3 1.22-.295.525-.815.825-.52.295-1.18.295M16.227 44v-7.2h2.185l.39.005q.28.005.535.04.82.115 1.37.61t.825 1.26q.276.765.275 1.685 0 .92-.275 1.685-.274.765-.825 1.26t-1.37.61a6 6 0 0 1-.535.04l-.39.005zm.76-.705h1.425q.206 0 .44-.01a3 3 0 0 0 .41-.05q.63-.11 1.015-.51.39-.4.57-1 .18-.605.18-1.325 0-.725-.18-1.33a2.35 2.35 0 0 0-.57-1q-.39-.395-1.015-.505a2.6 2.6 0 0 0-.415-.045 7 7 0 0 0-.435-.015h-1.425z"
    />
    <defs>
      <clipPath id="Model_svg__a">
        <path fill="#fff" d="M0 1h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgModel;
