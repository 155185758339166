import { SubmitHandler, useForm } from 'react-hook-form';
import { useContext } from 'react';
import { MultistepPagination } from 'shared/lib/multistep/types';
import { normalizePhone, regex } from 'shared/lib';
import type { LoginByCodeStep1Input } from 'shared/api';
import { useLoginStep1Mutation } from 'shared/api/login-by-code';
import { LoginByCodeContext } from './LoginByCodeContext';

interface FormData {
  contact: string,
}
export function useContactForm(pagination: MultistepPagination) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
  } = useForm<FormData>();
  const { setStep } = useContext(LoginByCodeContext);
  const [loginStep1] = useLoginStep1Mutation();
  const onSubmit: SubmitHandler<FormData> = (data) => {
    const isSms = data.contact.indexOf('+7') === 0;
    const input: LoginByCodeStep1Input = { is_sms: isSms };
    if (regex.email.test(data.contact)) {
      input.email = data.contact;
    } else if (regex.phone.test(data.contact)) {
      input.phone = normalizePhone(data.contact);
    }
    loginStep1({ input })
      .unwrap()
      .then(() => {
        setStep(1, data);
        pagination.next();
      })
      .catch((error) => {
        setError('contact', { type: '', message: error.message });
      });
  };

  return {
    onSubmit: handleSubmit(onSubmit),
    register,
    errors,
  };
}
