/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import { ArrowBackIcon, Button, IconButton, TextInput } from 'shared/ui';
import { Paths } from 'shared/config';
import { useFormErrorMessages } from 'shared/lib';
import { useEffect, useMemo, useState } from 'react';
import { CityAutocomplete, StreetAutocomplete } from 'entities/address';
import { useUser } from 'entities/user';
import { FormContainer } from 'features/profile/edit/data';
import styles from './AddressChangeForm.module.scss';
import { useAddressChangeForm } from '../model';

export const AddressChangeForm = () => {
  const {
    register,
    onSubmit,
    errors,
    resetField,
    watch,
    setValue,
  } = useAddressChangeForm();
  const errorMessage = useFormErrorMessages();
  const { user } = useUser();
  const [code, setCode] = useState('');
  const cityFiasId = watch('city');
  const getCityFias = () => {
    if (cityFiasId) {
      const { cityId, settlementId } = cityFiasId;
      return cityId ?? settlementId;
    }
    return null;
  };
  useEffect(() => {
    if (!user?.postindex) {
      setValue('code', code);
    }
  }, [code, setValue, user?.postindex]);
  const cityValue = useMemo(() => ({
    value: user?.city,
    cityId: user?.cityId ?? user?.settlementId,
    city: user?.city ?? user?.settlement,
  }), [user]);
  const streetValue = useMemo(() => ({
    value: `${user?.street}, ${user?.house}`,
    street: user?.street,
    streetId: user?.streetId,
    house: user?.house,
    houseId: user?.houseId,
  } || {}), [user]);
  const getDisplayCityValue = () => {
    if (Object.values(cityValue).some((value: any) => value === null)) {
      return watch('city');
    }
    return cityValue;
  };
  useEffect(() => {
    if (!Object.values(cityValue).some((value: any) => value === null)) {
      setValue('city', cityValue);
    }
    if (!Object.values(streetValue).some((value: any) => value === null)) {
      setValue('address', streetValue);
    }
  }, [cityValue, setValue, streetValue]);
  useEffect(() => {
    setValue('country', 'Россия');
  }, [setValue]);
  const getDisplayStreetValue = () => {
    if (Object.values(streetValue).some((value: any) => value === null)) {
      return watch('address');
    }
    return streetValue;
  };
  return (
    <FormContainer
      title="Редактировать адрес"
      className={styles.root}
      onSubmit={onSubmit}
    >
      <Link to={Paths.ProfileAddress}>
        <IconButton className="stepBack">
          <ArrowBackIcon />
        </IconButton>
      </Link>
      <TextInput
        id="country"
        label="Страна"
        defaultValue="Россия"
        disabled
        required
        hasError={errors.country != null}
        help={errors.country ? errorMessage.required : undefined}
        {...register('country', { required: true })}
      />
      <CityAutocomplete
        label="Город"
        resetField={resetField}
        setCode={setCode}
        defaultValue={getDisplayCityValue()}
        arg={{ type: 'city' }}
        required
        hasError={errors.city != null}
        help={errors.city
          ? errorMessage[errors.city.type as keyof typeof errorMessage]
          : undefined}
        {...register('city', { required: true })}
      />
      <StreetAutocomplete
        label="Улица и номер дома"
        defaultValue={getDisplayStreetValue()}
        resetField={resetField}
        setCode={setCode}
        required
        arg={{ type: 'street', guid: getCityFias() }}
        hasError={errors.address != null}
        help={errors.address ? errorMessage.required : undefined}
        {...register('address', { required: true })}
      />
      <div className={styles.wrapper}>
        <TextInput
          id="flat"
          label="Квартира"
          defaultValue={user?.flat}
          {...register('flat')}
        />
        <TextInput
          id="code"
          label="Индекс"
          defaultValue={user?.postindex}
          disabled={!!code || !watch('address')}
          {...register('code')}
        />
      </div>
      <Button type="submit" className={styles.submit}>Сохранить</Button>
    </FormContainer>
  );
};
