import { TAG_ORDER } from './tags';
import { api as useGetOrderListApi } from './getOrderList.csr.generated';
import { api as useGetOrderItemApi } from './getOrder.csr.generated';

export { useOrdersListQuery } from './getOrderList.csr.generated';
export { useGetOrderQuery } from './getOrder.csr.generated';

useGetOrderListApi.enhanceEndpoints({
  addTagTypes: [TAG_ORDER],
  endpoints: { OrdersList: { providesTags: [TAG_ORDER] } },
});

useGetOrderItemApi.enhanceEndpoints({
  addTagTypes: [TAG_ORDER],
  endpoints: { getOrder: { providesTags: [TAG_ORDER] } },
});
