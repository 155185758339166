/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import { useFormErrorMessages } from 'shared/lib';
import { PasswordInput, Button, IconButton, ArrowBackIcon } from 'shared/ui';
import { Paths } from 'shared/config';
import { FormContainer } from 'features/profile/edit/data';
import { usePasswordCreateForm } from '../model';
import styles from './PasswordChangeForm.module.scss';

export const PasswordCreateForm = () => {
  const {
    register,
    errors,
    onSubmit,
  } = usePasswordCreateForm();
  const errorMessage = useFormErrorMessages();
  return (
    <FormContainer
      title="Новый пароль"
      description="Пароль должен состоять из 8+ символов, содержать буквы разного регистра и хотя бы одну цифру"
      onSubmit={onSubmit}
      className={styles.root}
    >
      <Link to={Paths.ProfilePassword}>
        <IconButton className="stepBack">
          <ArrowBackIcon />
        </IconButton>
      </Link>
      <PasswordInput
        id="password"
        label="Новый пароль"
        hasError={errors.password != null}
        help={errors.password ? errorMessage[errors.password.type] : undefined}
        {...register('password', { required: true })}
      />
      <PasswordInput
        id="confirmPassword"
        label="Новый пароль еще раз"
        hasError={errors.confirmPassword != null}
        help={errors.confirmPassword ? errorMessage[errors.confirmPassword.type] : undefined}
        {...register('confirmPassword', { required: true })}
      />
      <Button type="submit" className={styles.submit}>Сохранить</Button>
      <Link to={Paths.ResetPassword} className="link">Забыли пароль?</Link>
    </FormContainer>
  );
};
