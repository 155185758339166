import { Container } from 'react-bootstrap';
import { PasswordCreateForm } from 'features/profile/edit/password';
import { ProfileMenu } from 'entities/profile/menu';
import styles from './ProfileCreatePasswordPage.module.scss';

export const ProfileCreatePasswordPage = () => (
  <div className={styles.root}>
    <Container>
      <div className={styles.wrapper}>
        <ProfileMenu />
        <PasswordCreateForm />
      </div>
    </Container>
  </div>
);
