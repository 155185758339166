/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type MenuQueryVariables = Types.Exact<{
  input: Types.MenuInput;
}>;


export type MenuQuery = { __typename?: 'Query', Menu?: Array<{ __typename?: 'MenuFront', id: string, name?: string, items?: Array<{ __typename?: 'MenuItemFront', id: string, parent_id: string, link: string, text: string }> }> };


export const MenuDocument = `
    query Menu($input: MenuInput!) {
  Menu(input: $input) {
    id
    name
    items {
      id
      parent_id
      link
      text
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    Menu: build.query<MenuQuery, MenuQueryVariables>({
      query: (variables) => ({ document: MenuDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useMenuQuery, useLazyMenuQuery } = injectedRtkApi;

