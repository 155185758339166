import { createApi } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient } from 'graphql-request';
import { api as apiConfig } from 'shared/config';

export const client = new GraphQLClient(apiConfig.baseUrl, { credentials: 'include' });

type ErrorResponse = {
  message: string;
  type: string;
};

export const api = createApi({
  reducerPath: 'api',
  baseQuery: graphqlRequestBaseQuery<ErrorResponse>({
    client,
    prepareHeaders(headers, { getState }) {
      const { token, tokenType } = (getState() as RootState).auth.data ?? {};
      if (token && tokenType) {
        headers.set('authorization', `${tokenType} ${token}`);
      }
      return headers;
    },
    customErrors(arg): ErrorResponse {
      const { message, extensions } = arg.response.errors?.at(0) || {};
      return {
        message: (extensions!.debugMessage ?? message ?? 'Unexpected error') as string,
        type: extensions?.category != null ? extensions.category as string : 'unknown',
      };
    },
  }),
  // Endpoints can be injected later @see https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced#injecting-endpoints
  endpoints: () => ({}),
});

export const { getRunningQueriesThunk } = api.util;
