import { EditCartIcon } from 'shared/ui';
import styles from './Actions.module.scss';

export const EditFavoriteItem = () => {
  const onClick = () => {
    console.log('edit');
  };
  return (
    <button type="button" onClick={onClick} className={styles.root}>
      <EditCartIcon className={styles.icon} />
    </button>
  );
};
