import { PencilIcon } from 'shared/ui/icons';
import cn from 'classnames';
import styles from './EditInput.module.scss';

interface EditInputProps {
  label?: string;
  value?: string;
  onEdit: () => void;
  className?: string;
}
export const EditInput = ({ label, value, onEdit, className }: EditInputProps) => (
  <div role="presentation" className={cn(styles.root, className)} onClick={onEdit}>
    <span className={styles.label}>{label}</span>
    <div className={styles.valueContainer}>
      <span className={styles.value}>{value || '-'}</span>
      <button type="button" className={styles.btn}>
        <PencilIcon />
      </button>
    </div>
  </div>
);
