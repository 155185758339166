import { useEffect, MouseEventHandler } from 'react';
import { CloseIcon } from 'shared/ui';
import styles from './Notification.module.scss';
import { Product } from '../types';

interface NotificationProps {
  product: Product
  onClose: () => void;
  actionType?: 'cart' | 'favorite',
}
export const Notification = ({ product, onClose, actionType }: NotificationProps) => {
  useEffect(() => {
    setTimeout(onClose, 1500);
  }, [onClose]);
  const onClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    onClose();
  };
  const name = product.name.split(' ')[0];
  const actionText = actionType === 'cart' ? 'добавлен в корзину' : 'добавлен в избранное';
  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <img src={product.productFiles?.[0].file_path} alt="" className={styles.img} />
        <div className={styles.info}>
          <span className={styles.title}>{`${name} ${actionText}`}</span>
          {
            product.attributeRows?.map((row) => (
              <div key={row} className={styles.property}>
                <span>{row}</span>
              </div>
            ))
          }
        </div>
      </div>
      <CloseIcon
        className={styles.icon}
        onClick={onClick}
      />
    </div>
  );
};
