import { TAG_CATALOG } from './tags';
import { api as catalogApi } from './catalog.csr.generated';

export { useCatalogQuery } from './catalog.csr.generated';
export { TAG_CATALOG, TAG_CATEGORIES } from './tags';

catalogApi.enhanceEndpoints({
  addTagTypes: [TAG_CATALOG],
  endpoints: { Catalog: { providesTags: [TAG_CATALOG] } },
});
