import { TAG_CART } from 'shared/api/cart';
import { api as checkoutDeliveryApi } from './checkoutDelivery.csr.generated';
import { api as suggestAddressApi } from './suggestAddress.csr.generated';
import { api as checkoutCreateOrder } from './checkoutCreateOrder.csr.generated';
import { TAG_CHECKOUT } from './tags';

export { useCheckoutDeliveryQuery } from './checkoutDelivery.csr.generated';
export { useSuggestAddressQuery } from './suggestAddress.csr.generated';
export { useDeliveryCalcQuery } from './getCdekPVZ.csr.generated';
export { useCheckoutCreateOrderMutation } from './checkoutCreateOrder.csr.generated';

checkoutDeliveryApi.enhanceEndpoints({
  addTagTypes: [TAG_CHECKOUT],
  endpoints: { CheckoutDelivery: { providesTags: [TAG_CHECKOUT] } },
});

suggestAddressApi.enhanceEndpoints({
  addTagTypes: [TAG_CHECKOUT],
  endpoints: { SuggestAddress: { providesTags: [TAG_CHECKOUT] } },
});

checkoutCreateOrder.enhanceEndpoints({
  addTagTypes: [TAG_CART],
  endpoints: { CheckoutCreateOrder: { invalidatesTags: [TAG_CART] } },
});
