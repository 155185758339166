/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type ProductQueryVariables = Types.Exact<{
  input: Types.ProductInput;
}>;


export type ProductQuery = { __typename?: 'Query', Product: { __typename?: 'ProductFront', id: string, name: string, slug: string, description?: string, price: number, category?: { __typename?: 'Category', id: string, image_path: string, name: string, slug: string }, productValues?: Array<{ __typename?: 'ProductAttributeValues', id: string, attribute_id: string, attribute?: { __typename?: 'Attribute', id: string, parent_attribute_id?: string, slug?: string, title: string }, attributeValue?: { __typename?: 'AttributeValue', id: string, parent_attribute_value_id?: string, slug?: string, vars?: string, value: string } }>, productVariantValues?: Array<{ __typename?: 'ProductAttributeValues', id: string, attribute_id: string, attribute?: { __typename?: 'Attribute', id: string, parent_attribute_id?: string, slug?: string, title: string }, attributeValue?: { __typename?: 'AttributeValue', id: string, parent_attribute_value_id?: string, vars?: string, slug?: string, value: string } }>, productFiles?: Array<{ __typename?: 'ProductFiles', id: string, file_path: string, fileType?: { __typename?: 'ProductFileType', name: string, id: string } }> } };


export const ProductDocument = `
    query Product($input: ProductInput!) {
  Product(input: $input) {
    id
    name
    slug
    description
    category {
      id
      image_path
      name
      slug
    }
    productValues {
      id
      attribute_id
      attribute {
        id
        parent_attribute_id
        slug
        title
      }
      attributeValue {
        id
        parent_attribute_value_id
        slug
        vars
        value
      }
    }
    productVariantValues {
      id
      attribute_id
      attribute {
        id
        parent_attribute_id
        slug
        title
      }
      attributeValue {
        id
        parent_attribute_value_id
        vars
        slug
        value
      }
    }
    productFiles {
      id
      file_path
      fileType {
        name
        id
      }
    }
    price
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    Product: build.query<ProductQuery, ProductQueryVariables>({
      query: (variables) => ({ document: ProductDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useProductQuery, useLazyProductQuery } = injectedRtkApi;

