/* eslint-disable */
/**
 *
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the .graphql files in this project and run
 *
 * npm run gql-codegen
 *
 * for this file to be re-created
 */
import * as Types from 'shared/api/types.generated';

import { api } from 'shared/api/baseApi';
module.hot?.accept();
export type CategoryQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type CategoryQuery = { __typename?: 'Query', Category?: Array<{ __typename?: 'Category', id: string, name: string, slug: string, imagePath: string }> };


export const CategoryDocument = `
    query Category($id: [ID!]) {
  Category(id: $id) {
    id
    name
    slug
    imagePath: image_path
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (build) => ({
    Category: build.query<CategoryQuery, CategoryQueryVariables | void>({
      query: (variables) => ({ document: CategoryDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };
export const { useCategoryQuery, useLazyCategoryQuery } = injectedRtkApi;

