/* eslint-disable react/jsx-props-no-spreading */
import type { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import cn from 'classnames';
import styles from './FormContainer.module.scss';

interface FormContainerProps extends Omit<PropsWithChildren<HTMLAttributes<HTMLFormElement>>, 'title'> {
  title: NonNullable<ReactNode>;
  description?: ReactNode;
  rootClass?: boolean;
}

export const FormContainer = (
  { title, description, children, className, rootClass, ...rest }: FormContainerProps,
) => (
  <div className={cn(styles.root, rootClass && styles.wrapper)}>
    <h2 className={styles.heading}>{title}</h2>
    {description && (
      <div className={styles.description}>{description}</div>
    )}
    <form
      className={cn(styles.content, className)}
      {...rest}
    >
      {children}
    </form>
  </div>
);
