import { ModalBody } from 'react-bootstrap';
import { ProductAttributeValues } from 'shared/api';
import { ArrowBackIcon, ArrowRightIcon, CloseIcon } from 'shared/ui';
import styles from './AttributeModal.module.scss';

interface AttributePopupBodyDefaultProps {
  attributes: ProductAttributeValues[];
  attributeTitle?: string;
  stepNext: (id: string) => void;
  stepBack?: () => void;
  updateAttributes: (attribute: ProductAttributeValues) => void;
  setPopupVisible: (visible: boolean) => void;
}

export const AttributeModalBodyDefault = ({
  attributes,
  attributeTitle,
  stepNext,
  updateAttributes,
  setPopupVisible,
  stepBack,
}: AttributePopupBodyDefaultProps) => (
  <ModalBody>
    <h3 className={styles.title}>{attributeTitle}</h3>
    {
      stepBack && (
        <div className={styles.back}>
          <ArrowBackIcon onClick={stepBack} className={styles.backIcon} />
        </div>
      )
    }
    <div className={styles.close}>
      <CloseIcon
        onClick={() => setPopupVisible(false)}
        className={styles.closeIcon}
      />
    </div>
    {
      attributes?.map((attribute) => (
        <button
          key={attribute.id}
          type="button"
          className={styles.btn}
          onClick={
            attribute?.attributeValue?.vars
              ? () => stepNext(attribute.id)
              : () => updateAttributes(attribute)
          }
        >
          {attribute?.attributeValue?.value}
          <ArrowRightIcon />
        </button>
      ))
    }
  </ModalBody>
);
