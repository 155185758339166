import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Paths } from 'shared/config';
import { InstagramIcon, LogoIcon, PinterestIcon, VkIcon } from 'shared/ui';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import headerMobileStyles from './HeaderMobile.module.scss';
import styles from './Header.module.scss';

const BurgerMenuItems = [
  {
    link: '/about',
    text: 'О компании',
  },
  {
    link: '/portfolio',
    text: 'Портфолио',
  },
  {
    link: '/order',
    text: 'Доставка и оплата',
  },
  {
    link: '/pdf-catalog',
    text: 'PDF-каталог',
  },
  {
    link: '/3d-catalog',
    text: '3D Max  каталог',
  },
  {
    link: '/cooperation',
    text: 'Сотрудничество',
  },
];

const profileMenuItems = [
  {
    id: '1',
    link: Paths.Profile,
    text: 'Основная информация',
  },
  {
    id: '2',
    link: Paths.Favourites,
    text: 'Избранное',
  },
  {
    id: '3',
    link: Paths.ProfileAddress,
    text: 'Адрес',
  },
  {
    id: '4',
    link: Paths.ProfilePassword,
    text: 'Пароль',
  },
  {
    id: '5',
    link: Paths.ProfileHistory,
    text: 'История заказов',
  },
];
export const HeaderMobile = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setMenuVisible(false);
  }, [location]);

  useEffect(() => {
    if (isMenuVisible) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMenuVisible]);

  return (
    <header className={headerMobileStyles.root}>
      <div className={styles.container}>
        <div className={headerMobileStyles.logo}>
          <Link to={Paths.Root}>
            <LogoIcon className={headerMobileStyles.icon} />
          </Link>
          <div className={headerMobileStyles.text}>
            <span>Столы и комоды</span>
            <span>Онлайн конструктор</span>
          </div>
        </div>
        <div
          className={cn(headerMobileStyles.menu, isMenuVisible && headerMobileStyles.menu_active)}
        >
          <nav>
            {
              location.pathname.includes('profile')
                ? profileMenuItems.map(
                  (item) => <Link to={item.link} key={item.link}>{item.text}</Link>,
                )
                : BurgerMenuItems.map(
                  (item) => <Link to={item.link} key={item.link}>{item.text}</Link>,
                )
            }
          </nav>
          <div className={headerMobileStyles.socials}>
            <Link
              to="https://ru.pinterest.com/timmebelonline/?invite_code=519cfc101abd486f9b82814d52094ef4&sender=979462756367535327"
              target="_blank"
            >
              <PinterestIcon className={headerMobileStyles.icon} />
            </Link>
            <Link
              to="https://instagram.com/tim.mebel?igshid=MzRlODBiNWFlZA=="
              target="_blank"
            >
              <InstagramIcon className={headerMobileStyles.icon} />
            </Link>
            <Link
              to="https://vk.com/timmebel.online"
              target="_blank"
            >
              <VkIcon className={headerMobileStyles.icon} />
            </Link>
          </div>
        </div>
        <div
          role="presentation"
          className={cn(
            headerMobileStyles.burger,
            isMenuVisible && headerMobileStyles.burger_active,
          )}
          onClick={() => setMenuVisible(!isMenuVisible)}
        />
      </div>
    </header>
  );
};
