import { Container } from 'react-bootstrap';
import { ProfilePassword } from 'features/profile/info/ui';
import { ProfileMenu } from 'entities/profile/menu';
import styles from './ProfilePasswordPage.module.scss';

export const ProfilePasswordPage = () => (
  <div className={styles.root}>
    <Container>
      <div className={styles.wrapper}>
        <ProfileMenu />
        <div className={styles.content}>
          <ProfilePassword />
        </div>
      </div>
    </Container>
  </div>
);
