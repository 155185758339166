import { CatalogList } from 'features/catalog';
import { Container } from 'react-bootstrap';
import styles from './CatalogPage.module.scss';

export const CatalogPage = () => (
  <Container>
    <div className={styles.root}>
      <CatalogList />
    </div>
  </Container>
);
