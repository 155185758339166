/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode } from 'react';
import { FormContainer } from 'entities/auth';
import { ContactInput, PasswordInput, Button } from 'shared/ui';
import { useFormErrorMessages, useValidateContact } from 'shared/lib';
import { useLoginByPasswordForm } from '../model';
import styles from './LoginByPasswordForm.module.scss';

interface LoginByPasswordFormProps {
  footer?: ReactNode,
}

export const LoginByPasswordForm = ({ footer }: LoginByPasswordFormProps) => {
  const { onSubmit, register, errors } = useLoginByPasswordForm();
  const errorMessage = useFormErrorMessages();
  const validateContact = useValidateContact();
  return (
    <FormContainer
      title="Вход"
      id="login-by-password"
      onSubmit={onSubmit}
      className={styles.root}
      footer={footer}
    >
      <ContactInput
        id="email-phone"
        label="Почта или номер телефона"
        position="center"
        hasError={errors.contact != null}
        help={errors.contact ? errors.contact.message : undefined}
        className={styles.contact}
        {...register('contact', { required: errorMessage.required, validate: validateContact })}
      />
      <PasswordInput
        id="password"
        label="Пароль"
        position="center"
        hasError={errors.password != null}
        help={errors.password ? errorMessage[errors.password.type] : undefined}
        {...register('password', { required: true })}
      />
      <Button form="login-by-password" type="submit" className={styles.submit}>Войти</Button>
    </FormContainer>
  );
};
