/* eslint-disable react/jsx-props-no-spreading */
import type { SVGProps } from 'react';

const SvgDropdown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g clipPath="url(#Dropdown_svg__a)">
      <path
        stroke="#95989F"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4 6 4 4 4-4"
      />
    </g>
    <defs>
      <clipPath id="Dropdown_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgDropdown;
