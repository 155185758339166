import { ModalFooter } from 'react-bootstrap';
import { ProductAttributeValues } from 'shared/api';
import { Button } from 'shared/ui';
import styles from './AttributeModal.module.scss';

interface AttributePopupFooterProps {
  attributes: ProductAttributeValues[];
  updateAttributes: (attribute: ProductAttributeValues) => void;
}

export const AttributeModalFooter = (
  { attributes, updateAttributes }: AttributePopupFooterProps,
) => (
  <ModalFooter className={styles.footer}>
    {
      attributes?.map((item) => (
        <Button key={item.id} type="button" onClick={() => updateAttributes(item)}>
          Выбрать
        </Button>
      ))
    }
  </ModalFooter>
);
