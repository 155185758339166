import { Paths } from 'shared/config';
import { Button, LoadingHOC } from 'shared/ui';
import { EditCartItem, RemoveFromCart, IncDecCartItem } from 'features/cart';
import { ProductRow } from 'entities/product/row';
import { formatPrice } from 'shared/lib/format';
import { useCartQuery } from 'shared/api/cart';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Cartproduct } from 'shared/api';
import styles from './CartPage.module.scss';

export const CartPage = () => {
  const query = useCartQuery();
  const cart = useMemo(() => query.currentData?.Cart, [query.currentData]);
  return (
    <LoadingHOC isLoading={!cart} className={styles.loader}>
      <section className={styles.root}>
        <h2 className={styles.title}>Корзина</h2>
        {
          cart?.products?.length ? (
            <div className={styles.wrapper}>
              {
                cart?.products.map((item: Cartproduct) => (
                  <ProductRow
                    key={item.id}
                    product={item}
                    actions={[
                      <RemoveFromCart productId={item.id} key="remove" />,
                      <EditCartItem product={item} key="edit" />]}
                    incDecAction={<IncDecCartItem product={item} />}
                  />
                ))
              }
              <Link
                to={Paths.Checkout}
                className={styles.link}
                state={{ cartSum: cart.sum }}
              >
                <Button
                  className={styles.submit}
                  type="button"
                >
                  {`Оформить · ${formatPrice(cart.sum)}`}
                </Button>
              </Link>
            </div>
          ) : (
            <div className="empty">
              <h3 className="info">Ваша корзина пуста</h3>
              <Button type="button" href={Paths.Catalog} className="button">
                В каталог
              </Button>
            </div>
          )
        }
      </section>
    </LoadingHOC>
  );
};
